import { AgentSessionType } from "@/modules/sessions/types";
import { ZTable } from "@/shared/components/table";
import { cn } from "@/shared/utils/classname-merger";
import Fuse from "fuse.js";
import { useCallback, useEffect, useMemo } from "react";
import { v4 as uuid } from "uuid";
import {
  Control,
  getCustomizeControlsActions,
  Soc2Control,
  useCustomizeControlsStore,
  VendorAssessmentControl,
} from "../../../states/cusomize-controls";
import { filterListOfControlsBasedOnTabsAndAgentType } from "../CustomizeControlTopSection/CustomizeControlTopLeftSection/helper";
import { getRandomCharacter } from "../helpers";
import {
  customizeControlsColumns,
  searchAndFilteringList,
  updateHandlerBasedOnAgentTypes,
  vendorAssessmentControlsColumns,
} from "./helper";
const CustomizeControlsTable = () => {
  const {
    dataState,
    accordianOpenState,
    selectedControls,
    searchQuery,
    selectedTab,
    agentType,
  } = useCustomizeControlsStore();
  const {
    setDataState,
    setAccordianOpenState,
    setSelectedControls,
    setNewControlPlaceHolderUid,
  } = useCustomizeControlsStore().actions;

  const dataStateBasedOnSelected = useMemo(
    () =>
      dataState.filter((c) =>
        filterListOfControlsBasedOnTabsAndAgentType({
          agentType,
          control: c,
          selectedTab,
        })
      ),
    [dataState, selectedTab, agentType]
  );

  const fuse = useMemo(() => {
    return new Fuse(dataStateBasedOnSelected, {
      keys: searchAndFilteringList(agentType),
      threshold: 0.3,
      findAllMatches: true,
      ignoreLocation: true,
    });
  }, [dataStateBasedOnSelected, agentType]);

  const filteredRows = useMemo(() => {
    return searchQuery
      ? fuse
          .search(searchQuery)
          .map((result) => result.item)
          .filter(Boolean)
      : dataStateBasedOnSelected;
  }, [dataStateBasedOnSelected, fuse, searchQuery]);

  const sectionWiseData = useMemo(() => {
    const output = filteredRows.filter((control) =>
      filterListOfControlsBasedOnTabsAndAgentType({
        agentType,
        control,
        selectedTab,
      })
    );
    const uid = getRandomCharacter() + uuid().replaceAll("-", "_");
    if (searchQuery.trim().length === 0) {
      switch (agentType) {
        case AgentSessionType.SOC2_GAP_TYPE1:
        case AgentSessionType.SOC2_GAP_TYPE2:
        case AgentSessionType.SOC2_GAP:
          output.push({
            category: "",
            toe: "",
            tod: "",
            control: "",
            control_id: "",
            criteria: "",
            tsc: selectedTab,
            tsc_id: "",
            uid,
            zania_control_id: "",
            edited_state: "NEW",
          } as Soc2Control);
          break;

        case AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT:
          output.push({
            edited_state: "NEW",
            category: "",
            question: "",
            section: selectedTab,
            uid,
          } as VendorAssessmentControl);
          break;
        default:
          break;
      }
    }

    return output.map((d) => ({ ...d, id: d.uid }));
  }, [filteredRows, selectedTab, searchQuery, agentType]);

  useEffect(() => {
    const newControlUid = sectionWiseData.find((c) => c.edited_state === "NEW")
      ?.uid;
    setNewControlPlaceHolderUid(newControlUid ?? "");
  }, [setNewControlPlaceHolderUid, sectionWiseData]);

  useEffect(() => {
    getCustomizeControlsActions().setSectionWiseData(sectionWiseData);
  }, [sectionWiseData]);

  const handleChangeDataState = useCallback(
    (
      agentType: AgentSessionType | null,
      key: keyof Soc2Control | keyof VendorAssessmentControl,
      uId: string,
      newValue: string,
      changesInTestingProcedure?: number
    ) => {
      switch (agentType) {
        case AgentSessionType.SOC2_GAP_TYPE1:
        case AgentSessionType.SOC2_GAP_TYPE2:
        case AgentSessionType.SOC2_GAP:
          updateHandlerBasedOnAgentTypes({
            agentType,
            key: key as keyof Soc2Control,
            uId,
            newValue,
            changesInTestingProcedure,
          });
          break;
        case AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT:
          updateHandlerBasedOnAgentTypes({
            agentType,
            key: key as keyof VendorAssessmentControl,
            uId,
            newValue,
            changesInTestingProcedure,
          });
          break;

        default:
          break;
      }
    },
    []
  );

  const columns = useMemo(() => {
    switch (agentType) {
      case AgentSessionType.SOC2_GAP_TYPE1:
      case AgentSessionType.SOC2_GAP_TYPE2:
      case AgentSessionType.SOC2_GAP:
        return customizeControlsColumns({
          accordianOpenState,
          filteredRows: filteredRows as Soc2Control[],
          handleChangeDataState: (
            key: keyof Soc2Control,
            uId: string,
            newValue: string,
            changesInTestingProcedure?: number
          ) =>
            handleChangeDataState(
              agentType,
              key,
              uId,
              newValue,
              changesInTestingProcedure
            ),
          searchQuery,
          selectedControls,
          setAccordianOpenState,
          setSelectedControls,
        });

      case AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT:
        return vendorAssessmentControlsColumns({
          handleChangeDataState: (
            key: keyof VendorAssessmentControl,
            uId: string,
            newValue: string,
            changesInTestingProcedure?: number
          ) =>
            handleChangeDataState(
              agentType,
              key,
              uId,
              newValue,
              changesInTestingProcedure
            ),
          searchQuery,
          selectedControls,
          setSelectedControls,
          filteredRows: filteredRows as VendorAssessmentControl[],
          selectedTab,
        });
      default:
        return [];
    }
  }, [
    agentType,
    accordianOpenState,
    filteredRows,
    handleChangeDataState,
    searchQuery,
    selectedControls,
    setAccordianOpenState,
    setSelectedControls,
    selectedTab,
  ]);

  return (
    <div className="grow overflow-hidden flex border-t  ">
      <div
        id="customize-control-table"
        className="flex-1 flex flex-col bg-white overflow-auto"
      >
        <ZTable<Control>
          aria-label="Customize Controls list"
          isCompact={true}
          removeWrapper={true}
          columns={columns as any} // todo: later lets try to fix it.
          tableRowProps={(control) => {
            return {
              className: cn(
                "border-t-1 border-b-1 border-[#E4E4E7] bg-white pb-1 group",
                "min-h-[36px]",
                control.edited_state === "ACCORDIAN" && "!bg-zinc-100",
                control.edited_state === "NEW" && "!bg-zinc-50"
              ),
            };
          }}
          tableBodyProps={{
            emptyContent: "No control found",
          }}
          tableColumnProps={({ fieldName }) => ({
            className: cn(
              fieldName === "control" && "pl-[16px]",
              fieldName === "" && "w-[216px]",
              fieldName === "category" && "w-[240px]",
              fieldName === "control" && "min-w-[420px]",
              fieldName === "testing_procedure" && "min-w-[372px]"
            ),
          })}
          classNames={{
            tr: "hover:bg-[#ECECEC] !rounded-none ",
            th: "bg-[#F9F7F7] !rounded-none",
            // td: 'h-full ',
          }}
          tableHeaderProps={{
            className: "!rounded-none",
          }}
          className="static"
          // className='transition'
          tableCellProps={() => ({ className: cn("align-baseline ") })}
          rows={sectionWiseData}
          isHeaderSticky={true}
        />
        <div className="h-52 w-full block">
          <span className="h-52 w-full block" />
        </div>
      </div>
    </div>
  );
};

export default CustomizeControlsTable;
