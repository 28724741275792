import { LoggedInMember, LoggedInOrganization } from "@/modules/auth/types";

export enum TeamRole {
  // Priority: ADMIN > ORGADMIN > MEMBER (Changes as per the doc: https://docs.google.com/document/d/18nBehktguhvb-YAClZhEONG1imMqvBaGRtu6Pm4z8jM/edit)
  ADMIN = "stytch_organization_admin",
  ORG_ADMIN = "stytch_admin",
  MEMBER = "stytch_member",
  // Zania custom roles
  ZANIA_AGENT = "zania_agent",
  ZANIA_ACCURATE_RESPONSE = "zania_accurate_response",
  // gap assessment
  ZANIA_GAP_ASSESSMENT = "zania_gap_assessment",
  ZANIA_DORA_ASSESSMENT = "zania_dora_assessment",
  ZANIA_SOC2_ASSESSMENT = "zania_soc2_assessment",

  // Risk assessment
  ZANIA_RISK_ASSESSMENT = "zania_risk_assessment",
  ZANIA_NIST_AI_RMF = "zania_nist_ai_rmf",

  // vendor assessment
  ZANIA_VENDOR_ASSESSMENT = "zania_vendor_assessment",

  // fair assessment
  ZANIA_FAIR_ASSESSMENT = "zania_fair_ra",

  // this two are same property
  ZANIA_SECURITY_QUESTIONNAIRE = "zania_security_questionnaire",
  ZANIA_QA_ACCESS = "zania_qa_access",

  ZANIA_FILE_UPLOAD = "zania_file_upload",
  ZANIA_SEARCH_TEAM = "search_team_members",
  RESPONSE_QUALITY_ACCURACY = "zania_response_quality_accuracy",
  RESPONSE_QUALITY_SPEED = "zania_response_quality_speed",
  RESPONSE_QUALITY_LITE = "zania_response_quality_lite",

  // Zania Ask AI roles
  ZANIA_ASK_AI = "zania_ask_ai",
}

export interface OrganizationTrustedMetaData {
  feature_roles: {
    ZANIA_GAP_ASSESSMENT?: TeamRole.ZANIA_GAP_ASSESSMENT;
    ZANIA_RISK_ASSESSMENT?: TeamRole.ZANIA_RISK_ASSESSMENT;
    ZANIA_NIST_AI_RMF?: TeamRole.ZANIA_NIST_AI_RMF;
    ZANIA_DORA_ASSESSMENT?: TeamRole.ZANIA_DORA_ASSESSMENT;
    ZANIA_SOC2_ASSESSMENT?: TeamRole.ZANIA_SOC2_ASSESSMENT;
    ZANIA_VENDOR_ASSESSMENT?: TeamRole.ZANIA_VENDOR_ASSESSMENT;
  };
  privilege_roles: {
    ADMIN: TeamRole.ADMIN;
  };
  response_quality_roles: {
    SPEED: TeamRole.RESPONSE_QUALITY_SPEED;
    ACCURACY: TeamRole.RESPONSE_QUALITY_ACCURACY;
    LITE: TeamRole.RESPONSE_QUALITY_LITE;
  };
}


export type InviteApiResponse = {
  status_code: number;
  request_id: string;
  member_id: string;
  member: LoggedInMember;
  organization: LoggedInOrganization;
};

export type UpdateTeamApiPayload ={ 
  member_id: string;
  feature_access_roles: string[];
  response_quality_roles: string[];
  privilege_roles?: undefined | string[];
};

export type RevokeMemberApiResponse = {
  success: string,
  message: string
}

export type InviteTeamApiPayload = {
  user_name: string;
  user_email: string;
  feature_access_roles: string[];
  response_quality_roles: string[];
  privilege_roles?: undefined | string[];
  invite_redirect_url: string;
};