import type {
  AgentSessionStep,
  DoraAgentSession,
  FairAgentSession,
  MultiVendorAgentSession,
  QaAgentSession,
  RiskAgentSession,
  SOC2GapAgentSession,
  SOC2Type1GapAgentSession,
  SOC2Type2NewGapAgentSession,
} from '@/modules/sessions/types';
import { AiResponseType } from '@/shared/types/user';
import { HighlightArea } from '@react-pdf-viewer/highlight';
import { ISupplyChain } from '../components/VendorRiskAssessment/SupplyChain/SupplyChain';
import {
  QuestionReviewReponse,
  QuestionnaireFileData,
  QuestionnaireFiller,
  QuestionnaireMapping,
} from "./questionnaire";
import {
  DoraReviewResponse,
  FairReviewResponse,
  RiskReviewResponse,
  SOC2GapReviewResponse,
} from "./risk-and-gap";
import { VendorRiskResponseItemForSecurityAssessment } from "./vendorSecurityAssessment";

export const dataNotFound = "Data-Not-Found";

export interface UploadDialogState {
  isOpen: boolean;
  type?: AGENT_TYPES;
  subtype?: AgentSubTypes;
}

export enum AGENT_TYPES {
  RISK_ASSESSMENT = "risk_assessment",
  GAP_ASSESSMENT = "gap_assessment",
  QUESTIONNAIRE = "questionnaire",
  MULTI_FILE_VENDOR_ASSESSMENT = "multi_file_vendor_assessment",
  FAIR_ASSESSMENT = "fair_assessment",
}

export enum GapAssessmentTypes {
  DORA = 'dora',
  SOC2 = 'soc-2',
  SOC2_TYPE1 = 'soc-2-type-1',
  SOC2_TYPE2 = 'soc-2-type-2',
}
export enum MultiVendorAssessmentTypes {
  VENDOR_ASSESSMENT = "mfva",
  VENDOR_INTAKE_FORM = "vendor_intake_form", // not used
  VENDOR_SUPPLY_CHAIN_TABLE = "vendor_supply_chain_table",
}
export enum RiskAssessmentTypes {
  NIST_CSF_2 = "nist-csf-2",
  NIST_AI_RMF = "nist_ai_rmf",
  FAIR = "fair_ra"
}
export enum QuestionnaireTypes {
  QA = "qa",
}

export type AgentSubTypes =
  | RiskAssessmentTypes
  | GapAssessmentTypes
  | QuestionnaireTypes
  | MultiVendorAssessmentTypes;

export type AgentSpecificSubTypes<T extends AGENT_TYPES> =
  T extends AGENT_TYPES.RISK_ASSESSMENT
  ? RiskAssessmentTypes
  : T extends AGENT_TYPES.GAP_ASSESSMENT
    ? GapAssessmentTypes
    : T extends AGENT_TYPES.QUESTIONNAIRE
      ? QuestionnaireTypes
      : T extends AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT
        ? MultiVendorAssessmentTypes
        : never;

export type AgentSpecificMainData<
  T extends AGENT_TYPES,
  S extends AgentSpecificSubTypes<T>,
> = T extends AGENT_TYPES.RISK_ASSESSMENT
  ? S extends RiskAssessmentTypes.NIST_CSF_2 
  ? RiskAgentMainData
  : S extends RiskAssessmentTypes.NIST_AI_RMF 
  ? RiskAgentMainData 
  : S extends RiskAssessmentTypes.FAIR
  ? FairAgentMainData
  : never
  : T extends AGENT_TYPES.GAP_ASSESSMENT
    ? S extends GapAssessmentTypes.DORA
      ? DoraMainData
      : S extends GapAssessmentTypes.SOC2
        ? SOC2GapMainData
        : never
    : T extends AGENT_TYPES.QUESTIONNAIRE
      ? S extends QuestionnaireTypes.QA
        ? QuestionnaireAgentMainData
        : never
      : T extends AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT
        ? S extends MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
          ? MultiVendorAgentMainData
          : S extends MultiVendorAssessmentTypes.VENDOR_SUPPLY_CHAIN_TABLE
            ? never
            : never
        : never;

export type AgentSpecificSession<
  T extends AGENT_TYPES,
  S extends AgentSpecificSubTypes<T>,
> = T extends AGENT_TYPES.RISK_ASSESSMENT
  ? S extends RiskAssessmentTypes.NIST_CSF_2
  ? RiskAgentSession
  : S extends RiskAssessmentTypes.FAIR
  ? FairAgentSession
  : never
  : T extends AGENT_TYPES.GAP_ASSESSMENT
    ? S extends GapAssessmentTypes.DORA
      ? DoraAgentSession
      : S extends GapAssessmentTypes.SOC2
        ? SOC2GapAgentSession | SOC2Type2NewGapAgentSession | SOC2Type1GapAgentSession
        : never
    : T extends AGENT_TYPES.QUESTIONNAIRE
      ? S extends QuestionnaireTypes.QA
        ? QaAgentSession
        : never
      : T extends AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT
        ? S extends MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
          ? MultiVendorAgentSession
          : S extends MultiVendorAssessmentTypes.VENDOR_SUPPLY_CHAIN_TABLE
            ? never
            : never
        : never;

export type AgentData<
  T extends AGENT_TYPES,
  S extends AgentSpecificSubTypes<T>,
> = {
  agentType: T;
  subType: S;
  sessionData: AgentSpecificSession<T, S>;
  stepData: AgentSessionStep[];
  mainData: AgentSpecificMainData<T, S>;
  responseQuality: AiResponseType;
};

export interface AgentState {
  agentDataMap: Map<
    string,
    AgentData<AGENT_TYPES, AgentSpecificSubTypes<AGENT_TYPES>>
  >;
  agentLogBaseData: any;
}

export type AgentSpecificTableRowData<
  T extends AGENT_TYPES,
  S extends AgentSpecificSubTypes<T>,
> = T extends AGENT_TYPES.RISK_ASSESSMENT
  ? S extends RiskAssessmentTypes.NIST_CSF_2
    ? RiskReviewResponseTableRow
    : never
  : T extends AGENT_TYPES.GAP_ASSESSMENT
    ? S extends GapAssessmentTypes.DORA
      ? DoraGapReviewResponseTableRow
      : S extends GapAssessmentTypes.SOC2
        ? Soc2GapReviewResponseTableRow
        : never
    : T extends AGENT_TYPES.QUESTIONNAIRE
      ? S extends QuestionnaireTypes.QA
        ? QuestionnaireTableRow
        : never
      : T extends AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT
        ? S extends MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
          ? MultiVendorAssessmentTableRow
          : S extends MultiVendorAssessmentTypes.VENDOR_SUPPLY_CHAIN_TABLE
            ? ISupplyChain
            : never
        : never;

export interface AgentSourceFile {
  url: string;
  fileType?: string;
  fileName: string;
}

export type AgentMainDataBase<
  T extends AGENT_TYPES,
  S extends AgentSpecificSubTypes<T>,
> = {
  sourceFilesUrls: Map<string, AgentSourceFile>;
  selectedId?: string;
  finalFileUrl?: string;
  staleUrl?: string;
  reviewResponseIds?: string[];
  reviewResponseData?: Map<
    string,
    ReviewResponseData<keyof AgentSpecificReviewResponseType<T, S>>[]
  >;
  filters?: FilterOption[];
  tableRows?: AgentSpecificTableRowData<T, S>[];
  approvedIds: string[];
  editedIds: string[];
  searchTerm?: string;
  searchFor?: (keyof AgentSpecificTableRowData<T, S>)[];
};

export type ReviewResponseItemStatus = "approved" | "edited" | "none";

export interface RiskReviewResponseTableRow {
  id: string;
  tag: string | null;
  riskString: string | null;
  riskValue: string | number;
  confidence: string;
  sources: ReviewSourceTypes[];
  status: ReviewResponseItemStatus;
  maturity: string;
}

export interface ReviewResponseTableRowForFair {
  id:string;
  breakdown: string | null;
  control: string | null;
  field_level: number;
  field_type: 'number' | 'letter' | 'roman' | 'none';
  field_index: number;
  section_type?: string;
  rating?: string;
}

export interface Soc2GapReviewResponseTableRow {
  id: string;
  tag: string | null;
  control: string | null;
  compliant: ComplianceStatus;
  confidence: string;
  sources: ReviewSourceTypes[];
  status: ReviewResponseItemStatus;
}

export interface DoraGapReviewResponseTableRow {
  id: string;
  tag: string | null;
  requirement: string | null;
  compliant: ComplianceStatus;
  confidence: string;
  sources: ReviewSourceTypes[];
  status: ReviewResponseItemStatus;
}

export interface QuestionnaireTableRow {
  id: string;
  question: string | null;
  confidence: string;
  sources: ReviewSourceTypes[];
  status: ReviewResponseItemStatus;
}

export interface MultiVendorAssessmentTableRow {
  id: string;
  question: string | null;
  questionKey: string | null;
  compliant: ComplianceStatus;
  confidence: string;
  sources: ReviewSourceTypes[];
  status: ReviewResponseItemStatus;
}

export type ReviewResponseTableRow =
  | RiskReviewResponseTableRow
  | Soc2GapReviewResponseTableRow
  | QuestionnaireTableRow
  | DoraGapReviewResponseTableRow
  | MultiVendorAssessmentTableRow;

export type ReviewResponseData<T> = {
  type: ReviewResponseType;
  value: any;
  key: T;
  title: string;
  placeholder?: string;
  isCollapsible?: boolean;
  isExpanded?: boolean;
  isDisabled?: boolean;
  shouldRender: boolean;
};

export interface AgentFilter<T extends string | number> {
  key: string;
  value: T[];
  operator: "equals";
  isActive: boolean;
  type: string;
}

export interface AgentSearch<T> {
  searchTerm: string;
  searchFor?: T[];
}

export enum ReviewResponseType {
  TEXT = "text",
  TEXT_AREA = "textarea",
  NUMBER = "number",
  SOURCE = "source",
  TEXT_DISPLAY = "text_display",
  FUNCTION = "function",
  CATEGORY = "category",
  SUBCATEGORY = "subcategory",
  COMPLIANCE_STATUS = "compliance_status",
  COMBINED = "combined",
  TEXT_WITH_SOURCE = "text_with_source",
  LIST_OF_TEXT_WITH_SOURCE = "list_of_text_with_source",
  LIST_OF_COMBINED_WITH_SOURCE = "list_of_combined_with_source",
  TEST_RESULTS = "test_results",
  CURRENT_MATURITY_LEVEL = "current_maturity_level",
  OVERALL_RISK = "overall risk",
  LIKELIHOOD = "likelihood",
  THREAT_EVENT_FREQUENCY = "threat event frequency",
  VULNERABILITY = "vulnerability",
  THREAT_ACTOR_CAPABILITY = "threat actor capability",
  CONTROL_STRENGTH = "control strength",
  PREVENTIVE_CONTROLS = "preventive controls",
  DETECTIVE_CONTROLS = "detective controls",
  CORRECTIVE_CONTROLS = "corrective controls",
  IMPACT = "impact",
}

export type RiskNistCsf2ReviewResponse = RiskReviewResponse;

export type FairAssessmentReviewResponse = FairReviewResponse;

export type SOC2GapReviewResponseType = SOC2GapReviewResponse;

export type DoraReviewResponseType = DoraReviewResponse;

export type QuestionnaireReviewResponse = Pick<
  QuestionReviewReponse,
  | "comment"
  | "answer"
  | "justification"
  | "sources"
  | "confidence"
  | "question_text"
  | "question_id"
>;

export type AgentSpecificReviewResponseType<
  T extends AGENT_TYPES,
  S extends AgentSpecificSubTypes<T>,
> = T extends AGENT_TYPES.RISK_ASSESSMENT
  ? S extends RiskAssessmentTypes.NIST_CSF_2
  ? RiskNistCsf2ReviewResponse
  : S extends RiskAssessmentTypes.FAIR
  ? FairAssessmentReviewResponse
  : never
  : T extends AGENT_TYPES.GAP_ASSESSMENT
    ? S extends GapAssessmentTypes.DORA
      ? DoraReviewResponse
      : S extends GapAssessmentTypes.SOC2
        ? SOC2GapReviewResponseType
        : never
    : T extends AGENT_TYPES.QUESTIONNAIRE
      ? S extends QuestionnaireTypes.QA
        ? QuestionnaireReviewResponse
        : never
      : T extends AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT
        ? S extends MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
          ? VendorRiskResponseItemForSecurityAssessment
          : S extends MultiVendorAssessmentTypes.VENDOR_SUPPLY_CHAIN_TABLE
            ? never
            : never
        : never;

export type ReviewResponse<T extends AGENT_TYPES> = {
  ids: string[];
  data: Map<
    string,
    AgentSpecificReviewResponseType<T, AgentSpecificSubTypes<T>>
  >;
};

export interface RiskAgentMainData
  extends AgentMainDataBase<
    AGENT_TYPES.RISK_ASSESSMENT,
    RiskAssessmentTypes.NIST_CSF_2
  > {
    lastEditedField?: string,
    skipDebounce?: boolean,
    lastUpdate?: {
      timestamp: number,
      isRemoteUpdate: boolean,
    }
}

export interface FairAgentMainData
  extends AgentMainDataBase<
    AGENT_TYPES.RISK_ASSESSMENT,
    RiskAssessmentTypes.FAIR
  > {
    lastEditedField?: string;
    skipDebounce?: boolean;
    lastUpdate?: {
      timestamp: number;
      isRemoteUpdate: boolean;
    }
  }

export interface SOC2GapMainData
  extends AgentMainDataBase<
    AGENT_TYPES.GAP_ASSESSMENT,
    GapAssessmentTypes.SOC2
  > {
    lastEditedField?: string,
    skipDebounce?: boolean,
    lastUpdate?: {
      timestamp: number,
      isRemoteUpdate: boolean,
    }
}

export interface DoraMainData
  extends AgentMainDataBase<
    AGENT_TYPES.GAP_ASSESSMENT,
    GapAssessmentTypes.DORA
  > {
    lastEditedField?: string,
    skipDebounce?: boolean,
    lastUpdate?: {
      timestamp: number,
      isRemoteUpdate: boolean,
    }
}

export interface QuestionnaireAgentMainData
  extends AgentMainDataBase<AGENT_TYPES.QUESTIONNAIRE, QuestionnaireTypes.QA> {
  questionnaireFileData: QuestionnaireFileData;
  questionnaireMapping: QuestionnaireMapping;
  selectedSheet: string;
  questionnaireFiller: QuestionnaireFiller[];
  lastEditedField?: string,
  skipDebounce?: boolean,
  lastUpdate?: {
    timestamp: number,
    isRemoteUpdate: boolean,
  }
}

export interface MultiVendorAgentMainData
  extends AgentMainDataBase<
    AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
    MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
  > {
  vendorName: string;
  scopeOfIntegration: string;
  lastEditedField?: string,
  skipDebounce?: boolean,
  lastUpdate?: {
    timestamp: number,
    isRemoteUpdate: boolean,
  }
}

export enum ConfidenceTypes {
  HIGH = "high",
  LOW = "low",
  MEDIUM = "medium",
  NA = "N/A",
  DNF = "Data-Not-Found",
}

export enum ComplianceStatus {
  FULLY_COMPLIANT = "Fully-Compliant",
  PARTIALLY_COMPLIANT = "Partially-Compliant",
  NON_COMPLIANT = "Non-Compliant",
  NO_EXCEPTIONS_NOTED = "No Exceptions Noted",
  EXCEPTION_NOTED = "Exception Noted",
  DNF = "Data-Not-Found",
}

export enum SOC2ComplianceStatusDeprecated {
  FULLY_COMPLIANT = "Fully Compliant",
  PARTIALLY_COMPLIANT = "Partially Compliant",
  NON_COMPLIANT = "Non Compliant",
  DNF = "Data Not Found",
}

export enum FilterOperators {
  EQUALS = "EQUALS",
  NOT_EQUALS = "NOT_EQUALS",
  CONTAINS = "CONTAINS",
  NOT_CONTAINS = "NOT_CONTAINS",
  IS_SET = "IS_SET",
  IS_NOT_SET = "IS_NOT_SET",
  STARTS_WITH = "STARTS_WITH",
  ENDS_WITH = "ENDS_WITH",
  LESS_THAN = "LESS_THAN",
  LESS_THAN_EQUAL_TO = "LESS_THAN_EQUAL_TO",
  GREATER_THAN = "GREATER_THAN",
  GREATER_THAN_EQUAL_TO = "GREATER_THAN_EQUAL_TO",
  BETWEEN = "BETWEEN",
  NOT_BETWEEN = "NOT_BETWEEN",
}

export interface FilterOptionCommon {
  key?: string;
  className?: string;
  label: string;
  id: string;
}

export interface FilterOptionWithValueAsString extends FilterOptionCommon {
  operator:
    | FilterOperators.EQUALS
    | FilterOperators.NOT_EQUALS
    | FilterOperators.CONTAINS
    | FilterOperators.NOT_CONTAINS
    | FilterOperators.STARTS_WITH
    | FilterOperators.ENDS_WITH;
  value: string;
}

export interface FilterOptionWithValueAsNumber extends FilterOptionCommon {
  operator:
    | FilterOperators.LESS_THAN
    | FilterOperators.LESS_THAN_EQUAL_TO
    | FilterOperators.GREATER_THAN
    | FilterOperators.GREATER_THAN_EQUAL_TO;
  value: number;
}

export interface FilterOptionWithValueAsBoolean extends FilterOptionCommon {
  operator: FilterOperators.IS_SET | FilterOperators.IS_NOT_SET;
  value: boolean;
}

export interface FilterOptionWithValueAsRange extends FilterOptionCommon {
  operator: FilterOperators.BETWEEN | FilterOperators.NOT_BETWEEN;
  value: { min: number; max: number };
}

export type FilterOption =
  | FilterOptionWithValueAsString
  | FilterOptionWithValueAsNumber
  | FilterOptionWithValueAsBoolean
  | FilterOptionWithValueAsRange;

export enum FilterSelectionType {
  RADIO = "radio",
  CHECKBOX = "checkbox",
  DROPDOWN = "dropdown",
  DROPDOWN_MULTI = "dropdown_multi",
  DROPDOWN_RANGE = "dropdown_range",
}

export interface FilterCategory {
  category: string;
  type: FilterSelectionType;
  options: FilterOption[];
  key: string;
}

export enum ResponseModes {
  DUMMY = "dummy",
  DEMO = "demo",
  PARTIAL = "partial",
  FULL = "full",
}

export interface CommonReviewSource {
  source_file_name: string;
  file_type: string;
  source_text?: string;
  file_name?: string;
  file_url?: string;
  source_number?: number;
  is_inline_source?: boolean;
  original_file_name?: string;
}

export interface ExcelReviewSource extends CommonReviewSource {
  source_row_number?: number;
  row_number?: number;
  sheet_name?: string;
}

export interface JSONReviewSource extends CommonReviewSource {
  source_json_id?: string;
}

export interface PDFReviewSource extends CommonReviewSource {
  source_page_number?: number;
  source_highlight_area?: HighlightArea[];
  highlight_area?: HighlightArea[];
  page_number?: number;
  original_file_name?: string;
}

export type ReviewSourceTypes =
  | CommonReviewSource
  | ExcelReviewSource
  | JSONReviewSource
  | PDFReviewSource;
