import JSONPreviewHighlight from "@/modules/agent/components/json-preview";
import { PDFReviewSource } from "@/modules/agent/types";
import PDFViewer from "@/shared/components/custom/pdf-preview";
import { Spinner } from "@nextui-org/react";
import { Suspense } from "react";

type Props = {
  source: PDFReviewSource;
  setSelectedSource: React.Dispatch<React.SetStateAction<PDFReviewSource | undefined>>;
}

export default function FileViewer({ source, setSelectedSource }: Props) {

  const renderPreview = () => {
    switch (source.file_type) {
      case "application/pdf":
        return (
          <Suspense fallback={<Spinner label="Loading..." />}>
            <PDFViewer
              fileUrl={source.file_url ?? ""}
              isOpen={true}
              setSelectedSource={setSelectedSource}
              onClose={() => {
                setSelectedSource(undefined);
              }}
              headerTitle={
                source?.file_name ?? ""
              }
            />
          </Suspense>
        );

      case "application/json":
        return (
          <Suspense fallback={<Spinner label="Loading..." />}>
              <JSONPreviewHighlight
                open={true}
                fileUrl={source.file_url as string}
                textToHighlight={""} // Provide appropriate value
                fileName={source?.file_name ?? ""}
                onClose={() => {
                  setSelectedSource(undefined);
                }}
                setSelectedSource={setSelectedSource}
              />
          </Suspense>
        );

      default:
        return null;
    }
  };

  return (
    renderPreview()
  )

}