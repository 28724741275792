import { SVGProps } from "react";

const ASkAiIcon = (props: SVGProps<SVGSVGElement>) => {
  const { color, ...rest } = props;
  return (
    <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.830078 30C0.830078 13.4315 14.2615 0 30.8301 0C47.3986 0 60.8301 13.4315 60.8301 30C60.8301 46.5685 47.3986 60 30.8301 60C14.2615 60 0.830078 46.5685 0.830078 30Z" fill="url(#paint0_linear_3210_23171)" />
      <path d="M0.830078 30C0.830078 13.4315 14.2615 0 30.8301 0C47.3986 0 60.8301 13.4315 60.8301 30C60.8301 46.5685 47.3986 60 30.8301 60C14.2615 60 0.830078 46.5685 0.830078 30Z" fill="white" fillOpacity="0.7" />
      <foreignObject x="-6.07333" y="-6.90633" width="73.8124" height="73.8127">
        <div
          style={{
            backdropFilter: "blur(10.45px)",
            clipPath: "url(#bgblur_0_3210_23171_clip_path)",
            height: "100%",
            width: "100%"
          }}
        />
      </foreignObject><g data-figma-bg-blur-radius="20.9058">
        <path d="M27.7357 35.2499C27.6018 34.7308 27.3312 34.257 26.9522 33.878C26.5731 33.4989 26.0993 33.2283 25.5802 33.0944L16.3777 30.7214C16.2207 30.6768 16.0825 30.5823 15.9842 30.4521C15.8858 30.3218 15.8325 30.1631 15.8325 29.9999C15.8325 29.8367 15.8858 29.6779 15.9842 29.5477C16.0825 29.4175 16.2207 29.3229 16.3777 29.2784L25.5802 26.9039C26.0992 26.7701 26.5728 26.4997 26.9518 26.1209C27.3309 25.7421 27.6016 25.2687 27.7357 24.7499L30.1087 15.5474C30.1528 15.3898 30.2473 15.2509 30.3777 15.152C30.5081 15.0531 30.6673 14.9995 30.831 14.9995C30.9947 14.9995 31.1538 15.0531 31.2843 15.152C31.4147 15.2509 31.5091 15.3898 31.5532 15.5474L33.9247 24.7499C34.0586 25.269 34.3292 25.7427 34.7083 26.1218C35.0874 26.5009 35.5611 26.7715 36.0802 26.9054L45.2827 29.2769C45.441 29.3205 45.5805 29.4149 45.68 29.5455C45.7794 29.6761 45.8333 29.8357 45.8333 29.9999C45.8333 30.164 45.7794 30.3237 45.68 30.4543C45.5805 30.5849 45.441 30.6792 45.2827 30.7229L36.0802 33.0944C35.5611 33.2283 35.0874 33.4989 34.7083 33.878C34.3292 34.257 34.0586 34.7308 33.9247 35.2499L31.5517 44.4524C31.5076 44.61 31.4132 44.7489 31.2828 44.8478C31.1523 44.9467 30.9932 45.0003 30.8295 45.0003C30.6658 45.0003 30.5066 44.9467 30.3762 44.8478C30.2458 44.7489 30.1513 44.61 30.1072 44.4524L27.7357 35.2499ZM42.8302 16.4999V22.4999V16.4999ZM45.8302 19.4999H39.8302H45.8302ZM18.8302 37.4999V40.4999V37.4999ZM20.3302 38.9999H17.3302H20.3302Z" fill="white" />
        <path d="M42.8302 16.4999V22.4999M45.8302 19.4999H39.8302M18.8302 37.4999V40.4999M20.3302 38.9999H17.3302M27.7357 35.2499C27.6018 34.7308 27.3312 34.257 26.9522 33.878C26.5731 33.4989 26.0993 33.2283 25.5802 33.0944L16.3777 30.7214C16.2207 30.6768 16.0825 30.5823 15.9842 30.4521C15.8858 30.3218 15.8325 30.1631 15.8325 29.9999C15.8325 29.8367 15.8858 29.6779 15.9842 29.5477C16.0825 29.4175 16.2207 29.3229 16.3777 29.2784L25.5802 26.9039C26.0992 26.7701 26.5728 26.4997 26.9518 26.1209C27.3309 25.7421 27.6016 25.2687 27.7357 24.7499L30.1087 15.5474C30.1528 15.3898 30.2473 15.2509 30.3777 15.152C30.5081 15.0531 30.6673 14.9995 30.831 14.9995C30.9947 14.9995 31.1538 15.0531 31.2843 15.152C31.4147 15.2509 31.5091 15.3898 31.5532 15.5474L33.9247 24.7499C34.0586 25.269 34.3292 25.7427 34.7083 26.1218C35.0874 26.5009 35.5611 26.7715 36.0802 26.9054L45.2827 29.2769C45.441 29.3205 45.5805 29.4149 45.68 29.5455C45.7794 29.6761 45.8333 29.8357 45.8333 29.9999C45.8333 30.164 45.7794 30.3237 45.68 30.4543C45.5805 30.5849 45.441 30.6792 45.2827 30.7229L36.0802 33.0944C35.5611 33.2283 35.0874 33.4989 34.7083 33.878C34.3292 34.257 34.0586 34.7308 33.9247 35.2499L31.5517 44.4524C31.5076 44.61 31.4132 44.7489 31.2828 44.8478C31.1523 44.9467 30.9932 45.0003 30.8295 45.0003C30.6658 45.0003 30.5066 44.9467 30.3762 44.8478C30.2458 44.7489 30.1513 44.61 30.1072 44.4524L27.7357 35.2499Z" stroke="url(#paint1_linear_3210_23171)" strokeWidth="2" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="bgblur_0_3210_23171_clip_path"><path transform="translate(6.07333 6.90633)" d="M27.7357 35.2499C27.6018 34.7308 27.3312 34.257 26.9522 33.878C26.5731 33.4989 26.0993 33.2283 25.5802 33.0944L16.3777 30.7214C16.2207 30.6768 16.0825 30.5823 15.9842 30.4521C15.8858 30.3218 15.8325 30.1631 15.8325 29.9999C15.8325 29.8367 15.8858 29.6779 15.9842 29.5477C16.0825 29.4175 16.2207 29.3229 16.3777 29.2784L25.5802 26.9039C26.0992 26.7701 26.5728 26.4997 26.9518 26.1209C27.3309 25.7421 27.6016 25.2687 27.7357 24.7499L30.1087 15.5474C30.1528 15.3898 30.2473 15.2509 30.3777 15.152C30.5081 15.0531 30.6673 14.9995 30.831 14.9995C30.9947 14.9995 31.1538 15.0531 31.2843 15.152C31.4147 15.2509 31.5091 15.3898 31.5532 15.5474L33.9247 24.7499C34.0586 25.269 34.3292 25.7427 34.7083 26.1218C35.0874 26.5009 35.5611 26.7715 36.0802 26.9054L45.2827 29.2769C45.441 29.3205 45.5805 29.4149 45.68 29.5455C45.7794 29.6761 45.8333 29.8357 45.8333 29.9999C45.8333 30.164 45.7794 30.3237 45.68 30.4543C45.5805 30.5849 45.441 30.6792 45.2827 30.7229L36.0802 33.0944C35.5611 33.2283 35.0874 33.4989 34.7083 33.878C34.3292 34.257 34.0586 34.7308 33.9247 35.2499L31.5517 44.4524C31.5076 44.61 31.4132 44.7489 31.2828 44.8478C31.1523 44.9467 30.9932 45.0003 30.8295 45.0003C30.6658 45.0003 30.5066 44.9467 30.3762 44.8478C30.2458 44.7489 30.1513 44.61 30.1072 44.4524L27.7357 35.2499ZM42.8302 16.4999V22.4999V16.4999ZM45.8302 19.4999H39.8302H45.8302ZM18.8302 37.4999V40.4999V37.4999ZM20.3302 38.9999H17.3302H20.3302Z" />
        </clipPath><linearGradient id="paint0_linear_3210_23171" x1="30.8301" y1="0" x2="30.8301" y2="60" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F4F4F5" />
          <stop offset="0.235" stopColor="#F8E3EA" />
          <stop offset="0.475" stopColor="#FDD0DF" />
          <stop offset="0.73" stopColor="#EFD2EA" />
          <stop offset="1" stopColor="#E4D4F4" />
        </linearGradient>
        <linearGradient id="paint1_linear_3210_23171" x1="30.8329" y1="14.9995" x2="30.8329" y2="45.0003" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF6496" />
          <stop offset="1" stopColor="#812DD6" />
        </linearGradient>
      </defs>
    </svg>

  );
};

export default ASkAiIcon;
