export enum AiResponseType {
  LITE = "lite",
  ACCURACY = "accuracy",
  SPEED = "speed",
  NONE = "null",
}

export type UserResponseTypesInterface = (
  | "zania_response_quality_accuracy"
  | "zania_response_quality_speed"
  | "zania_response_quality_lite"
)[];