export default function truncateText(text: string): string {
  const firstLetters = 10;
  const lastLetters = 10;
  if (text.length <= firstLetters + lastLetters) {
    return text;
  }
  const truncatedText =
    text.slice(0, firstLetters) + "..." + text.slice(-lastLetters);
  return truncatedText;
}
