import { getZaniaTeamMemberRoles } from "@/modules/team/states";
import { ZCard, ZCardBody } from "@/shared/components/card";
import { ROUTES } from "@/shared/constants/routes";
import { addNotification } from "@/shared/states/notification";
import { useSocketData } from "@/shared/states/sockets";
import { Button, Skeleton } from "@nextui-org/react";
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { assessments } from "../constants/assessments";
import { AGENT_TYPES, UploadDialogState } from "../types/index.ts";
import UploadDialog from "./upload-dialog";
import { useLoggedInMember } from "@/modules/auth/states/index.ts";

export default function Chat() {
  const [uploadDialogState, setUploadDialogState] = useState<UploadDialogState>(
    {
      isOpen: false,
    },
  );

  const navigate = useNavigate();
  const socketData = useSocketData("main");

  const [loading, setLoading] = useState(true);
  const [canAccessZaniaQaAssessment, setCanAccessZaniaQaAssessment] = useState(false);
  const [canAccessZaniaRiskAssessment, setCanAccessZaniaRiskAssessment] = useState(false);
  const [canAccessZaniaGapAssessment, setCanAccessZaniaGapAssessment] = useState(false);
  const [canAccessZaniaVendorAssessment, setCanAccessZaniaVendorAssessment] = useState(false);
  const loggedInMember = useLoggedInMember(); 
  useEffect(() => {
    // Only change it once.
    if (!loading) return;
    // Hide the loading indicator when the socket is connected without errors.
    if (socketData?.isConnected && !socketData?.isErrored) {
      setLoading(false);
    }
  }, [loading, socketData]);

  useEffect(() => {
    if (loggedInMember && loggedInMember?.member_id) {
      const {
        canAccessZaniaQaAssessment,
        canAccessZaniaRiskAssessment,
        canAccessZaniaGapAssessment,
        canAccessZaniaVendorAssessment
      } = getZaniaTeamMemberRoles("", loggedInMember);

        setCanAccessZaniaQaAssessment(canAccessZaniaQaAssessment);
        setCanAccessZaniaRiskAssessment(canAccessZaniaRiskAssessment);
        setCanAccessZaniaGapAssessment(canAccessZaniaGapAssessment);
        setCanAccessZaniaVendorAssessment(canAccessZaniaVendorAssessment);
    }
  }
  , [loggedInMember]);

  if (socketData?.isErrored === true) {
    return (
      <div className="w-full bg-white flex h-full px-24">
        <div className="grow w-full flex flex-col items-start justify-center gap-4">
          <h4 className="text-4xl font-semibold leading-[60px]">
            Something went wrong
          </h4>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            color="primary"
          >
            Reload Page
          </Button>
        </div>
      </div>
    );
  }
 
  

  const onCardClickHandler = (key: string, isComponentReady: boolean) => {
    if (loading) return;

    const accessRestrictedNotifBody = {
      type: 'info',
      title: 'Access Restricted',
      message: (
        <span>
          Contact <a href="mailto:sales@zania.ai" onClick={(e) => e.stopPropagation()}>sales@zania.ai</a> to get access to this feature
        </span>
      ),
    } as Partial<Notification>

    if (!isComponentReady) {
      return addNotification(accessRestrictedNotifBody);
    }

    switch (key) {
      case AGENT_TYPES.QUESTIONNAIRE:
        if (!canAccessZaniaQaAssessment) {
          return addNotification(accessRestrictedNotifBody);
        }
        break;
      case AGENT_TYPES.RISK_ASSESSMENT:
        if (!canAccessZaniaRiskAssessment) {
          return addNotification(accessRestrictedNotifBody);
        }
        break;
      case AGENT_TYPES.GAP_ASSESSMENT:
        if (!canAccessZaniaGapAssessment) {
          return addNotification(accessRestrictedNotifBody);
        }
        break;
      case AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT:
        if (!canAccessZaniaVendorAssessment) {
          return addNotification(accessRestrictedNotifBody);
        }
        break;
      default:
        break;
    }

    setUploadDialogState({
      isOpen: true,
      type: key as AGENT_TYPES,
    });
  };

  
  return (
    <div className="w-full bg-[#FEFEFE]  rounded-md border-[#E4E4E7] border-[1px] flex h-full px-24 gap-32">
      <div className="grow w-full flex flex-col items-start justify-center gap-4">
        <Skeleton isLoaded={!loading} className="rounded">
          <h4 className="text-4xl font-semibold leading-[60px]">
            What would you like to <br />
            accomplish today?
          </h4>
        </Skeleton>
        <Skeleton isLoaded={!loading} className="rounded">
          <h6 className="text-md text-[#71717A]">
            {` Select a task, and let Zania’s AI agents do the heavy lifting in security, risk, and compliance.`}
            
          </h6>
        </Skeleton>
        <Skeleton isLoaded={!loading} className="rounded">
          <Button
            variant="bordered"
            color="primary"
            className="rounded-lg mt-8"
            onClick={() => navigate(`/${ROUTES.AGENT_SESSIONS}`)}
          >
            View Previous Sessions
          </Button>
        </Skeleton>
      </div>
      <div className="grow w-full flex flex-col gap-4 justify-center">
        {assessments.map(
          ({ key, icon: Icon, label, desc, isComponentReady }) => {
            return (
              <ZCard
                key={key}
                onClick={() => onCardClickHandler(key, isComponentReady)}
                isPressable
                isHoverable
                className="p-2 cursor-pointer outline-none shadow-agentTile border-[1px] border-[#E4E4E7]"
              >
                <ZCardBody className="flex flex-row gap-4">
                  <span className="p-2 w-10 h-10 rounded-[12px] border-[1px] border-[#F4F4F5] bg-[#FAFAFA] flex items-center justify-center">
                    <Skeleton isLoaded={!loading}>
                      <Icon />
                    </Skeleton>
                  </span>
                  <Skeleton isLoaded={!loading} className="rounded">
                    <div className="grow">
                      <p>{label}</p>
                      <p className="grow text-[#A1A1AA]">{desc}</p>
                    </div>
                  </Skeleton>
                </ZCardBody>
              </ZCard>
            );
          },
        )}
      </div>
      <UploadDialog data={uploadDialogState} setIsOpen={setUploadDialogState} />
    </div>
  );
}
