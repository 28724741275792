import { Button, cn, Tooltip } from "@nextui-org/react";
import { MouseEventHandler, useCallback, useEffect, useRef, useState } from "react";

import { UploadedFile } from "@/shared/types/file-upload";
import { ArrowUp, PaperclipIcon } from "lucide-react";
import UseAskAiSocketEvents from "../hooks/useAskAiEvents";
import { useChatStore } from "../states";
import PromptInput from "./prompt-input";
import SessionPopOver from "./SessionPopOver";
import AiFileUpload from "./file-upload";

export default function ChatInput() {
  const { message, setMessage, threadId, uploadedFiles, setUploadedFiles, isGenerating, fileUploadError, setFileUploadError } = useChatStore();
  const { sendUserMessage, uploadUserFile } = UseAskAiSocketEvents();

  const displayContainerRef = useRef(null);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [resetFileUpload, setResetFileUpload] = useState(false);

  const handleFileChange = useCallback((newFiles: UploadedFile[]) => {
    const temp = newFiles.filter((file) => !uploadedFiles.some((uploadedFile) => uploadedFile.file.name !== file.file.name));

    setUploadedFiles([...newFiles]);

    const signedUrls = temp.filter((file) => file.status === "success")
      .map((file) => file.signedUrl)
      .filter((url): url is string => url !== undefined);

    if (signedUrls.length) {
      uploadUserFile(threadId, signedUrls);
    }
    if (signedUrls.length !== temp.length) {
      setFileUploadError(true);
    } else {
      setFileUploadError(false)
    }
  }, [setUploadedFiles, threadId, uploadUserFile]);

  useEffect(() => {
    if (uploadedFiles.length === 0) {
      setResetFileUpload(true);

    } else {
      setResetFileUpload(false);
    }
  }, [uploadedFiles]);

  const handleSendMessage = () => {
    // if message is not empty and not generating and no file upload error then send message
    if (message && message.trim() && !isGenerating && !fileUploadError) {
      sendUserMessage(threadId, message);
      setMessage("");
    }

  };

  return (
    <div>
      <form className={`flex w-full flex-col items-start rounded-medium bg-default-100 transition-colors hover:bg-default-200/70`}>
        <div className="pt-3">
          {uploadedFiles && uploadedFiles.length > 0 && (
            <div className="flex pl-4">
              <div className="flex flex-wrap gap-2" ref={displayContainerRef} id="file-list-container" />
            </div>
          )}
        </div>
        <PromptInput
          onKeyDown={(event) => {
            // if only enter then send message if shift + Enter new line
            if (event.key === 'Enter' && !event.shiftKey) {
              event.preventDefault();
              handleSendMessage();
            }
          }
          }
          classNames={{
            inputWrapper: "!bg-transparent shadow-none",
            innerWrapper: "relative",
            input: "pt-1 pl-2 pb-6 !pr-10 text-sm",
          }}
          endContent={
            <div className="flex items-end gap-2">
              <Tooltip showArrow content="Send message"
                delay={500}
                closeDelay={0}
                classNames={{
                  content: "bg-black/95 backdrop-blur-md text-white text-tiny p-3",
                  arrow: "backdrop-blur-md",
                  base: "before:bg-black/95", // This targets the pseudo-element used for the arrow
                }}>
                <Button
                  onClick={() => handleSendMessage()}
                  isIconOnly
                  color={!message ? "default" : "primary"}
                  isDisabled={!message || isGenerating || fileUploadError}
                  radius="lg"
                  size="sm"
                  variant="solid"
                >
                  <ArrowUp
                    className={cn(
                      "[&>path]:stroke-[2px]",
                      !message ? "text-default-600" : "text-primary-foreground",
                    )}
                  />
                </Button>
              </Tooltip>
            </div>
          }
          minRows={3}
          radius="lg"
          value={message}
          variant="flat"
          onValueChange={setMessage}
        />
        <div className="flex w-full items-center justify-between gap-2 px-4 pb-4">
          <div className={`flex w-full gap-1 md:gap-3 ${isGenerating ? "opacity-50 pointer-events-none" : ""}`}>
            <AiFileUpload
              reset={resetFileUpload}
              displayChildren={true}
              displayContainer={displayContainerRef}
              ref={fileInputRef}
              maxFiles={10}

              displayOnlyButton={true}
              orientation="horizontal"
              isMultiple={true}
              allowedFileTypes={[
                "application/json",
                "application/pdf",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              ]}
              setFiles={handleFileChange}
            >{(handleDropFile: MouseEventHandler<HTMLButtonElement>) => (
              <Tooltip
                delay={500}
                showArrow={true}
                closeDelay={0}
                classNames={{
                  content: "bg-black/95 backdrop-blur-md text-white text-tiny p-3",
                  arrow: "backdrop-blur-md",
                  base: "before:bg-black/95",
                }}
                content=<div>
                  <p>Upload up to 10 files 20MB each.</p>
                  Format accepted: .json, .pdf, .doc, .docx
                </div>
              >
                <Button
                  size="sm"
                  startContent={<PaperclipIcon size={18} />}
                  variant="flat"
                  onClick={handleDropFile}
                >
                  Attach
                </Button>
              </Tooltip>
            )}
            </AiFileUpload>
            <SessionPopOver />

          </div>
          <p className="py-1 text-tiny text-default-400">{message.length}/2000</p>
        </div>
      </form>
      <p className="py-2 px-2 text-tiny text-default-400 text-center">
        Ask AI references latest files and assessment results, ignoring sessions without them.
      </p>
    </div>

  );
}

