import { create } from "zustand";
import { LoggedInMember, LoggedInOrganization, LOGIN_STEP } from "../types";
import { resetLoginDetails, setLoginStep } from "./login";
import { deleteCookie } from "@/shared/utils/cookie-helper";

interface AuthState {
  isAuthenticated: boolean;
  isAuthenticationInProgress: boolean;
  loggedInMember?: LoggedInMember | null;
  loggedInOrganization?: LoggedInOrganization | null;
}

interface AuthActions {
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  setIsAuthenticatingInProgress: (isAuthenticationInProgress: boolean) => void;
  setAuth: (auth: AuthState) => void;
  resetAuth: () => void;
  logout: () => void;
  setLoggedInMember: (loggedInMember: LoggedInMember) => void;
  setLoggedInOrganization: (loggedInOrganization: LoggedInOrganization) => void;
}

type AuthStore = AuthState & {
  actions: AuthActions;
};

const initialState: AuthState = {
  isAuthenticationInProgress: true,
  isAuthenticated: false,
  loggedInMember: null,
  loggedInOrganization: null,
};

const useAuthStore = create<AuthStore>((set) => ({
  ...initialState,
  actions: {
    setIsAuthenticated: (isAuthenticated) =>
      set((state) => {
        if (isAuthenticated) resetLoginDetails();
        return { ...state, isAuthenticated };
      }),
    setIsAuthenticatingInProgress: (isAuthenticationInProgress) =>
      set((state) => ({ ...state, isAuthenticationInProgress })),
    setAuth: (auth) => set(auth),
    resetAuth: () => set(initialState),
    logout: () => {
      setLoginStep(LOGIN_STEP.MAIN);
      set({
        isAuthenticated: false,
        isAuthenticationInProgress: false,
        loggedInMember: null,
        loggedInOrganization: null
      });
      deleteCookie("intermediate_session_token");
    },
    setLoggedInMember: (loggedInMember) => set({ loggedInMember }),
    setLoggedInOrganization: (loggedInOrganization) =>
      set({ loggedInOrganization }),
  },
}));

export const useIsAuthenticated = () =>
  useAuthStore((state) => state.isAuthenticated);
export const useIsAuthenticationInProgress = () =>
  useAuthStore((state) => state.isAuthenticationInProgress);
export const useAuthActions = () => useAuthStore((state) => state.actions);
export const useAuth = () => useAuthStore();
export const setAuth = useAuthStore.getState().actions.setAuth;
export const resetAuth = useAuthStore.getState().actions.resetAuth;
export const useLoggedInMember = () =>
  useAuthStore((state) => state.loggedInMember);
export const useLoggedInOrganization = () =>
  useAuthStore((state) => state.loggedInOrganization);

