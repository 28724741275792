import { getAgentData } from "@/modules/agent/states";
import {
  getCustomizeControls,
  getCustomizeControlsActions,
  getCustomizeControlsAiOpenStates,
  VendorAssessmentControl,
} from "@/modules/agent/states/cusomize-controls";
import {
  RefineControlDescriptionResponseForVendor,
  refineControlsDescription,
} from "@/modules/agent/use-cases/customize-control-ai-generate.use-case";
import {
  AgentSessionStepType,
  AgentSessionType,
} from "@/modules/sessions/types";
import AcceptAndRejectPopOver from "@/shared/components/generate-with-ai/AcceptAndRejectPopOver";
import GenerateWithAI from "@/shared/components/generate-with-ai/GenerateWithAI";
import { AiResponseType } from "@/shared/types/user";
import { cn } from "@/shared/utils/classname-merger";
import { Checkbox, Tooltip } from "@nextui-org/react";
import { Info } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ZInputWithOnChangeDelay,
  ZTextAreaWithOnChangeDelay,
} from "../helpers";

export const CustomizeControlVendorCategoryRenderer = ({
  control,
  handleChangeDataState,
  searchQuery,
  selectedControls,
  setSelectedControls,
  selectedTab,
}: {
  control: VendorAssessmentControl;
  handleChangeDataState: (
    key: keyof VendorAssessmentControl,
    uId: string,
    newValue: string,
    changesInTestingProcedure?: number
  ) => void;
  searchQuery: string;
  selectedControls: Set<string>;
  setSelectedControls: (
    newVal: Set<string> | ((prev: Set<string>) => Set<string>)
  ) => void;
  selectedTab: string | null;
}) => {
  const [isActive, setIsActive] = useState(false);

  const listOfid = (getCustomizeControls() as VendorAssessmentControl[])
    .filter((d) => d.uid !== control.uid)
    .filter((ctl) => control.category.replace("AI_", "").trim().length > 0)
    .map((d) => d.category);

  const isCheckboxDisabled = getCustomizeControlsAiOpenStates() > 0;
  const isDuplicateId = listOfid.includes(control.category);
  const isInIdValid = false;
  return (
    <div className={cn("flex ")}>
      <div className="flex flex-col">
        <div className="flex ">
          <Checkbox
            isSelected={selectedControls.has(control.uid)}
            classNames={{
              base: cn("group", control.edited_state === "NEW" && "invisible"),
              wrapper: cn(
                "group-data-[disabled=true]:border-default",
                "after:group-data-[disabled=true]:bg-default-100"
              ),
              icon: "group-data-[disabled=true]:text-default-300",
            }}
            onChange={() => {
              setSelectedControls((prev) => {
                const newValue = prev;

                if (selectedControls.has(control.uid)) {
                  newValue.delete(control.uid);
                } else {
                  newValue.add(control.uid);
                }
                return newValue;
              });
            }}
            isDisabled={isCheckboxDisabled}
            disabled={isCheckboxDisabled}
            data-component="z-table-row-select"
            color={"primary"}
          />
          <ZInputWithOnChangeDelay
            id={`${control.uid}key`}
            aria-label={`${control.uid}key`}
            classNames={{
              inputWrapper:
                "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none active:bg-white",
              base:
                "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none active:bg-white",
              clearButton:
                "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none active:bg-white",
              description:
                "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none active:bg-white",
              errorMessage:
                "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none active:bg-white",
              helperWrapper:
                "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none active:bg-white",
              innerWrapper:
                "bg-transparent p-0 pl-[6px] data-[hover=true]:bg-transparent shadow-none border-none active:bg-white",
              input:
                "bg-transparent p-[6px] pl-0 data-[hover=true]:bg-transparent shadow-none border-none active:bg-white",
              label:
                "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none active:bg-white",
              mainWrapper:
                "bg-transparent p-0  data-[hover=true]:bg-transparent shadow-none border-none active:bg-white",
            }}
            startContent={
              selectedTab === "ai_security_assessment" ? "AI" : undefined
            }
            placeholder="Add Category"
            value={
              selectedTab === "ai_security_assessment"
                ? control.category.replace("AI_", "").replaceAll("_", " ")
                : control.category.replaceAll("_", " ")
            }
            onChange={(e) => {
              let newValue = e.target.value.trim();
              newValue = newValue.replaceAll(" ", "_");
              if (selectedTab === "ai_security_assessment") {
                newValue = `AI_${newValue}`;
              }
              handleChangeDataState("category", control.uid, newValue);
            }}
            disabled={searchQuery.length > 0}
            isInvalid={isInIdValid || isDuplicateId}
            onFocus={() => setIsActive(true)}
            onBlur={() => setIsActive(false)}
            autoFocus={false}
          />
        </div>
        <div className="flex flex-row">
          <div className="pl-6">
            {(isInIdValid || isDuplicateId) && (
              <div className="flex flex-row gap-1 p-1.5 ">
                {isInIdValid && (
                  <Tooltip
                    showArrow={true}
                    content={<div>Expected Format: vendor assessment</div>}
                    delay={500}
                    closeDelay={0}
                    classNames={{
                      content:
                        "bg-black bg-opacity-80 backdrop-blur-md text-white text-tiny max-w-[600px]",
                      arrow: "bg-black bg-opacity-80 backdrop-blur-md",
                    }}
                    placement="bottom-start"
                  >
                    <span>
                      <Info size={16} strokeWidth={1.5} color="#F31260" />
                    </span>
                  </Tooltip>
                )}
                <div className="text-[#F31260] text-xs font-medium">
                  {isInIdValid
                    ? "Invalid Format"
                    : isDuplicateId
                    ? "Category already exists"
                    : ""}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const CustomizeControlVendorControlDescriptionRenderer = ({
  control,
  handleChangeDataState,
  searchQuery,
}: {
  control: VendorAssessmentControl;
  handleChangeDataState: (
    key: keyof VendorAssessmentControl,
    uId: string,
    newValue: string,
    changesInTestingProcedure?: number
  ) => void;
  searchQuery: string;
}) => {
  const { id = "" } = useParams<{ id: string }>();

  const [isRowActive, setIsRowActive] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showAcceptAndRejectPopover, setShowAcceptAndRejectPopover] = useState<
    false | "SUCCESS" | "ERROR"
  >(false);

  const handleClickForRefineControls = useCallback(async () => {
    try {
      setIsLoading(true);
      const agentData = getAgentData(id);
      let response_quality =
        agentData?.sessionData.type === AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT
          ? agentData?.sessionData.multi_file_vendor_assessment.response_quality
          : AiResponseType.LITE;

      if (
        response_quality === undefined ||
        response_quality === null ||
        response_quality === AiResponseType.NONE
      ) {
        response_quality = AiResponseType.LITE;
      }

      const resp = (await refineControlsDescription({
        task: AgentSessionStepType.MULTI_FILE_VENDOR_ASSESSMENT,
        controls: [control],
        response_quality,
      })) as RefineControlDescriptionResponseForVendor;

      if (resp.data.controls[0].question) {
        handleChangeDataState(
          "new_question",
          control.uid,
          resp.data.controls[0].question
        );
      }

      setIsLoading(false);
      if (showAcceptAndRejectPopover === false) {
        getCustomizeControlsActions().setNoOfGenerateWithAiPopoverOpens(
          (prev) => prev + 1
        );
      }
      setShowAcceptAndRejectPopover("SUCCESS");
    } catch (err) {
      setIsLoading(false);
      if (showAcceptAndRejectPopover === false) {
        getCustomizeControlsActions().setNoOfGenerateWithAiPopoverOpens(
          (prev) => prev + 1
        );
      }
      setShowAcceptAndRejectPopover("ERROR");
      throw new Error("Failed to refine control descroiption ");
    }
  }, [control, handleChangeDataState, id, showAcceptAndRejectPopover]);

  const handleClickForAcceptRetryRejectButtons = useCallback(
    (action: "CONTINUE" | "DISCARD") => {
      const { setDataState } = getCustomizeControlsActions();

      if (action === "DISCARD") {
        setShowAcceptAndRejectPopover(false);
        getCustomizeControlsActions().setNoOfGenerateWithAiPopoverOpens(
          (prev) => prev - 1
        );
        setDataState((prev) => {
          const newValue = [...(prev as VendorAssessmentControl[])];

          const findIndexOfEditingControl = newValue.findIndex(
            (d) => d.uid === control.uid
          );
          if (findIndexOfEditingControl > -1) {
            newValue[findIndexOfEditingControl].new_question = undefined;
          }

          return newValue;
        });
      }
      if (action === "CONTINUE") {
        if (showAcceptAndRejectPopover === "ERROR") {
          handleClickForRefineControls();
        }
        if (showAcceptAndRejectPopover === "SUCCESS") {
          setDataState((prev) => {
            const newValue = [...(prev as VendorAssessmentControl[])];

            const findIndexOfEditingControl = newValue.findIndex(
              (d) => d.uid === control.uid
            );
            if (findIndexOfEditingControl > -1) {
              newValue[findIndexOfEditingControl].question =
                newValue[findIndexOfEditingControl].new_question ??
                newValue[findIndexOfEditingControl].question;
              newValue[findIndexOfEditingControl].new_question = undefined;
              newValue[findIndexOfEditingControl].changesInQuestion = 0;
            }

            return newValue;
          });
          setShowAcceptAndRejectPopover(false);
          getCustomizeControlsActions().setNoOfGenerateWithAiPopoverOpens(
            (prev) => prev - 1
          );
          getCustomizeControlsActions().setChangesMade((prev) => prev + 1); // to trigger autosave
        }
      }
    },
    [handleClickForRefineControls, showAcceptAndRejectPopover, control.uid]
  );

  useEffect(() => {
    const el = document.getElementById("customize-control-table");
    let t: NodeJS.Timeout;
    if (showAcceptAndRejectPopover) {
      if (el) {
        if (
          getCustomizeControlsAiOpenStates() === 0 ||
          getCustomizeControlsAiOpenStates() === 1
        ) {
          containerRef.current?.scrollIntoView({});
          el.scrollBy(0, -50);
        }
        t = setTimeout(() => {
          if (el.classList.contains("overflow-auto")) {
            el.classList.remove("overflow-auto");
            el.classList.add("overflow-hidden");
          }
        }, 500);
      }
    } else if (getCustomizeControlsAiOpenStates() === 0) {
      if (el) {
        if (el.classList.contains("overflow-hidden")) {
          el.classList.add("overflow-auto");
          el.classList.remove("overflow-hidden");
        }
      }
    }
    return () => {
      if (t) {
        clearTimeout(t);
      }
    };
  }, [showAcceptAndRejectPopover]);

  useEffect(() => {
    const focusInHandler = (event: any) => {
      if (event?.target?.id?.includes(control.uid)) {
        setIsRowActive(true);
      }
    };
    const focusOutHandler = () => {
      setIsRowActive(false);
    };
    window.addEventListener("focusin", focusInHandler);
    window.addEventListener("focusout", focusOutHandler);
    return () => {
      window.removeEventListener("focusin", focusInHandler);
      window.removeEventListener("focusout", focusOutHandler);
    };
  }, [control]);

  return (
    <div className="h-full min-w-[420px]" id={control.uid} ref={containerRef}>
      <ZTextAreaWithOnChangeDelay
        id={`${control.uid}question`}
        aria-label={`${control.uid}question`}
        className={cn(
          "text-zinc-700 font-light bg-transparent  focus-within:text-[#000000] mb-[5px]",
          control.question !== undefined && "mb-0"
        )}
        classNames={{
          base:
            "shadow-none  border-none hover:border-none rounded-none truncate",
          inputWrapper: ` text-sm font-normal text-zinc-700 p-px ${
            true ? "border-none " : ""
          }  ${
            false ? "text-[#000000]" : "text-zinc-700"
          } border-1 border-[#ECECEC]`,
          input: "p-[4px]",
        }}
        forceActive={isLoading || showAcceptAndRejectPopover !== false}
        strikeContent={
          showAcceptAndRejectPopover !== false
            ? control.question !== undefined
            : undefined
        }
        minRows={3}
        maxRows={8}
        disabled={searchQuery.length > 0}
        variant="bordered"
        labelPlacement="outside"
        value={control.question}
        placeholder="Add Control Description"
        onChange={(e) =>
          handleChangeDataState("question", control.uid, e.target.value)
        }
        autoFocus={false}
      />

      {control.new_question !== undefined && (
        <ZTextAreaWithOnChangeDelay
          id={`${control.uid}new_question`}
          aria-label={`${control.uid}new_question`}
          className="text-zinc-700 font-light bg-transparent  focus-within:text-[#000000] mb-[5px]"
          classNames={{
            base:
              "shadow-none  border-none hover:border-none rounded-none truncate",
            inputWrapper: ` text-sm font-normal text-zinc-700 p-px ${
              true ? "border-none " : ""
            }  ${
              false ? "text-[#000000]" : "text-zinc-700"
            } border-0 border-[#ECECEC]`,
            input: "p-[4px]",
          }}
          forceActive={true}
          strikeContent={false}
          minRows={3}
          maxRows={8}
          disabled={searchQuery.length > 0}
          variant="bordered"
          labelPlacement="outside"
          value={control.new_question}
          placeholder="Add Control Description"
          onChange={(e) =>
            handleChangeDataState("new_question", control.uid, e.target.value)
          }
          autoFocus={false}
        />
      )}
      {showAcceptAndRejectPopover && (
        <div className="h-5">
          <AcceptAndRejectPopOver
            isOpen
            type={showAcceptAndRejectPopover}
            handleClickForAcceptRetryRejectButtons={
              handleClickForAcceptRetryRejectButtons
            }
            handleClose={() => setShowAcceptAndRejectPopover(false)}
          />
        </div>
      )}
      {showAcceptAndRejectPopover === false && (
        <div
          className={cn(
            "group-hover:h-5 h-0 overflow-hidden ease-in-out duration-200",
            (isRowActive || isLoading || showAcceptAndRejectPopover) && "h-5"
          )}
        >
          <GenerateWithAI
            contextText={control.question}
            existingText={control.question}
            isLoadingAIResponse={isLoading}
            handleClickForRefineControls={handleClickForRefineControls}
            handleClickForGenerateControls={async () => {}}
            showGenerate={false}
            showRefine={true}
            type="CONTROL_DESCRIPTION"
            disableGenerate={false}
            disableGenerateTooltipMessage=""
            disableRefine={
              // (control.changesInQuestion ?? 0) === 0 ||
              control.question.trim().length === 0
            }
            // disableRefineTooltipMessage="You will be able to refine if new edits are made to control description"
            disableRefineTooltipMessage={[
              "You will be able to refine",
              "if new edits are made ",
              "to question",
            ]}
          />
        </div>
      )}
    </div>
  );
};
