// routes for zania assistant
import { ROUTES } from "@/shared/constants/routes";
import { RouteObject } from "react-router-dom";
import ZaniaComponent from "../components";

export const AssistantRoutes: RouteObject[] = [
  {
    path: ROUTES.ASK_AI,
    Component: ZaniaComponent,
  },
];
