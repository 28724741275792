import { ROUTES } from "@/shared/constants/routes";
import RocketIcon from "@/shared/icons/rocket";
import SessionsIcon from "@/shared/icons/sessions";
import SettingsIcon from "@/shared/icons/settings";
import TeamIcon from "@/shared/icons/team";
import { Sparkles } from "lucide-react";
import { NavItemData } from "../types/sidebar";

export const sidebarItems: NavItemData[] = [
  {
    title: "AI Agent",
    Icon: RocketIcon,
    path: ROUTES.AGENT,
  },
  {
    title: "Sessions",
    Icon: SessionsIcon,
    path: ROUTES.AGENT_SESSIONS,
  },
  // {
  //   title: 'Dashboard',
  //   path: ROUTES.DASHBOARD,
  //   Icon: DashboardIcon,
  // },
  // {
  //   title: 'Workflows',
  //   path: ROUTES.WORKFLOWS,
  //   Icon: LighteningIcon,
  // },
  // {
  //   title: 'Integrations',
  //   path: ROUTES.INTEGRATIONS,
  //   Icon: PuzzlePieceIcon,
  // },
  // {
  //   title: 'Knowledge Base',
  //   label: '',
  //   path: ROUTES.KNOWLEDGE_BASE,
  //   Icon: KnowledgeBaseIcon,
  // },
  {
    title: "Team",
    label: "",
    path: ROUTES.TEAM,
    Icon: TeamIcon,
  },
  {
    title: "Ask AI",
    label: "",
    path: ROUTES.ASK_AI,
    Icon: Sparkles,
  },
  {
    title: "Settings",
    label: "",
    path: ROUTES.SETTINGS,
    Icon: SettingsIcon,
  }
];
