import {
  CheckCircleIcon,
  HourglassIcon,
  TriangleAlertIcon,
} from "lucide-react";
import { AgentSessionStatus, AgentSessionType } from "../types";

export const agentSessionTypeLabelMap = {
  [AgentSessionType.RISK_ASSESSMENT]: "NIST CSF 2.0 Assessment",
  [AgentSessionType.NIST_AI_RMF]: "NIST AI RMF Risk Assessment",
  [AgentSessionType.QA_FILLER]: "Security Questionnaire",
  [AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT]:
    "Third-Party Risk Assessment",
  [AgentSessionType.SOC2_GAP]: "SOC2 Gap Assessment",
  [AgentSessionType.SOC2_GAP_TYPE1]: "SOC2 Gap Assessment",
  [AgentSessionType.SOC2_GAP_TYPE2]: "SOC2 Gap Assessment",
  [AgentSessionType.DORA_GAP]: "DORA Gap Assessment",
} as const;

export const agentSessionStatusLabelMap = {
  [AgentSessionStatus.PENDING]: {
    label: "Pending",
    borderColor: "#006FEE",
    backgroundColor: "#006FEE1A",
    renderIcon: () => {
      return <HourglassIcon size={13} />;
    },
  },
  [AgentSessionStatus.QUEUED]: {
    label: "Queued",
    borderColor: "#006FEE",
    backgroundColor: "#006FEE1A",
    renderIcon: () => {
      return <HourglassIcon size={13} />;
    },
  },
  [AgentSessionStatus.IN_PROGRESS]: {
    borderColor: "#006FEE",
    backgroundColor: "#006FEE1A",
    label: "In Progress",
    renderIcon: () => {
      return <HourglassIcon size={13} />;
    },
  },
  [AgentSessionStatus.UNDER_REASSESSMENT]: {
    borderColor: "#006FEE",
    backgroundColor: "#006FEE1A",
    label: "Under Reassessment",
    renderIcon: () => {
      return <HourglassIcon size={13} />;
    },
  },
  [AgentSessionStatus.INPUT_NEEDED]: {
    label: "Input Needed",
    borderColor: "#F5A524",
    backgroundColor: "#F5A5241A",
    renderIcon: () => {
      return <TriangleAlertIcon size={13} />;
    },
  },
  [AgentSessionStatus.COMPLETE]: {
    label: "Completed",
    borderColor: "#1FCB6A",
    backgroundColor: "#1FCB6A1A",
    renderIcon: () => {
      return <CheckCircleIcon size={13} />;
    },
  },
} as const;

export const frameworkTypeLabels: Record<string, string> = {
  soc2_type1: "SOC 2 Type I",
  soc2_type2: "SOC 2 Type II",
  soc2_type2_audit: "SOC 2 Type II",
  dora_assessment: "DORA",
  risk_assessment: "NIST CSF 2.0",
  nist_ai_rmf: "NIST AI RMF",
  fair_ra: "FAIR",
};

export const searchPlaceholderMap = {
  [AgentSessionType.RISK_ASSESSMENT]: "Search by Session Title or Framework",
  [AgentSessionType.SOC2_GAP]: "Search by Session Title or Framework",
  [AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT]:
    "Search by Session Title or Vendor Product Name",
  [AgentSessionType.QA_FILLER]: "Search by Session Title or Customer Name",
  [AgentSessionType.DORA_GAP]: "Search by Session Title or Framework",
};
