import ChatLayout from "@/modules/agent/components/layout";
import { getAgentSession } from "@/modules/sessions/requests";
import { AgentSessionType } from "@/modules/sessions/types";
import {
  handleContinueDoraGapAssessment,
  handleContinueFairAssessment,
  handleContinueMultiVendorAssessment,
  handleContinueQuestionnaire,
  handleContinueRiskAssessment,
  handleContinueSoc2GapAssessment
} from "@/modules/sessions/use-cases/agent-session.use-case";
import { ROUTES } from "@/shared/constants/routes";
import { addNotification } from "@/shared/states/notification";
import { LoaderFunctionArgs, redirect, RouteObject } from "react-router-dom";
import Chat from "../components";
import AgentSuccess from "../components/success";
import { getAgentData } from "../states";
import { handleContinueCustomizeControls } from "../use-cases/customize-control.use-case";

const sessionTypeHandlers: {
  [key in AgentSessionType]: (params: {
    session: any;
    steps: any;
  }) => Promise<void> | void;
} = {
  [AgentSessionType.RISK_ASSESSMENT]: handleContinueRiskAssessment,
  [AgentSessionType.NIST_AI_RMF]: handleContinueRiskAssessment,
  [AgentSessionType.FAIR_ASSESSMENT]: handleContinueFairAssessment,
  [AgentSessionType.QA_FILLER]: handleContinueQuestionnaire,
  [AgentSessionType.SOC2_GAP]: handleContinueSoc2GapAssessment,
  [AgentSessionType.SOC2_GAP_TYPE1]: handleContinueSoc2GapAssessment,
  [AgentSessionType.SOC2_GAP_TYPE2]: handleContinueSoc2GapAssessment,
  [AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT]: (params) =>
    handleContinueMultiVendorAssessment(params),
  [AgentSessionType.DORA_GAP]: handleContinueDoraGapAssessment,
};

const agentLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  if (!id) {
    addNotification({
      message: "Agent not found",
      type: "error",
    });
    return redirect(`/${ROUTES.AGENT}`);
  }
  const agent = getAgentData(id);
  if (agent) {
    await handleContinueCustomizeControls(agent.stepData, agent.sessionData);
    return true;
  }
  try {
    const agentFromApi = await getAgentSession(id);

    if (!agentFromApi || !agentFromApi.data) {
      addNotification({
        message: "Agent not found",
        type: "error",
      });
      return redirect(`/${ROUTES.AGENT}`);
    }
    const agentType = agentFromApi.data.session.type;
    const handler = sessionTypeHandlers[agentType];
    await handleContinueCustomizeControls(
      agentFromApi.data.steps,
      agentFromApi.data.session
    );
    if (handler) {
      await handler({
        session: agentFromApi.data.session,
        steps: agentFromApi.data.steps,
      });
    }
  } catch (error) {
    addNotification({
      message: "Error while continuing assessment.",
      type: "error",
    });
    return redirect(`/${ROUTES.AGENT}`);
  }
  return true;
};

export const AgentRoutes: RouteObject[] = [
  {
    path: ROUTES.AGENT,
    children: [
      {
        path: ":id",
        // lazy: importLazy(() => import("@/modules/agent/components/layout")),
        Component: ChatLayout,
        loader: agentLoader,
      },
      {
        path: ":id/vendor-risk-assessment",
        // lazy: importLazy(() => import("@/modules/agent/components/layout")),
        Component: ChatLayout,
        loader: agentLoader,
      },
      {
        index: true,
        // lazy: importLazy(() => import("@/modules/agent/components")),
        Component: Chat,
      },
    ],
  },
  {
    path: `${ROUTES.AGENT}/${ROUTES.SUCCESS}/:id`,
    // lazy: importLazy(() => import("@/modules/agent/components/success")),
    Component: AgentSuccess,
  },
];
