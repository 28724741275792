import { ZTooltip } from "@/shared/components/ZTooltip/ZTooltip";
import { Button } from "@nextui-org/react";
import { ArrowLeft, Clock7, SquarePen } from "lucide-react";
import { useChatStore } from "../states";

export default function ChatHeader() {

  const btnClassName = "border-blue-500 text-blue-500 bg-transparent hover:bg-blue-50 w-6 h-6 min-w-0 border-[1px] ";
  const { isOpen, isShowHistory, isGenerating, setIsOpen, setMessage, setSelectedSession, setFileUploadError, setIsGenerating, setUploadedFiles, setThreadId, setMessages, setIsShowHistory } = useChatStore();

  // Reset all states when new chat is initiated
  const handleNewChat = () => {
    setIsOpen(false);
    setMessage('');
    setSelectedSession({});
    setFileUploadError(false);
    setIsGenerating(false);
    setUploadedFiles([]);
    setThreadId('');
    setMessages([]);
  }

  return (
    <div className="flex gap-3 items-center">
      {isShowHistory &&
        <ArrowLeft size={20} className="cursor-pointer" onClick={() => setIsShowHistory(false)} />
      }
      <span className="mr-2">{isShowHistory ? 'History' : 'Ask AI'}</span>

      {
        !isShowHistory && (
          <>
            <ZTooltip content={`New Chat`} showArrow={true} delay={0} >
              <Button isIconOnly
                disabled={!isOpen || isGenerating}
                size="sm"
                variant="bordered"
                onClick={() => handleNewChat()}
                className={`${btnClassName} ${!isOpen || isGenerating? 'pointer-events-none border-blue-300 text-blue-300' : ''}`}
              >
                <SquarePen size={16} strokeWidth={1} />
              </Button>
            </ZTooltip>
            <ZTooltip content="History" placement="right" showArrow={true} delay={0} >
              <Button isIconOnly
                disabled={isGenerating}
                onClick={() => setIsShowHistory(true)}
                size="sm"
                variant="bordered"
                className={`${btnClassName} ${isGenerating ? 'pointer-events-none border-blue-300 text-blue-300' : ''}`}
              >
                <Clock7 size={16} strokeWidth={1} />
              </Button>
            </ZTooltip>
          </>)
      }

    </div>
  )
}