import { AgentSessionType } from "@/modules/sessions/types";
import { UploadedFile } from "@/shared/types/file-upload";
import { NavigateFunction } from "react-router-dom";
import {
	AGENT_TYPES,
	AgentData,
	AgentSourceFile,
	GapAssessmentTypes,
	MultiVendorAssessmentTypes,
	QuestionnaireTypes,
	RiskAssessmentTypes,
} from "../../types";
import { processFileForDoraGapAssessment } from "../../use-cases/dora.use-case";
import { processFileForFairAssessment } from "../../use-cases/fair.use-case";
import { processFilesForMapping } from "../../use-cases/quesitionnaire.use-case";
import { processFileForRiskAssessment } from "../../use-cases/risk.use-case";
import { processFileForSoc2GapAssessment } from "../../use-cases/soc2.use-case";
import { processDataForVendorAssessment, processFileForVendorReassessment } from "../../use-cases/vendor.use-case";

export const processDataBasedOnAssessment = async (
	type: string | undefined,
	allFiles: UploadedFile[],
	navigate: NavigateFunction,
	inputs?: { [key: string]: string | boolean },
	selectedDropdownOption?: string | undefined,
	isToggleSelected?: boolean,
	reassessOption?: string,
	agentData?:
		| AgentData<
				AGENT_TYPES,
				| RiskAssessmentTypes
				| GapAssessmentTypes
				| QuestionnaireTypes
				| MultiVendorAssessmentTypes
		  >
		| undefined,
	prevSourceFiles?: AgentSourceFile[],
) => {
	const allFilesMap = allFiles.map((file) => {
		return {
			fileName: file.file.name ?? "",
			url: file.signedUrl ?? "",
			fileType: file.file.type,
		};
	});
	const allSourceFiles =
		prevSourceFiles && prevSourceFiles.length > 0
			? [...allFilesMap, ...prevSourceFiles]
			: [...allFilesMap];

	switch (type) {
		case AGENT_TYPES.QUESTIONNAIRE: {
			const questionnaireFile = allFiles.filter((file) => file.type === "main");
			const kbFiles = allFiles.filter((file) => file.type === "kb");
			await processFilesForMapping({
				name: (inputs?.title as string) || "",
				questionnaireFile: {
					file: questionnaireFile[0].file,
					url: questionnaireFile[0].signedUrl ?? "",
				},
				sourceFiles: kbFiles.map((file) => {
					return {
						fileName: file.file.name,
						url: file.signedUrl ?? "",
					};
				}),
				navigate,
			});
			return;
		}
		case AGENT_TYPES.RISK_ASSESSMENT: {
			switch (selectedDropdownOption) {
				case RiskAssessmentTypes.NIST_CSF_2:
				case RiskAssessmentTypes.NIST_AI_RMF:
					await processFileForRiskAssessment({
						name: (inputs?.title as string)|| "",
						reassessOption,
						agentDataCurrent: agentData as AgentData<
							AGENT_TYPES,
							RiskAssessmentTypes
						>,
						sourceFiles: allSourceFiles,
						subSessionType: selectedDropdownOption as (RiskAssessmentTypes.NIST_AI_RMF | RiskAssessmentTypes.NIST_CSF_2),
						navigate,
					});
					break;
				case RiskAssessmentTypes.FAIR:
					await processFileForFairAssessment({
						name: (inputs?.title as string) || "",
						risk_statement: (inputs?.risk_statement as string) || "",
						agentDataCurrent: agentData as AgentData<
							AGENT_TYPES.RISK_ASSESSMENT,
							RiskAssessmentTypes.FAIR
						>,
						sourceFiles: allSourceFiles,
						navigate,
					});
					break;
					default:
						throw new Error("Invalid risk assessment type");
			}
			return;
		}
		case AGENT_TYPES.GAP_ASSESSMENT:
			switch (selectedDropdownOption) {
				case GapAssessmentTypes.DORA:
					await processFileForDoraGapAssessment({
						name: (inputs?.title as string) || "",
						reassessOption,
						agentDataCurrent: agentData as AgentData<
							AGENT_TYPES,
							GapAssessmentTypes
						>,
						sourceFiles: allSourceFiles,
						navigate,
					});
					break;
				case GapAssessmentTypes.SOC2_TYPE1:
					await processFileForSoc2GapAssessment({
						name: (inputs?.title as string) || "",
						customize: isToggleSelected || false,
						sessionType: AgentSessionType.SOC2_GAP_TYPE1,
						reassessOption,
						agentDataCurrent: agentData as AgentData<
							AGENT_TYPES,
							GapAssessmentTypes
						>,
						sourceFiles: allSourceFiles,
						navigate,
					});
					break;
				case GapAssessmentTypes.SOC2:
				case GapAssessmentTypes.SOC2_TYPE2:
					await processFileForSoc2GapAssessment({
						customize: isToggleSelected || false,
						sessionType: AgentSessionType.SOC2_GAP_TYPE2,
						name: (inputs?.title as string) || "",
						reassessOption,
						agentDataCurrent: agentData as AgentData<
							AGENT_TYPES,
							GapAssessmentTypes
						>,
						sourceFiles: allSourceFiles,
						navigate,
					});
					break;
				default:
					throw new Error("Invalid gap assessment type");
			}
			return;
		case AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT:
      if (reassessOption) {
				await processFileForVendorReassessment({
					name: (inputs?.title as string) || "",
					sourceFiles: allSourceFiles,
					navigate,
					reassessOption,
					agentDataCurrent: agentData as AgentData<
						AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
						MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
					>,
				});
			} else {
				await processDataForVendorAssessment({
					name: (inputs?.title as string) || "",
					multi_file_vendor_assessment: {
					vendor_name: (inputs?.vendorName as string) || "",
					scope_of_integration: (inputs?.scope as string) || "",
				},
				customize: (inputs?.customize as boolean) ?? false,
				sourceFiles: allFiles.map((file) => {
					return {
						fileName: file.file.name,
						url: file.signedUrl ?? "",
						fileType: file.file.type,
					};
				}),
				navigate,
			    });
			}
			return;
		default:
			throw new Error(`Invalid agent type : ${type}`);
	}
};


export const containsSpecialCharacters = (str: string) => {
	let specialChars =/[`!@#$%^&*()_\+=\[\]{};':"\\|<>\/?~]/;

	return specialChars.test(str) || str.startsWith("-")
}

export const checkAlphaNumeric = (str: string) => {
  // Check for alpha-numeric characters only
  const titleCharRegex = /^[a-zA-Z0-9]+$/;

  return !titleCharRegex.test(str);
};

export const containsSpecialCharactersForTitle = (str: string) => {
  // Check for characters except alphabets, hyphen(-), underscore(_) and forward slash(/)
  const titleCharRegex = /^[a-zA-Z0-9 _\-\/]+$/;

  return !titleCharRegex.test(str);
};