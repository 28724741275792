import { frameworkTypeLabels } from "@/modules/sessions/constants";
import { Button, Input, Popover, PopoverContent, PopoverTrigger, Radio, RadioGroup, ScrollShadow, Tab, Tabs, Tooltip } from "@nextui-org/react";
import { Check, SearchIcon, SlidersHorizontal } from "lucide-react"; // Icon for search
import { useState } from "react";
import UseAskAiSocketEvents from "../hooks/useAskAiEvents";
import { useChatStore } from "../states";

export default function SessionPopOver() {

  const { selectedSession, setSelectedSession, sessionList, threadId } = useChatStore();
  const { sendSelectedSessions } = UseAskAiSocketEvents();
  const [selectedTab, setSelectedTab] = useState("all");
  const [search, setSearch] = useState("");
  const [isPopoverDisabled, setIsPopoverDisabled] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);


  const items = [
    { title: 'All Sessions' },
    { title: 'All Risk Assessments' },
    { title: 'All Gap Assessments' },
    { title: 'All Third-Party Risk Assessments' },
    { title: 'All Security Quesionaire' },
  ]

  const filteredSessions = sessionList.filter((session) =>
    session.name.toLowerCase().includes(search.toLowerCase()) || frameworkTypeLabels[session.type]?.toLowerCase().includes(search.toLowerCase())
  );

  const onClose = () => {
    if (selectedSession?.id) {
      sendSelectedSessions(threadId, [selectedSession?.id])
      setIsPopoverDisabled(true)
    };
    setIsPopoverOpen(false)
  }

  return (
    <Popover placement="top-start" onClose={() => onClose()} isOpen={isPopoverOpen} >
      <PopoverTrigger>

        <Button
          disabled={selectedSession?.id ? true : false}
          onClick={() => {
            if (isPopoverDisabled && selectedSession?.id) return;
            else setIsPopoverOpen(true);

          }}
          size="sm"
          startContent={
            <SlidersHorizontal size={18} />
          }
          variant="flat"
        >
          <Tooltip delay={500} showArrow={true}
            classNames={{
              content: "bg-black/95 backdrop-blur-md text-white text-tiny p-3",
              arrow: "backdrop-blur-md",
              base: "before:bg-black/95", // This targets the pseudo-element used for the arrow
            }}
            closeDelay={0}
            content={selectedSession?.id ? `Selection disabled — you cannot change session after the session has begun.` : `Select either all or single session to tailor response`}>
            {selectedSession?.name || 'All'}
          </Tooltip>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[600px] py-3">
        <div className="flex flex-col gap-3 w-full">
          {/* Tabs for switching */}
          <Tabs
            className="w-full block"
            aria-label="Session Tabs"
            selectedKey={selectedTab}
            onSelectionChange={(key) => setSelectedTab(key as "all" | "single")}
          >
            <Tab key="all" title="All sessions I can access" />
            <Tab key="single" title="Select individual session" />
          </Tabs>

          {selectedTab === "single" ?
            <div className="flex flex-col gap-3">
              <Input
                autoFocus={true}
                placeholder="Search by session title or type"
                startContent={<SearchIcon size={16} />}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <ScrollShadow className="max-h-64">
                {filteredSessions.map((session, index) => (
                  <div key={index} className="p-2 hover:bg-gray-100 cursor-pointer rounded w-full inline-block" onClick={() => setSelectedSession(session)}>
                    <span>{session.name}</span>
                    <span className="pl-2 text-sm text-gray-500">{frameworkTypeLabels[session.type] || 'N/A'} </span>
                    {
                      selectedSession?.id === session.id ?
                        <Check className="flex float-right mr-4" width={20} />
                        : <></>
                    }
                  </div>
                ))}
              </ScrollShadow>
            </div> : <div className="flex flex-col gap-3 ml-2 mb-2">
              {/* TODO uncomment when BE is ready */}
              {/* <RadioGroup
                label=""
                // defaultValue={selectedReassesOption}
                className="pt-4"
              >
                {items.map((item, index) => {
                  return (
                    <Radio checked={false} key={index} value={item.title}>
                      <div className="radio-container pl-2">
                        <span className="label text-[14px] font-medium text-zinc-900">{item.title}</span>
                      </div>
                    </Radio>
                  )
                })}

              </RadioGroup> */}
            </div>}
        </div>
      </PopoverContent>
    </Popover >
  );
}