import { Card, CardBody, CardHeader } from '@nextui-org/react';
import { MinusIcon } from 'lucide-react';
import { useChatStore } from '../states';
import ChatInput from './chat-input';
import FeaturesCards from './features-cards';
import StaticHeader from './static-header';
import ChatHeader from './chat-header';
import ChatHistory from './ChatHistory';

export default function FloatingAskAi({
  children,
}: {
  children: React.ReactNode;
}) {
  const { isOpen, setIsFloatingAskAiOpen, isShowHistory } = useChatStore();
  return (
    <Card className="fixed bottom-6 right-6 h-[95vh] w-[550px] z-[60]">
      <CardHeader className="flex justify-between">
        <ChatHeader />
        <MinusIcon onClick={() => setIsFloatingAskAiOpen(false)} className='cursor-pointer m-1' />
      </CardHeader>
      <CardBody className="w-full p-0 flex-col-reverse">
        {
          isShowHistory ? <ChatHistory /> :
            <div className='p-3'>
              {isOpen ? children :
                <div className='mt-8'>
                  <div className='mb-6'>
                    <StaticHeader />
                  </div>
                  <FeaturesCards className='mt-4' cardClassName='mb-4' />
                </div>
              }
            </div>
        }
      </CardBody>
      {
        isShowHistory ? null :
          <div className='p-3'>
            <ChatInput />
          </div>
      }

    </Card>

  );
}