// UseAskAiSocketEvents.ts
import { getZaniaSocket } from "@/infra/sockets";
import { addNotification } from "@/shared/states/notification";
import dayjs from "dayjs";
import { useCallback, useEffect, useRef } from "react";
import { Socket } from "socket.io-client";
import { useChatStore } from "../states";
import {
  AiMessageDeltaPayload, AiMessageEndPayload, AiMessageStartPayload,
  ClientToServerEvents, ErrorMessagePayload, ServerToClientEvents, ThreadCreatedPayload
} from "../types";

let isListenerRegistered = false; // module-level flag

export default function UseAskAiSocketEvents() {
  const socket = getZaniaSocket() as Socket<ServerToClientEvents, ClientToServerEvents>;
  const { threadId, setThreadId, setIsGenerating, appendToMessage, uploadedFiles, addAllSourceToMessage, setUploadedFiles, setIsOpen, addMessage, setSelectedSession } = useChatStore();

  const channelRef = useRef<BroadcastChannel | null>(null);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === 'user')
        addMessage({ role: "user", message: event.data.message as string, created_at: dayjs().toString() });
      if (event.data.type === "assistant") {
        addMessage({ role: "assistant", message: "", created_at: dayjs().toString() });
        setUploadedFiles([]);
      }
    };
    channelRef.current = new BroadcastChannel('ask-ai-broadcast');

    if (!isListenerRegistered) {
      channelRef.current.addEventListener("message", handleMessage);
    }

    return () => {
      if (isListenerRegistered) {
        channelRef.current?.removeEventListener("message", handleMessage);
        channelRef.current?.close();
      }
    };
  }, []);

  useEffect(() => {
    // Only register if not already registered
    if (!isListenerRegistered) {
      const handleThreadCreated = (
        data:
          | ThreadCreatedPayload
          | AiMessageStartPayload
          | AiMessageDeltaPayload
          | AiMessageEndPayload
          | ErrorMessagePayload
      ) => {
        switch (data.type) {
          case "thread_created":
            setThreadId(data.thread_created.thread_id);
            break;
          case "ai_message_start":
            setIsGenerating(true);
            setIsOpen(true);
            appendToMessage(data.ai_message_start.message);
            break;
          case "ai_message_delta": {
            let temp = data.ai_message_delta.message;
            temp = temp.replace(/([.,!?]+)(\s*)?(\[[^\]]*\])/g, "$3$2$1");

            appendToMessage(temp);
            if (data.ai_message_delta.inline_sources?.length) {
              appendToMessage(JSON.stringify(data.ai_message_delta.inline_sources));
            }
            break;
          }
          case "ai_message_end":
            if (data.ai_message_end.all_sources) {
              addAllSourceToMessage(data.ai_message_end.all_sources);
            }
            setTimeout(() => {
              const conversation = document.querySelector("#conversation");
              conversation?.scrollTo({
                top: conversation.scrollHeight,
                behavior: "smooth"
              });
            }, 100);
            setIsGenerating(false);
            break;
          case "error_message":
            if (data.error_message.error_module === 'SESSION_SELECTION') {
              setSelectedSession({});
              addNotification({ title: 'Invalid Session', message: `Assessment results are not available for the selected session, which may affect responsees to your query. Scope has been reset to "All Sessions"`, type: "error" });
              return;
            }
            addMessage({ role: "assistant", message: data.error_message.message, type: "error", created_at: dayjs().toString() });
            setTimeout(() => {
              const conversation = document.querySelector("#conversation");
              conversation?.scrollTo({
                top: conversation.scrollHeight,
                behavior: "smooth"
              });
            }, 100);
            setIsGenerating(false);
            break;
          default:
            console.log("Unhandled event:", data);
        }
      };

      socket.on("ask_ai", handleThreadCreated);
      isListenerRegistered = true;

      return () => {
        socket.off("ask_ai", handleThreadCreated);
        isListenerRegistered = false;
      };
    }
  }, [socket, setThreadId, setIsGenerating, appendToMessage, setIsOpen, addMessage, setSelectedSession]);

  // Helper functions to emit events (unchanged) ...
  const createNewThread = useCallback((): void => {
    socket.emit("ask_ai", { type: "new_thread", new_thread: null }, (resp: any) => {
      if (resp?.error) {
        console.error("Error creating new thread:", resp.error);
        return;
      }
      setIsOpen(false);
    });
  }, [socket]);

  const uploadUserFile = useCallback((tId: string, fileUrls: string[]): void => {
    socket.emit("ask_ai", {
      type: "user_file_upload",
      thread_id: tId,
      user_file_upload: { file_urls: fileUrls },
    });
  }, [socket, threadId]);

  const sendSelectedSessions = useCallback((tId: string, sessionIds: "all" | string[]): void => {
    socket.emit("ask_ai", {
      type: "selected_sessions",
      thread_id: tId,
      selected_sessions: { session_ids: sessionIds },
    }, (resp: any) => {
      if (resp?.error) {
        console.error("Error selecting session:", resp.error);
        setSelectedSession({});
        return;
      }
    });
  }, [socket]);

  const sendUserMessage = useCallback((tId: string, message: string): void => {
    setIsOpen(true);
    setIsGenerating(true);
    let appendMsg = message;
    if (uploadedFiles?.length) {
      appendMsg += `   
      Attachments: ${uploadedFiles.map((file) => file?.file?.name).join(', ')}`
    }
    channelRef.current?.postMessage({ type: 'user', message: appendMsg });
    socket.emit("ask_ai", {
      type: "user_message",
      thread_id: tId,
      user_message: { message }
    }, (resp: any) => {
      if (resp?.error) {
        console.error("Error sending message", resp.error);
        return;
      }
    });
    channelRef.current?.postMessage({ type: 'assistant' });
  }, [socket, uploadedFiles]);

  return {
    socket,
    createNewThread,
    uploadUserFile,
    sendSelectedSessions,
    sendUserMessage,
  };
}
