import { Spinner } from "@nextui-org/react";
import dayjs from "dayjs";
import { Trash } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { getChatHistoryMessages, getHistoryList } from "../requests";
import { useChatStore } from "../states";
import { ChatHistoryInterface } from "../types";
import DeleteConfirmModal from "./DeleteConfirmModal";

export default function ChatHistory() {

  const { historyList, sessionList, setHistoryList, setMessages, setThreadId, setIsOpen, setIsShowHistory, setSelectedSession } = useChatStore();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<number | null>(null);
  const [isHistoryLoading, setIsHistoryLoading] = useState<boolean>(false);
  const [selectedChat, setSelectedChat] = useState({});
  const historyListFn = useCallback(async () => {
    setIsHistoryLoading(true);
    const [error, resp] = await getHistoryList();
    if (resp?.data) {
      setHistoryList(resp.data.filter((item) => item.title));
      setIsHistoryLoading(false);
      return resp.data;
    }
    if (error) {
      console.error('Error fetching sessions:', error);
      setIsHistoryLoading(false);
      return;
    }
    return resp;
  }, []);

  function replaceSubstring(
    str: string,
    startIndex: number,
    endIndex: number,
    replacement: string
  ): string {
    return str.slice(0, startIndex) + replacement + str.slice(endIndex);
  }

  const getMessageByThreadId = useCallback(async (index: number, threadId: string, sessionId: string[] | null) => {
    setIsLoading(index);
    const [error, resp] = await getChatHistoryMessages(threadId);
    if (resp?.data) {
      const temp = [...resp.data];
      temp.forEach((item) => {
        if (item.inline_sources && item.inline_sources.length > 0) {
          // replace the message string with the inline sources as string based on inline source start and end index
          item.inline_sources.reverse().forEach((source) => {
            const sourceString = JSON.stringify(source);
            // replace in message based on source start and end index
            item.message = replaceSubstring(item.message, source.start_index, source.end_index, `[${sourceString}]`);
          });
        }
        return item;
      });
      setMessages(temp);
      setThreadId(threadId);
      setIsOpen(true);
      setIsShowHistory(false);
      setIsLoading(null);
      if (sessionId)
        setSelectedSession(sessionList.find((session) => session.id === sessionId[0])!);
      else
        setSelectedSession({});
      return resp.data;
    }
    if (error) {
      console.error('Error fetching sessions:', error);
      setIsLoading(null);
      return;
    }
    return resp;
  }, []);

  useEffect(() => {
    const fetchHistoryList = async () => {
      try {
        await historyListFn();
      } catch (error) {
        console.error('Error fetching sessions:', error);
        return;
      }
    };
    void fetchHistoryList();
  }, [])
  return (
    <div className="m-1 mt-4 h-full overflow-auto">
      {historyList.length > 0 && historyList.map((item, index) => (
        <div key={index}
          onClick={() => void getMessageByThreadId(index, item.thread_id, item.session_id)}
          className="flex items-center justify-between bg-white 
        hover:bg-gray-200 transition-colors p-3 rounded group cursor-pointer">
          <div className="flex items-center gap-2 align-baseline">
            <p className="text-sm font-normal">{item.title}
            </p>
            <span className="ml-2 text-xs text-[#A1A1AA]"> {dayjs(item.created_at).format("D MMM")}</span>
            {isLoading === index && <Spinner size="sm" className="ml-2" />}
          </div>
          {/* TODO add this class when BE is done---- group-hover:block */}
          <div className="hidden ">
            <Trash size={16}
              className="cursor-pointer"
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                setShowDeleteModal(true);
                setSelectedChat(item)
              }} />
          </div>
        </div>
      ))}

      {isHistoryLoading && !historyList.length &&
        <div className="flex justify-center items-center h-full font-light text-[#A1A1AA]">
          <Spinner size="md" />
        </div>
      }
      {!isHistoryLoading && !historyList.length ?
        <div className="flex justify-center items-center h-full font-light text-[#A1A1AA]">
          No chats to show
        </div> :
        null
      }


      <DeleteConfirmModal isOpen={showDeleteModal}
        toggleModal={() => setShowDeleteModal(!showDeleteModal)}
        selectedChat={selectedChat as ChatHistoryInterface} />
    </div>
  )
}