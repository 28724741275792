import { cn } from "@/shared/utils/classname-merger";
import { Textarea } from "@nextui-org/react";
import { ReviewSourceTypes } from "../../types";
import { TestResultsValue } from "../../types/risk-and-gap";
import InlineSources from "./InlineSources";

interface Props {
  values: TestResultsValue[];
  handleChanges: (newValues: TestResultsValue[]) => void;
  onSourceClick: (selectedSource: ReviewSourceTypes) => void;
  setListOfSourcesForSelectedSources: (
    selectedSource: ReviewSourceTypes[]
  ) => void;

  expandedView?: boolean;
}

const TestResults = ({
  handleChanges,
  values,
  expandedView,
  onSourceClick,
  setListOfSourcesForSelectedSources,
}: Props) => {
  const handleChange = (
    key: keyof TestResultsValue,
    value: ReviewSourceTypes[] | string,
    idx: number
  ) => {
    const newValue = [...values];

    if (key === "result") {
      newValue[idx].result = value as string;
    }
    if (key === "test") {
      newValue[idx].test = value as string;
    }
    if (key === "sources") {
      newValue[idx].sources = value as ReviewSourceTypes[];
    }

    handleChanges(newValue);
  };

  return (
    <div
      className={cn(
        "bg-white rounded-md border-1 border-[#E4E4E7]  w-full",
        expandedView && "flex flex-wrap w-full "
      )}
    >
      {values.map((value, idx) => (
        <div
          key={idx}
          className={cn(
            "flex-1",
            expandedView && "border-r-1 border-[#E4E4E7]"
          )}
        >
          <div
            className="bg-[#F9F7F7] text-[#717171] font-medium py-2 px-4 text-[10px] leading-4"
            style={{
              border: "1px solid #E4E4E7",
              borderLeft: "0px",
              borderRight: "0px",
            }}
          >
            TEST {idx + 1}
          </div>
          <div
            className={cn("relative  w-full", expandedView && "flex flex-row ")}
          >
            <div
              className={cn(
                value?.sources?.length ? "pb-4 mb-1" : "",
                expandedView && "flex flex-col grow pb-2"
              )}
            >
              <div className={cn("items-center pl-4 pt-4")}>
                <div className="text-sm font-medium  self-start text-[#333333]">
                  Test:
                </div>
                <Textarea
                  className="text-[#121212] !border-0 font-light pt-0 self-start bg-white focus-within:text-[#000000] "
                  classNames={{
                    base:
                      "shadow-none border-none !border-0 rounded-none truncate  pt-0",
                    inputWrapper: `items-start rounded-md ps-0 !border-0 ${
                      false ? "border-none px-4" : ""
                    }  ${false ? "text-[#000000]" : "text-[#333333]"} pt-0`,
                    input: "!text-[#333333] !border-0 pt-0",
                  }}
                  maxRows={9999999}
                  minRows={1}
                  variant="bordered"
                  labelPlacement="outside"
                  value={value.test}
                  placeholder={"Add Text"}
                  onChange={(e) => {
                    handleChange("test", e.target.value, idx);
                  }}
                  onMouseDown={(e) => {
                    const textarea = e.target as HTMLTextAreaElement;
                    const clickPosition = textarea.selectionStart;
                    textarea.focus({
                      preventScroll: true
                    });
                    textarea.setSelectionRange(clickPosition, clickPosition);
                  }}
                />
              </div>
              <div
                className={cn(
                  "items-center p-4 pt-0.5",
                  expandedView && "p-0 pl-4 pb-4"
                )}
              >
                <div className="flex-shrink-0  text-sm font-medium  self-start text-[#333333]">
                  Result:
                </div>
                <Textarea
                  className="text-[#121212] !border-0 font-light pt-0 self-start bg-white focus-within:text-[#000000]"
                  classNames={{
                    base:
                      "shadow-none border-none !border-0 rounded-none truncate pt-0",
                    inputWrapper: `items-start rounded-md ps-0 !border-0 ${
                      false ? "border-none px-4" : ""
                    }  ${false ? "text-[#000000]" : "text-[#333333]"} pt-0`,
                    input: "!text-[#333333] !border-0 pt-0",
                  }}
                  maxRows={9999999}
                  minRows={1}
                  variant="bordered"
                  labelPlacement="outside"
                  value={value.result}
                  placeholder={"Add Text"}
                  onChange={(e) => {
                    handleChange("result", e.target.value, idx);
                  }}
                  onMouseDown={(e) => {
                    const textarea = e.target as HTMLTextAreaElement;
                    const clickPosition = textarea.selectionStart;
                    textarea.focus({
                      preventScroll: true
                    });
                    textarea.setSelectionRange(clickPosition, clickPosition);
                  }}
                />
              </div>
              <div className={`pl-4 ${value.sources?.length && "pb-2"}`}>
                <InlineSources
                  sources={value.sources}
                  onSourceClick={onSourceClick}
                  setListOfSourcesForSelectedSources={
                    setListOfSourcesForSelectedSources
                  }
                  handleSourceRemoveAtIndex={(index) => {
                    const newSources = [...value.sources];

                    newSources.splice(index, 1);

                    handleChange("sources", newSources, idx);
                  }}
                  tooltipPlacement={expandedView ? "bottom" : "bottom"}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TestResults;
