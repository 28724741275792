import { ReviewResponseType } from "../types/index.ts";
import { ResponseSchemaVersion } from "../types/risk-and-gap.ts";

export const getRenderType = (key: string, version?: ResponseSchemaVersion, value?: string,  type?: string) => {
 
  if (version === "v2") {
    if (["gaps", "recommendations", "improvement_opportunities", "improvement_recommendations"].includes(key)) {
      return ReviewResponseType.LIST_OF_TEXT_WITH_SOURCE;
    }

    if (["observations"].includes(key.toLowerCase())) {
      return ReviewResponseType.TEXT_WITH_SOURCE;
    }
  }
  else if(version === "v1" && type === "fair"){
    if(key === "actual_risks" || key === "justification" || key === "recommendations"){
      return ReviewResponseType.TEXT_WITH_SOURCE;
    }
    // FAIR Assessment Controls
   if(key === "control_id") {
      switch(value) {
        case "overall risk":
          return ReviewResponseType.OVERALL_RISK;
        case "likelihood":
          return ReviewResponseType.LIKELIHOOD;
        case "threat event frequency":
          return ReviewResponseType.THREAT_EVENT_FREQUENCY;
        case "vulnerability":
          return ReviewResponseType.VULNERABILITY;
        case "threat actor capability":
          return ReviewResponseType.THREAT_ACTOR_CAPABILITY;
        case "control strength":
          return ReviewResponseType.CONTROL_STRENGTH;
        case "preventive controls":
          return ReviewResponseType.PREVENTIVE_CONTROLS;
        case "detective controls":
          return ReviewResponseType.DETECTIVE_CONTROLS;
        case "corrective controls":
          return ReviewResponseType.CORRECTIVE_CONTROLS; 
        case "impact":
          return ReviewResponseType.IMPACT;
        default:
          return ReviewResponseType.TEXT;
      }
    }
  }

  if (["improvement_opportunities"].includes(key)) {
    return ReviewResponseType.LIST_OF_TEXT_WITH_SOURCE;
  }

  if (["risk_value"].includes(key)) {
    return ReviewResponseType.NUMBER;
  }

  if (["justification"].includes(key)) {
    return ReviewResponseType.TEXT_DISPLAY;
  }

  if (["compliance_status", "compliant"].includes(key)) {
    return ReviewResponseType.COMPLIANCE_STATUS;
  }

  if (["sources"].includes(key)) {
    return ReviewResponseType.SOURCE;
  }

  if (["test_results"].includes(key)) {
    return ReviewResponseType.TEST_RESULTS;
  }

  return ReviewResponseType.TEXT;
};
