import {
  getCustomizeControlsActions,
  useCustomizeControlsStore,
} from "@/modules/agent/states/cusomize-controls";
import { ZInput } from "@/shared/components/input";
import { cn } from "@/shared/utils/classname-merger";
import { InputProps } from "@nextui-org/react";
import { useEffect, useRef, useState } from "react";

const CustomizeControlSearchBar = () => {
  const selectedControls = useCustomizeControlsStore().selectedControls;
  const searchQuery = useCustomizeControlsStore().searchQuery;
  return (
    <>
      {selectedControls.size === 0 && (
        <ZInputWithOnChangeDelay
          classNames={{
            inputWrapper: "rounded-md h-10 min-h-9",
            mainWrapper: "max-h-10 ",
          }}
          className="grow max-w-96 min-w-[480px]  h-10"
          placeholder="Enter keywords to search"
          value={searchQuery}
          onChange={(e) => {
            getCustomizeControlsActions().setSearchQuery(e.target.value);
          }}
        />
      )}
    </>
  );
};

export default CustomizeControlSearchBar;

const ZInputWithOnChangeDelay = (
  props: InputProps & {
    inputValidation?: (newValue: string) => boolean;
  }
) => {
  const [value, setValue] = useState(props.value ?? "");
  const [isActive, setIsActive] = useState(false);

  const { isInvalid } = props;

  useEffect(() => {
    if (props.value !== undefined) setValue(props.value);
  }, [props.value]);

  const timerref = useRef<NodeJS.Timeout | null>(null);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value.trimStart() !== value) {
      if (
        props.inputValidation === undefined ||
        props.inputValidation(e.target.value)
      ) {
        setValue(e.target.value);
        if (timerref.current) {
          clearTimeout(timerref.current);
        }
        timerref.current = setTimeout(() => {
          props.onChange?.(e);
        }, 500);
      }
    }
  };

  return (
    <ZInput
      {...props}
      className={cn(
        props.className ?? "",
        "placeholder:text-zinc-400",
        isActive &&
          "bg-white data-[hover=true]:bg-white rounded-[4px] border-1 border-[#A5A5A5]",
        isInvalid && "border-1 border-[#F31260]"
      )}
      startContent={
        props.startContent ? (
          <div className={cn("text-zinc-400", value && "text-zinc-800")}>
            {props.startContent}
          </div>
        ) : undefined
      }
      value={value}
      onChange={handleChange}
      onFocus={(e) => {
        setIsActive(true);
        props.onFocus?.(e);
      }}
      onBlur={(e) => {
        setIsActive(false);
        props.onBlur?.(e);
      }}
      autoFocus={false}
      onMouseDown={(e) => {
        const textarea = e.target as HTMLTextAreaElement;
        const clickPosition = textarea.selectionStart;
        textarea.focus();
        textarea.setSelectionRange(clickPosition, clickPosition);
      }}
    />
  );
};
