export const getNumbering = (typeOrLevel: string | number, index: number): string => {
    // Just return the exact prefix for each field
    if (typeOrLevel === 'Likelihood') return '1.';
    if (typeOrLevel === 'Threat Event Frequency') return 'a.';
    if (typeOrLevel === 'Vulnerability') return 'b.';
    if (typeOrLevel === 'Threat Actor Capability') return 'i.';
    if (typeOrLevel === 'Control Strength') return 'ii.';
    if (typeOrLevel === 'Preventive Controls') return '1.';
    if (typeOrLevel === 'Detective Controls') return '2.';
    if (typeOrLevel === 'Corrective Controls') return '3.';
    if (typeOrLevel === 'Impact') return '2.';
    return '';
  };
  
  export const getFairItemStyle = (level: number): string => {
    switch (level) {
      case 0: // Overall Risk, Actual Risk, Recommendations
        return "pl-3";
      case 1: // 1. Likelihood, 2. Impact
        return "pl-6";
      case 2: // a. Threat Event Frequency, b. Vulnerability
        return "pl-12";
      case 3: // i. Threat Actor Capability, ii. Control Strength
        return "pl-20";
      case 4: // 1. Preventive Controls, 2. Detective Controls
        return "pl-28";
      default:
        return "pl-4";
    }
  };

  export const getRatingStyle = (rating: string) => {
    const styles = {
      'Very Low': 'bg-green-100 text-green-800',
      'Low': 'bg-[#D1F4E0] text-[#45D483]',
      'Moderate': 'bg-[#FDEDD3] text-[#F5A524]',
      'High': 'bg-[#FDD0DF] text-[#F31260]',
      'Very High': 'bg-red-200 text-red-900',
    };
    return styles[rating as keyof typeof styles] || '';
  };