import {
  AgentSessionStatus,
  AgentSessionStepType,
} from "@/modules/sessions/types";
import { ZButton } from "@/shared/components/button";
import {
  Avatar,
  AvatarGroup,
  Tooltip,
  TooltipPlacement,
} from "@nextui-org/react";
import { useParams } from "react-router-dom";
import { sourcePreviewSupportedFileTypes } from "../../constants/sourcePreviewSupportedFileTypes";
import { useAgentStepData } from "../../states";
import { PDFReviewSource, ReviewSourceTypes } from "../../types/index.ts";
import { IdParams } from "../layout/header";

type Props = {
  sources: ReviewSourceTypes[];
  onSourceClick: (selectedSource: ReviewSourceTypes) => void;
  setListOfSourcesForSelectedSources: (
    selectedSource: ReviewSourceTypes[]
  ) => void;
  handleSourceRemoveAtIndex?: (index: number) => void;
  tooltipPlacement?: TooltipPlacement;
  position?: string;
};

const InlineSources = ({
  sources,
  onSourceClick,
  handleSourceRemoveAtIndex,
  tooltipPlacement = "top-end",
  setListOfSourcesForSelectedSources,
  position = "absolute bottom-3"
}: Props) => {
  const { id } = useParams<keyof IdParams>() as IdParams;
  const stepData = useAgentStepData(id);
  const isReportReady =
    stepData?.find((step) => step.type === AgentSessionStepType.PREPARE_REPORT)
      ?.status === AgentSessionStatus.COMPLETE;

  return (
    <AvatarGroup
      isBordered
      max={50}
      classNames={{
        base: `${position} cursor-pointer`,
        count: `
          flex relative justify-center items-center box-border overflow-hidden align-middle z-0 outline-none
          data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus
          data-[focus-visible=true]:outline-offset-2 text-primary-foreground rounded-full ring-0 bg-[#006FEE] bg-opacity-20
          text-primary ring-offset-2 ring-offset-background dark:ring-offset-background-dark -ms-0
          data-[hover=true]:-translate-x-1 rtl:data-[hover=true]:translate-x-1
          transition-transform data-[focus-visible=true]:-translate-x-1 rtl:data-[focus-visible=true]:translate-x-1
          ring-primary w-4 h-4 text-tiny
        `,
      }}
      color="primary"
    >
      {(sources ?? []).map((source, index) => (
        <Tooltip
          showArrow={true}
          placement={tooltipPlacement}
          shouldFlip={true}
          key={`${index}_${source.source_number}`}
          isDismissable={true}
          closeDelay={100}
          classNames={{
            content: "bg-black/95 backdrop-blur-md text-white text-tiny p-3",
            arrow: "backdrop-blur-md",
            base: "before:bg-black/95", // This targets the pseudo-element used for the arrow
          }}
          content={
            <div className="flex flex-col gap-y-[0.5rem] max-w-96">
              <div className="max-h-[5rem] overflow-hidden">
                <p className="line-clamp-4 text-white">
                  {source.source_text ? source.source_text : null}
                </p>
              </div>
              <div className="flex gap-3">
                <p className="text-tiny text-[#A5A5A5] break-all">
                  {source.original_file_name ||
                    source.source_file_name ||
                    source.file_name}
                </p>
                {((source as PDFReviewSource).source_page_number ||
                  (source as PDFReviewSource).page_number) && (
                  <>
                    <span className="text-tiny text-[#A5A5A5]">•</span>
                    <p className="text-tiny text-[#A5A5A5]">
                      Page{" "}
                      {(source as PDFReviewSource).source_page_number ||
                        (source as PDFReviewSource).page_number}
                    </p>
                  </>
                )}
              </div>
              <div className="self-end flex gap-2">
                {!!handleSourceRemoveAtIndex && !isReportReady && (
                  <ZButton
                    size="sm"
                    type="button"
                    color="danger"
                    onClick={() => handleSourceRemoveAtIndex(index)}
                  >
                    Remove
                  </ZButton>
                )}
                <ZButton
                  size="sm"
                  type="button"
                  onClick={() => {
                    onSourceClick(source);
                    setListOfSourcesForSelectedSources(sources);
                  }}
                >
                  {sourcePreviewSupportedFileTypes.includes(source.file_type)
                    ? "View Reference"
                    : "Download"}
                </ZButton>
              </div>
            </div>
          }
        >
          <Avatar
            key={`${index}_${source.source_number}`}
            classNames={{
              icon: "[&>span]:text-primary",
              base: `
                flex relative justify-center items-center box-border overflow-hidden align-middle z-0 outline-none
                data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus
                data-[focus-visible=true]:outline-offset-2 text-primary-foreground rounded-full ring-0 bg-[#006FEE]
                bg-opacity-20 text-primary ring-offset-2 ring-offset-background dark:ring-offset-background-dark -ms-0
                data-[hover=true]:-translate-x-1 rtl:data-[hover=true]:translate-x-1 transition-transform
                data-[focus-visible=true]:-translate-x-1 rtl:data-[focus-visible=true]:translate-x-1 ring-primary
                w-4 h-4 text-[10px]
              `,
            }}
            icon={<span>{source.source_number}</span>}
            onClick={(e) => {
              e.stopPropagation();
              onSourceClick(source);
              setListOfSourcesForSelectedSources(sources);
            }}
          >
            {source.source_number}
          </Avatar>
        </Tooltip>
      ))}
    </AvatarGroup>
  );
};

export default InlineSources;
