import { del, get, post, put } from "@/infra/rest";
import { setLoginStep } from "@/modules/auth/states/login";
import { ErrorResponse, GenerateMagicLinkResponse, InitialAuthenticationAndOrganizationList, LoggedInMember, LoggedInMemberWithOrg, LOGIN_STEP, OrganiaztionListResponse } from "@/modules/auth/types";
import { AiResponseType } from "@/shared/types/user";
import { getCookie } from "@/shared/utils/cookie-helper";
// v0/auth/magic-link/create
export const generateMagicLinks = async (email: string, redirectUrl: string) => {
  try {
    const url = `/v0/auth/magic-link`

    const resp = await post<{ user_email: string, redirect_url: string }, GenerateMagicLinkResponse>({
      url,
      isAuthRequired: false,
      data: {
        user_email: email,
        redirect_url: redirectUrl
      }
    });
    setLoginStep(LOGIN_STEP.EMAIL_SUCCESS);

    return resp;
  } catch (err) {
    throw new Error("Failed to send the Magic Link.");
  }
};

// "/v0/auth/orgs"
export const getInitialOrganizationListFromAuth = async (token_type: string, token: string) => {
  try {
    const resp = await get<InitialAuthenticationAndOrganizationList>({
      url: `/v0/auth/orgs?token_type=${token_type}&token=${token}`,
      isAuthRequired: false,
    });
    return resp;
  } catch (err) {
    throw new Error("Something went wrong fetching organization list");
  }
};

// /v0/auth/discovery/orgs
export const getOrganizationList = async () => {
  try {
    const resp = await get<OrganiaztionListResponse>({
      url: `/v0/auth/discovery/orgs`,
      isAuthRequired: true,
      useCookies: true
    });

    return resp;
  } catch (err) {
    throw new Error("Something went wrong fetching organization list");
  }
};


// "/v0/auth/jwt-token"
export const generateJWTTokenFromInitialAuth = async (org_id: string, intermediate_session_token?: string) => {

  const intermediateSessionToken = getCookie("intermediate_session_token");
  try {
    const resp = await post<{ org_id: string, intermediate_session_token?: string }, undefined>({
      data: {
        org_id: org_id,
        intermediate_session_token: intermediate_session_token || intermediateSessionToken
      },
      url: `v0/auth/jwt-token`,
      isAuthRequired: true,
      useCookies: true
    });
    return resp;
  } catch (err) {
    throw new Error("Something went wrong!");
  }
};

// "/v0/auth/refresh-token"
export const refreshAuthToken = async () => {
  try {
    await post<undefined, undefined>({
      url: "/v0/auth/refresh-token",
      data: undefined,
      isAuthRequired: true,
      useCookies: true
    });
  } catch (err) {
    throw new Error("Something went wrong!");
  }
};

// "/v0/auth/sso/orgs"
export const getListOfSsoOrganization = async (user_email: string) => {

};

export const getTeam = async () => {
  try {
    const resp = await get<{ team_members: LoggedInMember[] }>({
      url: "/v0/auth/stytch-members",
      useCookies: true
    });
    return resp;
  } catch (err) {
    throw new Error("Something went wrong!");  }
}


// Logout
export const revokeToken = async () => {
  try {
    const resp = await del<any>({
      url: "/v0/auth/revoke-token",
      useCookies: true
    });
    return resp;
  }
  catch (err) {
    throw new Error("Something went wrong!");  }
}


// Get member details with organization
export const getMemberDetailsWithOrganization = async () => {
  try {
    const resp = await get<LoggedInMemberWithOrg | null>({
      url: "/v0/auth/stytch-member-details",
      useCookies: true
    });
    return resp;
  }
  catch (err) {
    throw new Error("Something went wrong!");  }
}

export const switchOrganization = async (org_id: string) => {
  try {
    const resp = await post<null, undefined>({
      data: null,
      url: `v0/auth/switch-org/${org_id}`,
      isAuthRequired: true,
      useCookies: true
    });
    return resp;
  } catch (err) {
    throw new Error("Something went wrong!");
  }
};

export const authenticateInviteMagicLink = async (token: string) => {
  try {
    const data = await post<unknown, { magic_link_token: string }>({
      url: `/v0/auth/jwt-token`,
      data: {
        magic_link_token: token,
      },
      isAuthRequired: false,
    });

    return data;
  } catch (err) {
    throw new Error('Failed to authenticate');
  }
}

// get response mode settings
export const getSettingsApi = async () => {
  try {
    const resp = await get<{ data: {ui_response_quality: AiResponseType, user_response_quality: []} }>({
      url: "v0/user/settings",
      useCookies: true
    });
    return resp;
  } catch (err) {
    throw new Error("Something went wrong!");
  }
}

// get response mode settings
export const putSettingsApi = async (value: AiResponseType) => {
  try {
    const resp = await put<{ui_response_quality: AiResponseType}, { data: {ui_response_quality: AiResponseType}, success: boolean }>({
      url: "v0/user/settings",
      useCookies: true,
      data: {ui_response_quality: value}
    });
    return resp;
  } catch (err) {
    throw new Error("Something went wrong!");
  }
}

