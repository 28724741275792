import {
  Control,
  isSoc2ControlValid,
  isVendorAssessmentControlValid,
  Soc2Control,
  TSCABBRIVATIONS,
  VendorAssessmentControl,
} from "@/modules/agent/states/cusomize-controls";
import { AgentSessionType } from "@/modules/sessions/types";
import { isInValidIdForSoc2 } from "../../helpers";

export const getValidControlNumber = ({
  agentType,
  controls,
  selectedTab,
}: {
  agentType: AgentSessionType | null;
  controls: Control[];
  selectedTab: string | null;
}) => {
  if (
    agentType === AgentSessionType.SOC2_GAP ||
    agentType === AgentSessionType.SOC2_GAP_TYPE1 ||
    agentType === AgentSessionType.SOC2_GAP_TYPE2
  ) {
    return (controls as Soc2Control[])
      .filter((c) => isSoc2ControlValid(c) && c.tsc === selectedTab)
      .filter((c) => c.tsc_id.replaceAll(TSCABBRIVATIONS[c.tsc], "").length > 0)
      .filter(
        (c) =>
          !isInValidIdForSoc2(c.tsc_id.replaceAll(TSCABBRIVATIONS[c.tsc], ""))
      );
  }
  if (agentType === AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT) {
    return (controls as VendorAssessmentControl[]).filter(
      (c) => isVendorAssessmentControlValid(c) && c.section === selectedTab
    );
  }
  return controls;
};

export const filterListOfControlsBasedOnTabsAndAgentType = ({
  agentType,
  control,
  selectedTab,
}: {
  agentType: AgentSessionType | null;
  control: Control;
  selectedTab: string | null;
}) => {
  switch (agentType) {
    case AgentSessionType.SOC2_GAP_TYPE1:
    case AgentSessionType.SOC2_GAP:
    case AgentSessionType.SOC2_GAP_TYPE2:
      return (control as Soc2Control).tsc === selectedTab;

    case AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT:
      return (control as VendorAssessmentControl).section === selectedTab;

    default:
      false;
  }
};
