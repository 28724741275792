/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AgentSessionStepType } from "@/modules/sessions/types";
import { ZTableColumnProps } from "@/shared/components/table";
import { ISupplyChain } from "../components/VendorRiskAssessment/SupplyChain/SupplyChain";
import { SupplyChainKebabMenu } from "../components/VendorRiskAssessment/SupplyChain/SupplyChainKebabMenu";
import {
  AgentSubTypes,
  ConfidenceTypes,
  DoraGapReviewResponseTableRow,
  GapAssessmentTypes,
  MultiVendorAssessmentTableRow,
  MultiVendorAssessmentTypes,
  QuestionnaireTableRow,
  QuestionnaireTypes,
  RiskAssessmentTypes,
  RiskReviewResponseTableRow,
  Soc2GapReviewResponseTableRow,
} from "../types/index.ts";
import { MappingType } from "../types/questionnaire.ts";
import { RiskValue } from "../types/risk-and-gap.ts";
type Helper = {
  [K in AgentSubTypes]: {
    title: string;
    rowName: string;
    process: Partial<Record<AgentSessionStepType, { breadcrumb: string; title?: string; subtitle?: string }>>;
  };
};

export const helper: Helper = {
  [QuestionnaireTypes.QA]: {
    title: "Auto-fill Security Questionnaire",
    rowName: "question",
    process: {
      [AgentSessionStepType.QA_EXCEL_STRUCTURE_MAPPING]: {
        breadcrumb: "Data Mapping",
        title: "AI is preparing your file for data mapping",
        subtitle: "This process might take up to 10 minutes. Thank you for your patience!",
      },
      [AgentSessionStepType.MANUAL_EXCEL_STRUCTURE_MAPPING]: {
        breadcrumb: "Data Mapping",
      },
      [AgentSessionStepType.QA_FILLER]: {
        breadcrumb: "Review Responses",
        title: "AI is preparing responses for questions extracted",
        subtitle: "This process might take up to 10 minutes. Thank you for your patience! ",
      },
      [AgentSessionStepType.EDIT_RESPONSE]: {
        breadcrumb: "Review Responses",
      },
      [AgentSessionStepType.PREPARE_REPORT]: {
        breadcrumb: "Responses",
      },
      [AgentSessionStepType.CONTROL_SELECTION]: {
        breadcrumb: "Customise Assessment",
      },
    },
  },
  [RiskAssessmentTypes.NIST_CSF_2]: {
    title: "NIST CSF 2.0 Assessment",
    rowName: "control",
    process: {
      [AgentSessionStepType.GENERATE_RISK_ASSESSMENT_RESPONSE]: {
        breadcrumb: "Review Responses",
        title: "AI is preparing your risk assessment document",
        subtitle: "This process might take up to 10 minutes. Thank you for your patience! ",
      },
      [AgentSessionStepType.EDIT_RESPONSE]: {
        breadcrumb: "Review Responses",
      },
      [AgentSessionStepType.PREPARE_REPORT]: {
        breadcrumb: "Responses",
      },
      [AgentSessionStepType.CONTROL_SELECTION]: {
        breadcrumb: "Customise Assessment",
      },
    },
  },
  [RiskAssessmentTypes.NIST_AI_RMF]: {
    title: "NIST AI RMF Risk Assessment",
    rowName: "control",
    process: {
      [AgentSessionStepType.NIST_AI_RMF]: {
        breadcrumb: "Review Responses",
        title: "AI is preparing your risk assessment document",
        subtitle: "This process might take up to 10 minutes. Thank you for your patience! ",
      },
      [AgentSessionStepType.EDIT_RESPONSE]: {
        breadcrumb: "Review Responses",
      },
      [AgentSessionStepType.PREPARE_REPORT]: {
        breadcrumb: "Responses",
      },
      [AgentSessionStepType.CONTROL_SELECTION]: {
        breadcrumb: "Customise Assessment",
      },
    },
  },
  [RiskAssessmentTypes.FAIR]: {
    title: "Fair Risk Assessment",
    rowName: "control",
    process: {
      [AgentSessionStepType.GENERATE_FAIR_ASSESSMENT_RESPONSE]: {
        breadcrumb: "Review Responses",
        title: "AI is preparing your risk assessment report",
        subtitle: "This process might take up to 10 minutes. Thank you for your patience! ",
      },
      [AgentSessionStepType.EDIT_RESPONSE]: {
        breadcrumb: "Review Responses",
      },
      [AgentSessionStepType.PREPARE_REPORT]: {
        breadcrumb: "Responses",
      },
    }
  },
  [GapAssessmentTypes.SOC2]: {
    title: "SOC 2 Type II Gap Assessment",
    rowName: "control",
    process: {
      [AgentSessionStepType.SOC2_TYPE2_AUDIT]: {
        breadcrumb: "Review Responses",
        title: "AI is preparing your Gap Assessment",
        subtitle: "This process might take up to 10 minutes. Thank you for your patience!",
      },
      [AgentSessionStepType.EDIT_RESPONSE]: {
        breadcrumb: "Review Responses",
      },
      [AgentSessionStepType.PREPARE_REPORT]: {
        breadcrumb: "Responses",
      },
      [AgentSessionStepType.CONTROL_SELECTION]: {
        breadcrumb: "Customise Assessment",
      },
    },
  },
  [GapAssessmentTypes.SOC2_TYPE1]: {
    title: "SOC 2 Type I Gap Assessment",
    rowName: "control",
    process: {
      [AgentSessionStepType.SOC2_TYPE2_AUDIT]: {
        breadcrumb: "Review Responses",
        title: "AI is preparing your Gap Assessment",
        subtitle: "This process might take up to 10 minutes. Thank you for your patience!",
      },
      [AgentSessionStepType.SOC2_TYPE1]: {
        breadcrumb: "Review Responses",
        title: "AI is preparing your Gap Assessment",
        subtitle: "This process might take up to 10 minutes. Thank you for your patience!",
      },
      [AgentSessionStepType.EDIT_RESPONSE]: {
        breadcrumb: "Review Responses",
      },
      [AgentSessionStepType.PREPARE_REPORT]: {
        breadcrumb: "Session Completed",
      },
      [AgentSessionStepType.CONTROL_SELECTION]: {
        breadcrumb: "Customise Assessment",
      },
    },
  },
  [GapAssessmentTypes.SOC2_TYPE2]: {
    title: "SOC 2 Type II Gap Assessment",
    rowName: "control",
    process: {
      [AgentSessionStepType.SOC2_TYPE2_AUDIT]: {
        breadcrumb: "Review Responses",
        title: "AI is preparing your Gap Assessment",
        subtitle: "This process might take up to 10 minutes. Thank you for your patience!",
      },
      [AgentSessionStepType.SOC2_TYPE2]: {
        breadcrumb: "Review Responses",
        title: "AI is preparing your Gap Assessment",
        subtitle: "This process might take up to 10 minutes. Thank you for your patience!",
      },
      [AgentSessionStepType.EDIT_RESPONSE]: {
        breadcrumb: "Review Responses",
      },
      [AgentSessionStepType.PREPARE_REPORT]: {
        breadcrumb: "Session Completed",
      },
      [AgentSessionStepType.CONTROL_SELECTION]: {
        breadcrumb: "Customise Assessment",
      },
    },
  },
  // [GapAssessmentTypes.SOC2_TYPE1]: {
  //   title: 'SOC2 Gap Assessment',
  //   rowName: 'control',
  //   process: {
  //     [AgentSessionStepType.SOC2_TYPE2_AUDIT]: {
  //       breadcrumb: 'Review Responses',
  //       title: 'AI is preparing your Gap Assessment',
  //       subtitle:
  //         'This process might take up to 10 minutes. Thank you for your patience!',
  //     },
  //     [AgentSessionStepType.EDIT_RESPONSE]: {
  //       breadcrumb: 'Review Responses',
  //     },
  //     [AgentSessionStepType.PREPARE_REPORT]: {
  //       breadcrumb: 'Session Completed',
  //     },
  //     [AgentSessionStepType.CONTROL_SELECTION]: {
  //       breadcrumb: 'Customise Assessment',
  //     },
  //   },
  // },
  // [GapAssessmentTypes.SOC2_TYPE2]: {
  //   title: 'SOC2 Gap Assessment',
  //   rowName: 'control',
  //   process: {
  //     [AgentSessionStepType.SOC2_TYPE2_AUDIT]: {
  //       breadcrumb: 'Review Responses',
  //       title: 'AI is preparing your Gap Assessment',
  //       subtitle:
  //         'This process might take up to 10 minutes. Thank you for your patience!',
  //     },
  //     [AgentSessionStepType.EDIT_RESPONSE]: {
  //       breadcrumb: 'Review Responses',
  //     },
  //     [AgentSessionStepType.PREPARE_REPORT]: {
  //       breadcrumb: 'Session Completed',
  //     },
  //     [AgentSessionStepType.CONTROL_SELECTION]: {
  //       breadcrumb: 'Customise Assessment',
  //     },
  //   },
  // },
  [GapAssessmentTypes.DORA]: {
    title: "DORA Gap Assessment",
    rowName: "requirement",
    process: {
      [AgentSessionStepType.DORA_ASSESSMENT]: {
        breadcrumb: "Review Responses",
        title: "AI is preparing your Gap Assessment",

        subtitle: "This process might take up to 10 minutes. Thank you for your patience!",
      },
      [AgentSessionStepType.EDIT_RESPONSE]: {
        breadcrumb: "Review Responses",
      },
      [AgentSessionStepType.PREPARE_REPORT]: {
        breadcrumb: "Responses",
      },
    },
  },
  [MultiVendorAssessmentTypes.VENDOR_ASSESSMENT]: {
    title: "Third-Party Risk Assessment",
    rowName: "control",
    process: {
      [AgentSessionStepType.MULTI_VENDOR_PROFILE]: {
        breadcrumb: "Vendor assessment",
        title: "AI is preparing your Third-Party Risk Assessment",
        subtitle: "This process might take up to 10 minutes. Thank you for your patience!",
      },
      [AgentSessionStepType.MULTI_FILE_VENDOR_ASSESSMENT]: {
        breadcrumb: "Vendor assessment",
        title: "AI is preparing your Third-Party Risk Assessment",
        subtitle: "This process might take up to 10 minutes. Thank you for your patience!",
      },
      [AgentSessionStepType.EDIT_RESPONSE]: {
        breadcrumb: "Review Responses",
      },
      [AgentSessionStepType.PREPARE_REPORT]: {
        breadcrumb: "Session Completed",
      },
      [AgentSessionStepType.CONTROL_SELECTION]: {
        breadcrumb: "Customise Assessment",
      },
    },
  },

  // the following is really NOT NEEDED, just here to keep tsc happy,
  // without having to change the type to a Partial<>
  [MultiVendorAssessmentTypes.VENDOR_SUPPLY_CHAIN_TABLE]: {
    title: "Vendor Supply Chain Table",
    rowName: "control",
    process: {},
  },
  [MultiVendorAssessmentTypes.VENDOR_INTAKE_FORM]: {
    title: "Vendor Intake Form",
    rowName: "control",
    process: {},
  },
};

export enum RenderCellTypeEnum {
  TAG = "tag",
  RISK_STRING = "riskString",
  RISK_LEVEL = "riskLevel",
  CONFIDENCE = "confidence",
  SOURCE = "source",
  STATUS = "status",
  CHAPTER_AND_ARTICLE = "chapter-and-article",
  COMPLIANT = "compliant",
  STRING = "STRING",
  CUSTOM = "custom",
  VENDOR_QUESTION = "vendor_question",
  MATURITY = "maturity",
  BREAKDOWN = "breakdown"
}

interface CommonTableConfig {
  renderType: RenderCellTypeEnum;
}

interface FairTableConfig {
  renderType: RenderCellTypeEnum;
  fieldName: string;
  headerName: string;
}

interface TableConfig {
  [RiskAssessmentTypes.NIST_CSF_2]: (ZTableColumnProps<RiskReviewResponseTableRow> & CommonTableConfig)[];
  [RiskAssessmentTypes.NIST_AI_RMF]: (ZTableColumnProps<RiskReviewResponseTableRow> & CommonTableConfig)[];
  [QuestionnaireTypes.QA]: (ZTableColumnProps<QuestionnaireTableRow> & CommonTableConfig)[];
  [RiskAssessmentTypes.FAIR]: (ZTableColumnProps<FairTableConfig> & CommonTableConfig)[];
  [GapAssessmentTypes.SOC2]: (ZTableColumnProps<Soc2GapReviewResponseTableRow> & CommonTableConfig)[];
  [GapAssessmentTypes.SOC2_TYPE1]: (ZTableColumnProps<Soc2GapReviewResponseTableRow> & CommonTableConfig)[];
  [GapAssessmentTypes.SOC2_TYPE2]: (ZTableColumnProps<Soc2GapReviewResponseTableRow> & CommonTableConfig)[];
  [GapAssessmentTypes.DORA]: (ZTableColumnProps<DoraGapReviewResponseTableRow> & CommonTableConfig)[];
  [MultiVendorAssessmentTypes.VENDOR_ASSESSMENT]: (ZTableColumnProps<MultiVendorAssessmentTableRow> &
    CommonTableConfig)[];
  [MultiVendorAssessmentTypes.VENDOR_SUPPLY_CHAIN_TABLE]: (ZTableColumnProps<ISupplyChain> & CommonTableConfig)[];
  [MultiVendorAssessmentTypes.VENDOR_INTAKE_FORM]: [];
}

const SOC2_TABLE_HELPER_VALUE: (ZTableColumnProps<Soc2GapReviewResponseTableRow> & CommonTableConfig)[] = [
  {
    type: "custom",
    fieldName: "id",
    headerName: "ID",
    renderType: RenderCellTypeEnum.TAG,
  },
  {
    type: "custom",
    fieldName: "control",
    headerName: "Control",
    renderType: RenderCellTypeEnum.RISK_STRING,
  },
  {
    type: "custom",
    fieldName: "compliant",
    headerName: "Compliant",
    renderType: RenderCellTypeEnum.COMPLIANT,
    sortable: true,
    getSortValue: ({ compliant }) => compliant,
    sortComparator: (a, b) => {
      const aValue = a.compliant;
      const bValue = b.compliant;
      if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.localeCompare(bValue);
      }
      return 0;
    },
  },
  {
    type: "custom",
    fieldName: "confidence",
    headerName: "Confidence",
    sortable: true,
    renderType: RenderCellTypeEnum.CONFIDENCE,
    getSortValue: ({ confidence }) => confidence,
    sortComparator: (a, b) => {
      const aValue = a.confidence;
      const bValue = b.confidence;
      if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.localeCompare(bValue);
      }
      return 0;
    },
  },
  {
    type: "custom",
    fieldName: "source",
    headerName: "References",
    renderType: RenderCellTypeEnum.SOURCE,
  },
  {
    type: "custom",
    fieldName: "status",
    headerName: "Status",
    sortable: true,
    renderType: RenderCellTypeEnum.STATUS,
    align: "center",
  },
];

export const tableConfigs: TableConfig = {
  [RiskAssessmentTypes.NIST_CSF_2]: [
    {
      type: "custom",
      fieldName: "id",
      headerName: "ID",
      renderType: RenderCellTypeEnum.TAG,
    },
    {
      type: "custom",
      fieldName: "control",
      headerName: "Control",
      renderType: RenderCellTypeEnum.RISK_STRING,
    },
    // {
    //   type: "custom",
    //   fieldName: "riskLevel",
    //   headerName: "Risk Level",
    //   renderType: RenderCellTypeEnum.RISK_LEVEL,
    //   sortable: true,
    //   getSortValue: ({ riskValue }) => riskValue,
    //   sortComparator: (a, b) => {
    //     const aValue = Number(a.riskValue);
    //     const bValue = Number(b.riskValue);
    //     if (isNaN(aValue) || isNaN(bValue)) {
    //       return 0;
    //     }
    //     return aValue - bValue;
    //   },
    // },
    {
      type: "custom",
      fieldName: "maturity",
      headerName: "Maturity",
      renderType: RenderCellTypeEnum.MATURITY,
      sortable: true,
    },
    {
      type: "custom",
      fieldName: "confidence",
      headerName: "Confidence",
      sortable: true,
      renderType: RenderCellTypeEnum.CONFIDENCE,
      getSortValue: ({ confidence }) => confidence,
      sortComparator: (a, b) => {
        const aValue = a.confidence;
        const bValue = b.confidence;
        if (typeof aValue === "string" && typeof bValue === "string") {
          return aValue.localeCompare(bValue);
        }
        return 0;
      },
    },
    {
      type: "custom",
      fieldName: "source",
      headerName: "References",
      renderType: RenderCellTypeEnum.SOURCE,
    },
    {
      type: "custom",
      fieldName: "status",
      headerName: "Status",
      sortable: true,
      renderType: RenderCellTypeEnum.STATUS,
      sortComparator: (a, b) => {
        const aValue = a.status;
        const bValue = b.status;
        if (typeof aValue === "string" && typeof bValue === "string") {
          return aValue.localeCompare(bValue);
        }
        return 0;
      },
    },
  ],
  [RiskAssessmentTypes.NIST_AI_RMF]: [
    {
      type: "custom",
      fieldName: "id",
      headerName: "ID",
      renderType: RenderCellTypeEnum.TAG,
    },
    {
      type: "custom",
      fieldName: "control",
      headerName: "Control",
      renderType: RenderCellTypeEnum.RISK_STRING,
    },
    // {
    //   type: "custom",
    //   fieldName: "riskLevel",
    //   headerName: "Risk Level",
    //   renderType: RenderCellTypeEnum.RISK_LEVEL,
    //   sortable: true,
    //   getSortValue: ({ riskValue }) => riskValue,
    //   sortComparator: (a, b) => {
    //     const aValue = Number(a.riskValue);
    //     const bValue = Number(b.riskValue);
    //     if (isNaN(aValue) || isNaN(bValue)) {
    //       return 0;
    //     }
    //     return aValue - bValue;
    //   },
    // },
    {
      type: "custom",
      fieldName: "maturity",
      headerName: "Maturity",
      renderType: RenderCellTypeEnum.MATURITY,
      sortable: true,
    },
    {
      type: "custom",
      fieldName: "confidence",
      headerName: "Confidence",
      sortable: true,
      renderType: RenderCellTypeEnum.CONFIDENCE,
      getSortValue: ({ confidence }) => confidence,
      sortComparator: (a, b) => {
        const aValue = a.confidence;
        const bValue = b.confidence;
        if (typeof aValue === "string" && typeof bValue === "string") {
          return aValue.localeCompare(bValue);
        }
        return 0;
      },
    },
    {
      type: "custom",
      fieldName: "source",
      headerName: "References",
      renderType: RenderCellTypeEnum.SOURCE,
    },
    {
      type: "custom",
      fieldName: "status",
      headerName: "Status",
      sortable: true,
      renderType: RenderCellTypeEnum.STATUS,
      sortComparator: (a, b) => {
        const aValue = a.status;
        const bValue = b.status;
        if (typeof aValue === "string" && typeof bValue === "string") {
          return aValue.localeCompare(bValue);
        }
        return 0;
      },
    },
  ],
  [RiskAssessmentTypes.FAIR]: [
    {
      type: "custom",
      fieldName: "control",
      headerName: "Breakdown",
      renderType: RenderCellTypeEnum.BREAKDOWN,
      headerClassName: "pl-6",
    },
  ],
  [QuestionnaireTypes.QA]: [
    {
      type: "custom",
      fieldName: "question",
      headerName: "Question",
      renderType: RenderCellTypeEnum.RISK_STRING,
    },
    {
      type: "custom",
      fieldName: "confidence",
      headerName: "Confidence",
      sortable: true,
      renderType: RenderCellTypeEnum.CONFIDENCE,
      getSortValue: ({ confidence }) => confidence,
      sortComparator: (a, b) => {
        const aValue = a.confidence;
        const bValue = b.confidence;
        if (typeof aValue === "string" && typeof bValue === "string") {
          return aValue.localeCompare(bValue);
        }
        return 0;
      },
    },
    {
      type: "custom",
      fieldName: "source",
      headerName: "References",
      renderType: RenderCellTypeEnum.SOURCE,
    },
    {
      type: "custom",
      fieldName: "status",
      headerName: "Status",
      sortable: true,
      renderType: RenderCellTypeEnum.STATUS,
    },
  ],
  [GapAssessmentTypes.SOC2]: SOC2_TABLE_HELPER_VALUE,
  [GapAssessmentTypes.SOC2_TYPE1]: SOC2_TABLE_HELPER_VALUE,
  [GapAssessmentTypes.SOC2_TYPE2]: SOC2_TABLE_HELPER_VALUE,
  [GapAssessmentTypes.DORA]: [
    {
      type: "custom",
      fieldName: "id",
      headerName: "ID",
      renderType: RenderCellTypeEnum.TAG,
    },
    {
      type: "custom",
      fieldName: "requirement",
      headerName: "Requirement",
      renderType: RenderCellTypeEnum.RISK_STRING,
    },
    {
      type: "custom",
      fieldName: "compliance_status",
      headerName: "Compliant",
      renderType: RenderCellTypeEnum.COMPLIANT,
      sortable: true,
      getSortValue: ({ compliant }) => compliant as string,
      sortComparator: (a, b) => {
        const aValue = a.compliant as string;
        const bValue = b.compliant as string;
        if (typeof aValue === "string" && typeof bValue === "string") {
          return aValue.localeCompare(bValue);
        }
        return 0;
      },
    },
    {
      type: "custom",
      fieldName: "confidence",
      headerName: "Confidence",
      sortable: true,
      renderType: RenderCellTypeEnum.CONFIDENCE,
      getSortValue: ({ confidence }) => confidence,
      sortComparator: (a, b) => {
        const aValue = a.confidence;
        const bValue = b.confidence;
        if (typeof aValue === "string" && typeof bValue === "string") {
          return aValue.localeCompare(bValue);
        } else if (typeof aValue === "number" && typeof bValue === "number") {
          return aValue - bValue;
        }
        return 0;
      },
    },
    {
      type: "custom",
      fieldName: "source",
      headerName: "References",
      sortable: false,
      renderType: RenderCellTypeEnum.SOURCE,
    },
    {
      type: "custom",
      fieldName: "status",
      headerName: "Status",
      sortable: true,
      renderType: RenderCellTypeEnum.STATUS,
      align: "center",
    },
  ],
  [MultiVendorAssessmentTypes.VENDOR_ASSESSMENT]: [
    {
      type: "custom",
      fieldName: "question",
      headerName: "Control",
      renderType: RenderCellTypeEnum.VENDOR_QUESTION,
    },
    {
      type: "custom",
      fieldName: "compliant",
      headerName: "Compliant",
      renderType: RenderCellTypeEnum.COMPLIANT,
      sortable: true,
      getSortValue: ({ compliant }) => compliant as string,
      sortComparator: (a, b) => {
        const aValue = a.compliant as string;
        const bValue = b.compliant as string;
        const compliantOrder = {
          "fully-compliant": 1,
          "partially-compliant": 2,
          "non-compliant": 3,
          "data-not-found": 4,
          "not-applicable": 4,
        };
        if (typeof aValue === "string" && typeof bValue === "string") {
          const aOrder = compliantOrder[aValue.toLowerCase() as keyof typeof compliantOrder] || 4;
          const bOrder = compliantOrder[bValue.toLowerCase() as keyof typeof compliantOrder] || 4;
          return aOrder - bOrder;
        }
        return 0;
      },
    },
    {
      type: "custom",
      fieldName: "confidence",
      headerName: "Confidence",
      sortable: true,
      renderType: RenderCellTypeEnum.CONFIDENCE,
      getSortValue: ({ confidence }) => confidence,
      sortComparator: (a, b) => {
        const aValue = a.confidence;
        const bValue = b.confidence;
        const confidenceOrder = {
          high: 1,
          medium: 2,
          low: 3,
          dnf: 4,
          na: 4,
        };
        if (typeof aValue === "string" && typeof bValue === "string") {
          const aOrder = confidenceOrder[aValue.toLowerCase() as keyof typeof confidenceOrder] || 5;
          const bOrder = confidenceOrder[bValue.toLowerCase() as keyof typeof confidenceOrder] || 5;
          return aOrder - bOrder;
        }
        return 0;
      },
    },
    {
      type: "custom",
      fieldName: "source",
      headerName: "References",
      sortable: false,
      renderType: RenderCellTypeEnum.SOURCE,
    },
    {
      type: "custom",
      fieldName: "status",
      headerName: "Status",
      sortable: true,
      renderType: RenderCellTypeEnum.STATUS,
      align: "center",
    },
  ],
  [MultiVendorAssessmentTypes.VENDOR_SUPPLY_CHAIN_TABLE]: [
    {
      type: "custom",
      fieldName: "type",
      headerName: "Type",
      sortable: true,
      getSortValue: ({ type }) => type,
      renderType: RenderCellTypeEnum.STRING,
      renderCell: ({ type }) => {
        return type;
      },
    },
    {
      type: "custom",
      fieldName: "subServices",
      headerName: "Subservices",
      sortable: true,
      getSortValue: ({ subServices }) => subServices,
      renderType: RenderCellTypeEnum.STRING,
    },
    {
      type: "custom",
      fieldName: "purpose",
      headerName: "Purpose",
      sortable: true,
      getSortValue: ({ purpose }) => purpose,
      renderType: RenderCellTypeEnum.STRING,
    },
    {
      type: "custom",
      fieldName: "confidence",
      headerName: "Confidence",
      sortable: true,
      renderType: RenderCellTypeEnum.CONFIDENCE,
      getSortValue: ({ confidence }) => confidence,
      sortComparator: (a, b) => {
        const aValue = a.confidence;
        const bValue = b.confidence;
        if (typeof aValue === "string" && typeof bValue === "string") {
          return aValue.localeCompare(bValue);
        }
        return 0;
      },
    },
    {
      type: "custom",
      fieldName: "source",
      headerName: "References",
      renderType: RenderCellTypeEnum.SOURCE,
    },
    {
      type: "custom",
      fieldName: "",
      headerName: "",
      renderType: RenderCellTypeEnum.CUSTOM,
      renderCell: SupplyChainKebabMenu,
    },
  ],
  [MultiVendorAssessmentTypes.VENDOR_INTAKE_FORM]: [],
};

export const mappingHelper = {
  [MappingType.QUESTIONS]: {
    label: "Question",
    bg: "#FFF6E6",
    text: "#A35200",
  },
  [MappingType.QUESTION_START_ROW]: {
    label: "Start Row",
    bg: "#EFFBEF",
    text: "#297A3A",
  },
  [MappingType.COMMENTS]: {
    label: "Comment",
    bg: "#FFEBF5",
    text: "#BD2864",
  },
  [MappingType.ANSWERS]: {
    label: "Answer",
    bg: "#F9F0FF",
    text: "#7820BC",
  },
  [MappingType.QUESTION_TYPE]: {
    label: "Instructions",
    bg: "#E6F1FE",
    text: "#002E62",
  },
};

export const confidenceHelper = {
  [ConfidenceTypes.HIGH]: {
    color: "#17C964",
    label: "High Confidence",
  },
  [ConfidenceTypes.MEDIUM]: {
    color: "#F5A524",
    label: "Medium Confidence",
  },
  [ConfidenceTypes.LOW]: {
    color: "#F4286F",
    label: "Low Confidence",
  },
  [ConfidenceTypes.DNF]: {
    color: "#D4D4D8",
    label: "Data Not Found",
  },
  [ConfidenceTypes.NA]: {
    color: "#F4286F",
    label: "Data Not Found",
  },
};

export const getRiskValueStyle = (value: number | string) => {
  let numericValue: number;

  if (typeof value === "string") {
    numericValue = Number.parseFloat(value);
    if (isNaN(numericValue)) {
      return "";
    }
  } else {
    numericValue = value;
  }

  if (numericValue >= 1 && numericValue < 2) {
    return riskLevelStyles[RiskValue.VERY_LOW];
  } else if (numericValue >= 2 && numericValue < 3) {
    return riskLevelStyles[RiskValue.LOW];
  } else if (numericValue >= 3 && numericValue < 4) {
    return riskLevelStyles[RiskValue.MEDIUM];
  } else if (numericValue >= 4 && numericValue < 5) {
    return riskLevelStyles[RiskValue.HIGH];
  } else if (numericValue >= 5) {
    return riskLevelStyles[RiskValue.VERY_HIGH];
  }
  return "";
};

export const getMaturityLevelStyle = (value: number) => {
  let numericValue: number;

  if (typeof value === "string") {
    numericValue = Number.parseFloat(value);
    if (isNaN(numericValue)) {
      return "";
    }
  } else {
    numericValue = value;
  }

  if (numericValue === 1) {
    return maturityLevelStyles[RiskValue.VERY_HIGH];
  } else if (numericValue === 2) {
    return maturityLevelStyles[RiskValue.HIGH];
  } else if (numericValue === 3) {
    return maturityLevelStyles[RiskValue.MEDIUM];
  } else if (numericValue === 4) {
    return maturityLevelStyles[RiskValue.LOW];
  }
  return "";
};

export const riskLevelStyles = {
  [RiskValue.VERY_LOW]: "text-[#74DFA2] bg-[#E8FAF0] ",
  [RiskValue.LOW]: "text-[#17C964] bg-[#D1F4E0] ",
  [RiskValue.MEDIUM]: "text-[#F5A524] bg-[#FDEDD3] ",
  [RiskValue.HIGH]: "text-[#F31260] bg-[#FDD0DF] ",
  [RiskValue.VERY_HIGH]: "text-[#920B3A] bg-[#FAA0BF] ",
};

export const maturityLevelStyles = {
  [RiskValue.LOW]: "text-[#17C964] bg-[#D1F4E0] ",
  [RiskValue.MEDIUM]: "text-[#F5A524] bg-[#FDEDD3] ",
  [RiskValue.HIGH]: "text-[#F31260] bg-[#FDD0DF] ",
  [RiskValue.VERY_HIGH]: "text-[#920B3A] bg-[#FAA0BF] ",
};

export const reassessmentHelper = {
  title: "Reassessment",
  process: {
    UNDER_REASSESSMENT: {
      breadcrumb: "Reassessment",
      title: "Reassessment in Progress",
      subtitle: "You'll be redirected to new responses once it's complete. This process should take about 15 minutes.",
    },
  },
};
