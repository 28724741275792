import { authenticateInviteMagicLink, generateJWTTokenFromInitialAuth, getInitialOrganizationListFromAuth, getOrganizationList } from "@/modules/auth/requests";
import { LOGIN_STEP } from "@/modules/auth/types";
import { ROUTES } from "@/shared/constants/routes";
import { addNotification } from "@/shared/states/notification";
import { setCookie } from "@/shared/utils/cookie-helper";
import parsePromise from "@/shared/utils/parsePromise";
import ZaniaLogoLoader from "@components/page-loader";
import { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthActions } from "../../states";
import { useLoginActions } from "../../states/login";
import { authenticateSso } from "../../use-cases/sso.use-case";
import { authChannel } from "@/infra/auth/broadcast";

const Authenticate = () => {
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");
  const tokenType = searchParams.get("stytch_token_type");
  const { setAuth } = useAuthActions();
  const navigate = useNavigate();
  const {
    setLoginStep,
    setDiscoveredOrganizations,
  } = useLoginActions();

  const completeAuth = useCallback(() => {
    setAuth({
      isAuthenticated: true,
      isAuthenticationInProgress: false,
    });
    authChannel.postMessage("loggedIn")
  }, [setAuth]);

  useEffect(() => {
    setLoginStep(LOGIN_STEP.AUTHENTICATING);
  }, []);

  let count = 0;
  const authenticate = useCallback(async () => {

    if (token && tokenType) {
      // Prevent multiple calls
      count += 1;
      if (count > 1) {
        return;
      }

      switch (tokenType) {
        case "discovery":
        case "discovery_oauth": {
          const [error, data] = await parsePromise(getInitialOrganizationListFromAuth(tokenType === "discovery_oauth" ? "discover_oauth" : tokenType, token));
          if (error) {
            addNotification({
              type: "error",
              title: "Error",
              message: error?.message,
            });
            setLoginStep(LOGIN_STEP.MAIN);
            navigate(`/${ROUTES.LOGIN}`);
            return;
          }
          if (data && data?.intermediate_session_token) {
            document.cookie = `intermediate_session_token=${data.intermediate_session_token};path=/`
          }

          if (data?.discovered_organizations?.length && data?.discovered_organizations?.length > 1) {
            localStorage.setItem("discoveredOrgs", JSON.stringify(data?.discovered_organizations));
            setDiscoveredOrganizations(data.discovered_organizations);
            setLoginStep(LOGIN_STEP.SELECT_ORG);
            navigate(`/${ROUTES.LOGIN}`);
            authChannel.postMessage("selectOrganization");
          } else if (data?.discovered_organizations?.length && data?.discovered_organizations?.length === 1) {
            const [error, resp] = await parsePromise(generateJWTTokenFromInitialAuth(data.discovered_organizations[0].organization.organization_id, data.intermediate_session_token));
            if (!error) {
              navigate(`/${ROUTES.AGENT}`);
              completeAuth();
            }

          } else {
            addNotification({
              type: "error",
              title: "No organizations found",
              message: "Please contact support@zania.ai to get access to an organization",
            });
            setLoginStep(LOGIN_STEP.MAIN);
            navigate(`/${ROUTES.LOGIN}`);
          }          

          break;
        }
        case "multi_tenant_magic_links": {
          const [error, data] = await parsePromise(authenticateInviteMagicLink(token));
          if (error) {
            addNotification({
              type: "error",
              title: "Error",
              message: error?.message,
            });
            setLoginStep(LOGIN_STEP.MAIN);
            navigate(`/${ROUTES.LOGIN}`);
            return;
          }
          else {
            completeAuth();
            document.cookie = `intermediate_session_token=${token};path=/`
            setLoginStep(LOGIN_STEP.MAIN);
            navigate(`/${ROUTES.AGENT}`)
          }
          break;
        }
        case "sso": {
          const [error, data] = await parsePromise(authenticateSso(token));
          if (error) {
            addNotification({
              type: "error",
              title: "Error",
              message: error?.message,
            });
            setLoginStep(LOGIN_STEP.MAIN);
            navigate(`/${ROUTES.LOGIN}`);
          }
          else {
            const [err, orgs] = await parsePromise(getOrganizationList());
            if (!err) {
              setCookie("auth_type", "sso", 1);
              document.cookie = `intermediate_session_token=${token};path=/`
              setDiscoveredOrganizations(orgs.discovered_organizations);
              if (orgs.discovered_organizations && orgs.discovered_organizations.length > 1) {
                localStorage.setItem("discoveredOrgs", JSON.stringify(orgs?.discovered_organizations));
                setLoginStep(LOGIN_STEP.SELECT_ORG);
                navigate(`/${ROUTES.LOGIN}`);
                authChannel.postMessage("selectOrganization");
              }
              else {
                completeAuth();
                setLoginStep(LOGIN_STEP.MAIN);
                navigate(`/${ROUTES.AGENT}`)
              }
            }
          }


          break;
        }
        default:
          break;
      }
    }
  }, [tokenType, token, completeAuth]);

  useEffect(() => {
    void authenticate();
  }, [authenticate]);

  return <ZaniaLogoLoader />;
};

export default Authenticate;
