import { getAgentData, getAgentStateActions, useAgentStepData } from "@/modules/agent/states";
import { AgentSessionStatus, AgentSessionStepType } from "@/modules/sessions/types";
import { useParams } from "react-router-dom";
/* eslint-disable prettier/prettier */
import { useVendorRiskAssessment } from "../useVendorRiskAssessment/useVendorRiskAssessment";
import { useVendorRiskAssessmentStore } from "../useVendorRiskAssessment/useVendorRiskAssessmentStore";
import { VendorRiskSectionEnum } from "@/modules/agent/types/vendorAssessment";

import {
  VENDOR_DATA_TYPES_HANDLED,
  VENDOR_INCIDENTS_IN_LAST_36_MONTHS,
} from "@/modules/agent/constants/vendorAssessmentConstants";
import { useControlEventsSubscription } from "@/modules/agent/hooks/useControlEventsSubscription";
import { useControlStatus } from "@/modules/agent/hooks/useControlStatus";
import { useSessionSubscription } from "@/modules/agent/hooks/useSessionSubscription";
import {
  VendorOverviewKey,
  VendorOverviewKeyToValueTypeMap,
  VendorRiskResponseItemForVendorOverview,
} from "@/modules/agent/types/vendorOverviewSection";
import {
  ComplianceAdherenceCard,
  DropDownAndTextAreaCard,
  RiskAndJustitificationCard,
  TextAreaCard,
} from "./OverviewCards";
import { getCustomizeControlsActions } from "@/modules/agent/states/cusomize-controls";

export interface IdParams {
  id: string;
}

const Overview = () => {
  const { vendorOverview } = useVendorRiskAssessment();
  const setVendorOverview = useVendorRiskAssessmentStore().setVendorOverview;

  const { id } = useParams<keyof IdParams>() as IdParams;

  // Subscribe to session events
  useControlStatus(id);
  useSessionSubscription(id);
  useControlEventsSubscription(id);

  const stepData = useAgentStepData(id);
  const isReportReady =
    stepData?.find((step) => step.type === AgentSessionStepType.PREPARE_REPORT)
      ?.status === AgentSessionStatus.COMPLETE;

  const getFieldValue = <
    K extends VendorOverviewKey,
    V extends keyof VendorOverviewKeyToValueTypeMap[K]
  >(
    fieldKey: K,
    valueKey: V
  ) => {
    const item = vendorOverview.find(
      (overviewItem) => overviewItem.key === fieldKey
    );
    return item?.value ? (item.value as VendorOverviewKeyToValueTypeMap[K])[valueKey] : [];
  };

  const handleFieldValueChange = <
    K extends VendorOverviewKey,
    V extends keyof VendorOverviewKeyToValueTypeMap[K]
  >(
    fieldKey: K,
    valueKey: V,
    newValue: any
  ) => {
    const newVendorOverview = [...vendorOverview];
    const indexOfOverviewItem = vendorOverview.findIndex(
      (overviewItem) => overviewItem.key === fieldKey
    );
    if (indexOfOverviewItem > -1) {
      (newVendorOverview[indexOfOverviewItem]
        .value as VendorOverviewKeyToValueTypeMap[K])[valueKey] = newValue;
    }
    
    // Get store actions
    const store = useVendorRiskAssessmentStore.getState();
    
    // Set saving state and update overview data
    store.setIsSaving(true);
    setVendorOverview(newVendorOverview);

    // Update combinedVendorRiskData as well
    if (store.combinedVendorRiskData) {
      const updatedCombinedData = {
        ...store.combinedVendorRiskData,
        [VendorRiskSectionEnum.vendor_overview]: newVendorOverview
      };
      store.setCombinedVendorRiskData(updatedCombinedData);
    }

    // to explicitly trigger changes
    getCustomizeControlsActions().setChangesMade((prev) => prev + 1);
     // Reset saving state after a delay
     setTimeout(() => {
      store.setIsSaving(false);
    }, 2000);
  };

  return (
    <div className="p-6 grid gap-4 grid-cols-2 w-full bg-white">
      <div className="col-span-2">
        <RiskAndJustitificationCard
          title="Risk & Justification"
          riskLevel={getFieldValue("risk_rating", "value")}
          updateRiskLevel={(newValue: VendorOverviewKeyToValueTypeMap["risk_rating"]["value"]) =>
            handleFieldValueChange("risk_rating", "value", newValue)
          }
          handleTextAreaValueChange={(newValue: VendorOverviewKeyToValueTypeMap["risk_rating"]["justification"]) =>
            handleFieldValueChange("risk_rating", "justification", newValue)
          }
          textAreaValue={getFieldValue("risk_rating", "justification") ?? ""}
          isDisabled={isReportReady}
          sessionId={id}
          controlId="risk_rating"
        />
      </div>
      <TextAreaCard
        title="Company Information"
        handleTextAreaValueChange={(newValue: VendorOverviewKeyToValueTypeMap["company_description"]["value"]) =>
          handleFieldValueChange("company_description", "value", newValue)
        }
        textAreaValue={getFieldValue("company_description", "value") ?? ""}
        isDisabled={isReportReady}
        sessionId={id}
        controlId="company_info"
      />
      <TextAreaCard
        title="Product/ Service Information"
        handleTextAreaValueChange={(newValue: VendorOverviewKeyToValueTypeMap["description_of_services"]["value"]) =>
          handleFieldValueChange("description_of_services", "value", newValue)
        }
        textAreaValue={getFieldValue("description_of_services", "value") ?? ""}
        isDisabled={isReportReady}
        sessionId={id}
        controlId="product_service_info"
      />
      <TextAreaCard
        title="Integration Requirements"
        handleTextAreaValueChange={(newValue: VendorOverviewKeyToValueTypeMap["integration_overview"]["value"]) =>
          handleFieldValueChange("integration_overview", "value", newValue)
        }
        textAreaValue={getFieldValue("integration_overview", "value") ?? ""}
        isDisabled={isReportReady}
        sessionId={id}
        controlId="integration_overview"
      />
      <ComplianceAdherenceCard
        title="Compliance Adherence"
        values={getFieldValue("compliance", "value") ?? []}
        listOfExtraOptions={
          (vendorOverview.find(
            (overviewItem) => overviewItem.key === "compliance"
          ) as VendorRiskResponseItemForVendorOverview<"compliance">)
            ?.customer_added_certifications ?? []
        }
        handleChange={(newValues: VendorOverviewKeyToValueTypeMap["compliance"]["value"]) =>
          handleFieldValueChange("compliance", "value", newValues)
        }
        isDisabled={isReportReady}
        sessionId={id}
        controlId="compliance"
      />
      <DropDownAndTextAreaCard
        title="Data Types Handled"
        selectionMode="multiple"
        dropDownOptions={[...VENDOR_DATA_TYPES_HANDLED]}
        handleDropDownChanges={(newValues: VendorOverviewKeyToValueTypeMap["data_types_handled"]["data_types"]) =>
          handleFieldValueChange("data_types_handled", "data_types", newValues)
        }
        dropdownValues={getFieldValue("data_types_handled", "data_types") ?? []}
        textAreaValue={getFieldValue("data_types_handled", "purpose") ?? ""}
        handleTextAreaValueChange={(newValue: VendorOverviewKeyToValueTypeMap["data_types_handled"]["purpose"]) =>
          handleFieldValueChange("data_types_handled", "purpose", newValue)
        }
        otherValues={getFieldValue("data_types_handled", "other_data_types") ?? ""}
        handleOtherValueChange={(newValue: VendorOverviewKeyToValueTypeMap["data_types_handled"]["other_data_types"]) =>
          handleFieldValueChange("data_types_handled", "other_data_types", newValue)
        }
        isDisabled={isReportReady}
        sessionId={id}
        controlId="data_types_handled"
      />
      <DropDownAndTextAreaCard
        title="Incidents in Last 36 Months"
        selectionMode="single"
        dropDownOptions={[...VENDOR_INCIDENTS_IN_LAST_36_MONTHS]}
        handleDropDownChanges={(newValues: VendorOverviewKeyToValueTypeMap["security_incidents"]["incident_history"]) =>
          handleFieldValueChange(
            "security_incidents",
            "incident_history",
            newValues[0]
          )
        }
        dropdownValues={[
          getFieldValue("security_incidents", "incident_history"),
        ]}
        textAreaValue={
          getFieldValue("security_incidents", "incident_details") ?? ""
        }
        handleTextAreaValueChange={(newValue: VendorOverviewKeyToValueTypeMap["security_incidents"]["incident_details"]) =>
          handleFieldValueChange(
            "security_incidents",
            "incident_details",
            newValue
          )
        }
        isDisabled={isReportReady}
        sessionId={id}
        controlId="security_incidents"
      />
    </div>
  );
};

export default Overview;
