import { AGENT_TYPES } from "../types";

export const getOrderedKeys = (agentType: AGENT_TYPES, version: string  ) => {
    switch (agentType) {
      case AGENT_TYPES.RISK_ASSESSMENT:
        return [
          "risk_value",
          "risk_statement",
          "assessment_approach_presentation",
          "observations",
          "gaps",
          "recommendations",
          "improvement_opportunities",
          "improvement_recommendations",
          "current_maturity_level",
          "description_of_current_maturity_level",
          "path_to_next_maturity_level",
          "sources",
        ];
        
      case AGENT_TYPES.GAP_ASSESSMENT:
        return [
          "compliance_status",
          version === "v2" ? "toe" : "tod",
          "test_results",
          "gaps",
          "improvement_opportunities",
          "sources",
        ];
      default:
        return [];
    }
  };
  
