import { LoadingSpinner } from "@/shared/components/custom/spin";
import truncateText from "@/shared/utils/truncate-text";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Tooltip,
} from "@nextui-org/react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ReviewSourceTypes } from "../../types";

interface JSONPreviewHighlightProps {
  fileUrl: string;
  open: boolean;
  onClose: () => void;
  fileName: string;
  textToHighlight: string;
  setSelectedSource: React.Dispatch<
    React.SetStateAction<ReviewSourceTypes | undefined>
  >;
  selectedSource?: ReviewSourceTypes;
  listOfSourcesForSelectedSources?: ReviewSourceTypes[];
}

const JSONPreviewHighlight = ({
  fileUrl,
  open,
  onClose,
  fileName,
  textToHighlight,
  setSelectedSource,
  listOfSourcesForSelectedSources,
  selectedSource,
}: JSONPreviewHighlightProps) => {
  const [loading, setLoading] = useState(true);
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
  const [jsonData, setJsonData] = useState<any>(null);
  const highlightRef = useRef<HTMLSpanElement | null>(null);

  const loadJsonData = useCallback(() => {
    setLoading(true);
    fetch(fileUrl)
      .then((response) => response.json())
      .then((data) => {
        setJsonData(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [fileUrl]);

  useEffect(() => {
    loadJsonData();
  }, [loadJsonData]);

  // useEffect(() => {
  //   if (!loading && highlightRef.current) {
  //     highlightRef.current.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'center',
  //     });
  //   }
  // }, [loading]);

  // const highlight = (text: string, highlight: string) => {
  //   if (!highlight) return text;

  //   const regex = new RegExp(
  //     `(${highlight.toString().replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`,
  //     'gi'
  //   );
  //   const parts = text.toString().split(regex);

  //   return parts.map((part, index) =>
  //     regex.test(part) ? (
  //       <b key={index} className="text-red-500 highlight" ref={highlightRef}>
  //         {part}
  //       </b>
  //     ) : (
  //       part
  //     )
  //   );
  // };

  const highlight = (text: string, highlight: string) => {
    if (!highlight) return text;

    const regex = new RegExp(`(${highlight})`, "gi");

    return text.replace(regex, '<b class="text-red-500 highlight">$1</b>');
  };


  const jsonDataNotArray = !Array.isArray(jsonData)
  
  const jsonString = JSON.stringify(jsonData, null, 2);
  // const highlightedJson = highlight(jsonString, textToHighlight);

  const findindex = useMemo(() => {
    if (listOfSourcesForSelectedSources) {
      return (
        listOfSourcesForSelectedSources.findIndex(
          (d) => d.source_number === selectedSource?.source_number
        ) + 1
      );
    }
    return 0;
  }, [listOfSourcesForSelectedSources, selectedSource]);

  const isNextAvailable = useMemo(() => {
    if (listOfSourcesForSelectedSources) {
      const findindex = listOfSourcesForSelectedSources.findIndex(
        (d) => d.source_number === selectedSource?.source_number
      );

      return findindex < listOfSourcesForSelectedSources.length - 1;
    }
    return false;
  }, [listOfSourcesForSelectedSources, selectedSource]);
  const handleNext = useCallback(() => {
    if (listOfSourcesForSelectedSources && isNextAvailable) {
      const findindex = listOfSourcesForSelectedSources.findIndex(
        (d) => d.source_number === selectedSource?.source_number
      );
      setSelectedSource(listOfSourcesForSelectedSources[findindex + 1]);
    }
  }, [
    listOfSourcesForSelectedSources,
    setSelectedSource,
    isNextAvailable,
    selectedSource,
  ]);

  const isPreviousAvailable = useMemo(() => {
    if (listOfSourcesForSelectedSources) {
      const findindex = listOfSourcesForSelectedSources.findIndex(
        (d) => d.source_number === selectedSource?.source_number
      );

      return findindex > 0;
    }
    return false;
  }, [listOfSourcesForSelectedSources, selectedSource]);
  const handlePrevious = useCallback(() => {
    if (listOfSourcesForSelectedSources && isPreviousAvailable) {
      const findindex = listOfSourcesForSelectedSources.findIndex(
        (d) => d.source_number === selectedSource?.source_number
      );
      setSelectedSource(listOfSourcesForSelectedSources[findindex - 1]);
    }
  }, [
    listOfSourcesForSelectedSources,
    setSelectedSource,
    isPreviousAvailable,
    selectedSource,
  ]);

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      classNames={{ wrapper: "z-[65]"}}
      className="max-h-[calc(100vh-32px)] max-w-[calc(100vw-200px)] !rounded-[8px] overflow-y-hidden !my-auto"
      size="full"
      style={{
        borderRadius: "8px !important",
      }}
    >
      <ModalContent className="flex flex-col">
        <ModalHeader className="border-b border-solid border-[#E4E4E7]">
          <div className="flex gap-3 items-center ">
            <div className="flex gap-1 items-center pr-3 border-r border-[#E4E4E7] border-solid">
              <Tooltip
                content="Previous Reference"
                classNames={{
                  content:
                    "bg-black bg-opacity-80 backdrop-blur-md text-white text-tiny",
                  arrow: "bg-black bg-opacity-80 backdrop-blur-md",
                }}
              >
                <div
                  className="select-none bg-white cursor-pointer border-[1px] h-5 w-5 flex items-center justify-center rounded-[50%] border-solid border-[#E4E4E7]"
                  onClick={handlePrevious}
                >
                  <ChevronLeft
                    color={isPreviousAvailable ? "#A1A1AA" : "#E4E4E7"}
                    size={16}
                  />
                </div>
              </Tooltip>

              <div className="text-[12px] leading-5 font-medium text-[#71717A] w-11 text-center">
                {findindex} of {listOfSourcesForSelectedSources?.length}
              </div>

              <Tooltip
                content="Next Reference"
                classNames={{
                  content:
                    "bg-black bg-opacity-80 backdrop-blur-md text-white text-tiny",
                  arrow: "bg-black bg-opacity-80 backdrop-blur-md",
                }}
              >
                <div
                  className="select-none bg-white cursor-pointer border-[1px] h-5 w-5 flex items-center justify-center rounded-[50%] border-solid border-[#E4E4E7]"
                  onClick={handleNext}
                >
                  <ChevronRight
                    color={isNextAvailable ? "#A1A1AA" : "#E4E4E7"}
                    size={16}
                  />
                </div>
              </Tooltip>
            </div>

            <p className="text-sm font-medium">{truncateText(fileName)}</p>
          </div>
        </ModalHeader>
        <ModalBody className="grow overflow-auto">
          {loading ? (
            <div className="flex">
              <LoadingSpinner className="mx-auto" />
            </div>
          ) : (
            <pre>
              <code className={jsonDataNotArray ? "bg-[#FFFFB2]" :undefined}>{jsonString}</code>
            </pre>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default JSONPreviewHighlight;
