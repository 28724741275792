import { SVGProps } from "react";

const ShadowExcalamationIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="240" height="240" viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M175.2 162.145C198.432 155.425 217.094 143.717 221.957 118.767C226.229 96.8598 221.981 69.5478 204.576 53.967C187.267 38.4678 159.638 37.647 139.675 48.207C118.93 59.2086 111.893 82.7046 90.1295 92.7414C80.0873 97.3556 69.0477 99.3757 58.0223 98.6166C48.2831 98.0166 38.0543 94.3974 28.7423 99.0006C6.23992 110.103 19.4255 142.172 31.8479 155.818C51.1919 177.073 69.5903 168.063 94.2479 165.418C121.92 162.466 147.586 170.146 175.2 162.145Z" fill="url(#paint0_linear_3478_10392)" />
      <path opacity="0.9" d="M175.2 162.145C198.432 155.425 217.094 143.717 221.957 118.767C226.229 96.8598 221.981 69.5478 204.576 53.967C187.267 38.4678 159.638 37.647 139.675 48.207C118.93 59.2086 111.893 82.7046 90.1295 92.7414C80.0873 97.3556 69.0477 99.3757 58.0223 98.6166C48.2831 98.0166 38.0543 94.3974 28.7423 99.0006C6.23992 110.103 19.4255 142.172 31.8479 155.818C51.1919 177.073 69.5903 168.063 94.2479 165.418C121.92 162.466 147.586 170.146 175.2 162.145Z" fill="white" />
      <path d="M120 205.229C171.4 205.229 213.067 202.796 213.067 199.795C213.067 196.794 171.4 194.361 120 194.361C68.6005 194.361 26.9329 196.794 26.9329 199.795C26.9329 202.796 68.6005 205.229 120 205.229Z" fill="#F5F5F5" />
      <g clipPath="url(#clip0_3478_10392)">
        <foreignObject x="22.2501" y="27.2313" width="196.501" height="185.539"><div style={{
              backdropFilter: 'blur(20.91px)',
              clipPath: 'url(#bgblur_1_3478_10392_clip_path)',
              height: '100%',
              width: '100%'
            }}></div></foreignObject><path data-figma-bg-blur-radius="41.8117" d="M108.654 77.8824C113.919 68.7628 127.082 68.7628 132.347 77.8824L173.084 148.44C178.349 157.559 171.767 168.959 161.237 168.959H79.7645C69.2341 168.959 62.6526 157.559 67.9178 148.44L108.654 77.8824Z" fill="white" stroke="#71717A" stroke-width="4" />
        <g filter="url(#filter1_d_3478_10392)">
          <rect width="40" height="4" rx="2" transform="matrix(4.37114e-08 1 1 -4.37114e-08 118.5 96.2676)" fill="#71717A" />
        </g>
        <g filter="url(#filter2_d_3478_10392)">
          <rect width="6" height="6" rx="3" transform="matrix(4.37114e-08 1 1 -4.37114e-08 117.5 144.268)" fill="#71717A" />
        </g>
      </g>
      <defs>
        <clipPath id="bgblur_1_3478_10392_clip_path" transform="translate(-22.2501 -27.2313)"><path d="M108.654 77.8824C113.919 68.7628 127.082 68.7628 132.347 77.8824L173.084 148.44C178.349 157.559 171.767 168.959 161.237 168.959H79.7645C69.2341 168.959 62.6526 157.559 67.9178 148.44L108.654 77.8824Z" />
        </clipPath><filter id="filter1_d_3478_10392" x="108.047" y="85.8147" width="24.9058" height="60.9058" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="5.22646" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3478_10392" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3478_10392" result="shape" />
        </filter>
        <filter id="filter2_d_3478_10392" x="107.047" y="133.815" width="26.9058" height="26.9058" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="5.22646" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3478_10392" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3478_10392" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_3478_10392" x1="120.016" y1="41.1582" x2="120.016" y2="169.582" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DD9AB0" />
          <stop offset="1" stopColor="#A982CF" />
        </linearGradient>
        <clipPath id="clip0_3478_10392">
          <rect width="120" height="107.143" fill="white" transform="translate(60.5 66.4297)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ShadowExcalamationIcon;