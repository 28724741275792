import { SVGProps } from "react";

const ZaniaInverseIcon = ({ fill, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="black" />
      <g filter="url(#filter0_d_3161_58673)">
        <path d="M11.7589 8.58649C11.899 8.37827 12.1632 8.32165 12.3735 8.44527C13.0949 8.8724 14.7572 9.77069 16.2552 9.96048C17.8458 10.1625 20.8341 9.68168 21.9481 9.48425C22.11 9.45534 22.2263 9.65288 22.1349 9.79995L19.1439 14.5965C19.037 14.7682 18.8839 14.8985 18.7008 14.96C17.9752 15.2016 15.9226 15.7842 13.6496 15.5302C11.5383 15.2946 9.64267 14.3528 8.80962 13.8864C8.56707 13.7502 8.50111 13.4156 8.66085 13.1791L11.7589 8.58649ZM20.179 23.4155C20.0447 23.6149 19.7953 23.6762 19.5898 23.5662C18.8138 23.153 16.9182 22.2121 15.383 22.0175C13.8123 21.8182 11.076 22.2877 10.0113 22.4903C9.84864 22.521 9.72968 22.3218 9.82187 22.1738L12.7965 17.4028C12.9108 17.2205 13.0758 17.0847 13.2719 17.0261C14.0682 16.7872 16.3318 16.2007 18.6152 16.4561C20.7144 16.6904 22.4304 17.6213 23.1808 18.0896C23.4081 18.2307 23.4614 18.5494 23.3082 18.777L20.179 23.4155Z" fill="black" />
      </g>
      <path d="M11.7589 8.58649C11.899 8.37827 12.1632 8.32165 12.3735 8.44527C13.0949 8.8724 14.7572 9.77069 16.2552 9.96048C17.8458 10.1625 20.8341 9.68168 21.9481 9.48425C22.11 9.45534 22.2263 9.65288 22.1349 9.79995L19.1439 14.5965C19.037 14.7682 18.8839 14.8985 18.7008 14.96C17.9752 15.2016 15.9226 15.7842 13.6496 15.5302C11.5383 15.2946 9.64267 14.3528 8.80962 13.8864C8.56707 13.7502 8.50111 13.4156 8.66085 13.1791L11.7589 8.58649ZM20.179 23.4155C20.0447 23.6149 19.7953 23.6762 19.5898 23.5662C18.8138 23.153 16.9182 22.2121 15.383 22.0175C13.8123 21.8182 11.076 22.2877 10.0113 22.4903C9.84864 22.521 9.72968 22.3218 9.82187 22.1738L12.7965 17.4028C12.9108 17.2205 13.0758 17.0847 13.2719 17.0261C14.0682 16.7872 16.3318 16.2007 18.6152 16.4561C20.7144 16.6904 22.4304 17.6213 23.1808 18.0896C23.4081 18.2307 23.4614 18.5494 23.3082 18.777L20.179 23.4155Z" fill="white" />
      <defs>
        <filter id="filter0_d_3161_58673" x="6.42422" y="7.31037" width="19.1203" height="19.5397" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1.08" />
          <feGaussianBlur stdDeviation="1.075" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3161_58673" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3161_58673" result="shape" />
        </filter>
      </defs>
    </svg>

  );
};

export default ZaniaInverseIcon;
