import {
  createSelectorFunctions,
  createSelectorHooks,
} from "auto-zustand-selectors-hook";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { AiResponseType, UserResponseTypesInterface } from "../types/user";

export const allowedEmails = [
  "sahil@zania.ai",
  "harsh+1@zania.ai",
  "aalem@zania.ai",
  "shruti@zania.ai",
];

interface DemoState {
  isDemoActive: boolean;
  isUserAllowed: boolean;
}

interface UserState {
  demo: DemoState;
  aiResponseType: AiResponseType;
  isSlackConnected: boolean;
  userResponseTypes: UserResponseTypesInterface
}

const initialState: UserState = {
  demo: {
    isDemoActive: false,
    isUserAllowed: false,
  },
  // Env Condition
  aiResponseType: AiResponseType.NONE,
  isSlackConnected: false,
  userResponseTypes: []
};

interface UserActions {
  setDemoActive: (isActive: boolean) => void;
  setUserAllowed: (isAllowed: boolean) => void;
  setAIResponseType: (aiResponseType: AiResponseType) => void;
  setUserResponseTypes: (aiResponseType: []) => void;
  setSlackConnected: (isConnected: boolean) => void;
}

type UserStore = UserState & UserActions;

export const useUserStore = create<UserStore>()(
  persist(
    (set) => ({
      ...initialState,
      setAIResponseType: (aiResponseType) => set({ aiResponseType }),
      setSlackConnected: (isSlackConnected) => set({ isSlackConnected }),
      setDemoActive: (isDemoActive) =>
        set((state) => ({
          demo: {
            ...state.demo,
            isDemoActive,
          },
        })),
      setUserAllowed: (isUserAllowed) =>
        set((state) => ({
          demo: {
            ...state.demo,
            isUserAllowed,
          },
        })),
        setUserResponseTypes: (userResponseTypes) => set({ userResponseTypes }),
    }),
    {
      name: "user-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export const userStateSelector = createSelectorFunctions(useUserStore);

export const useUserStateSelector = createSelectorHooks(useUserStore);

export const useIsDemoActive = () =>
  useUserStateSelector.useDemo().isDemoActive;
