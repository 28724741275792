import { useAgentLogState } from "@/modules/app/states/agentLog";
import {
  type AgentSession,
  AgentSessionStepType,
  type DoraAgentSession,
  FairAgentSession,
  type MultiVendorAgentSession,
  type QaAgentSession,
  type RiskAgentSession,
  type SOC2GapAgentSession,
} from "@/modules/sessions/types";
import {
  handleContinueDoraGapAssessment,
  handleContinueFairAssessment,
  handleContinueMultiVendorAssessment,
  handleContinueQuestionnaire,
  handleContinueRiskAssessment,
  handleContinueSoc2GapAssessment,
} from "@/modules/sessions/use-cases/agent-session.use-case";
import ZBreadCrumbs from "@/shared/components/custom/breadcrumbs";
import { ROUTES } from "@/shared/constants/routes";
import { addNotification } from "@/shared/states/notification";
import { Button } from "@nextui-org/react";
import Lottie from "lottie-react";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SuccessAnimation from "../../../../shared/lottie/success.json";
import { helper } from "../../constants/helpers";
import { getAgentData } from "../../states";
import { getAgentTypeFromId } from "../../states";
import { AGENT_TYPES, RiskAssessmentTypes } from "../../types/index.ts";
import { getFileNameForDownloadReport } from "../../utils/downloadReport.ts";

const getFileName = (agentType: AGENT_TYPES) => {
  switch (agentType) {
    case AGENT_TYPES.QUESTIONNAIRE:
      return "questionnaire";
    case AGENT_TYPES.GAP_ASSESSMENT:
      return "gap_assessment";
    default:
      return "risk_assessment";
  }
};

const AgentSuccess = () => {
  const { id } = useParams<{ id: string }>();

  const { agentType, agentSubType } = getAgentTypeFromId(id || "");
  const title = helper[agentSubType]?.title || "";

  const setOpenAgentLog = useAgentLogState.useActions().setIsOpen;
  const [downloading, setDownloading] = useState(false);

  const { excelUrl, sessionName } = useMemo(() => {
    if (!id)
      return {
        excelUrl: "",
        sessionName: "",
      };
    const agentData = getAgentData(id);
    const finalStep = agentData?.stepData?.find(
      (step) => step.type === AgentSessionStepType.PREPARE_REPORT,
    );

    return {
      excelUrl: finalStep?.data?.url || "",
      sessionName: agentData?.sessionData.name || "",
    };
  }, [id]);

  const handleDownload = async () => {
    if (!excelUrl || !id) {
      addNotification({
        message: "Error in processing the file",
        type: "error",
      });
      return;
    }
    const agentData = getAgentData(id);

    try {
      setDownloading(true);
      const response = await fetch(excelUrl);
      const fileBlob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = getFileNameForDownloadReport(agentData?.sessionData as AgentSession);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error(error);
      addNotification({
        message: "Error in downloading the file",
        type: "error",
      });
    } finally {
      setDownloading(false);
    }
  };

  const navigate = useNavigate();

  const handleViewSession = async () => {
    if (!id) return;

    const agentData = getAgentData(id);
    if (!agentData) return;

    const { agentType, sessionData, stepData } = agentData;

    try {
      switch (agentType) {
        case AGENT_TYPES.RISK_ASSESSMENT:
          if(agentSubType === RiskAssessmentTypes.FAIR) {
            await handleContinueFairAssessment({
              session: sessionData as FairAgentSession,
              steps: stepData,
            });
          } else {
            await handleContinueRiskAssessment({
              session: sessionData as RiskAgentSession,
              steps: stepData,
            });
          }
          break;
        case AGENT_TYPES.QUESTIONNAIRE:
          await handleContinueQuestionnaire({
            session: sessionData as QaAgentSession,
            steps: stepData,
          });
          break;
        case AGENT_TYPES.GAP_ASSESSMENT:
          if ((sessionData as SOC2GapAgentSession).soc2_type2_audit) {
            await handleContinueSoc2GapAssessment({
              session: sessionData as SOC2GapAgentSession,
              steps: stepData,
            });
          } else if ((sessionData as DoraAgentSession).dora_assessment) {
            await handleContinueDoraGapAssessment({
              session: sessionData as DoraAgentSession,
              steps: stepData,
            });
          }
          break;
        case AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT:
          await handleContinueMultiVendorAssessment({
            session: sessionData as MultiVendorAgentSession,
            steps: stepData,
          });
          break;
        default:
          throw new Error("Invalid session type");
      }
      navigate(`/${ROUTES.AGENT}/${id}`);
    } catch (error) {
      console.error(error);
      addNotification({
        type: "error",
        title: "Error",
        message: "Failed to view session. Please try again.",
      });
    }
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex items-center pb-4">
        <ZBreadCrumbs
          items={[
            {
              title: "AI Agent",
              href: `/${ROUTES.AGENT}`,
            },
            {
              title: title,
              href: "",
              description: sessionName,
            },
          ]}
        />
      </div>
      <div className="w-full h-full bg-white flex items-center px-24 gap-32 rounded-lg">
        <div className="grow w-full flex flex-col items-start justify-center gap-4">
          <h4 className="text-4xl font-semibold leading-[60px]">
            Session Complete
          </h4>
          <h6 className="text-md text-[#71717A]">
            {`Your session `}
            <span className="font-bold text-black">{sessionName}</span>
            {` has been concluded. You can continue to access it in view only mode and download the result.`}
          </h6>
          <div className="w-full mt-8 flex flex-col gap-3">
            <Button
              color="primary"
              className="w-full rounded-lg"
              onClick={() => void handleDownload()}
              isLoading={downloading}
            >
              Download Result
            </Button>
            <div className="w-full flex flex-row gap-3">
              <Button
                color="primary"
                variant="bordered"
                className="w-full  border-[1px] rounded-lg"
                onClick={() => void handleViewSession()}
              >
                View Session
              </Button>
              <Button
                color="primary"
                variant="bordered"
                className="w-full  border-[1px] rounded-lg"
                onClick={() => {
                  navigate(`/${ROUTES.AGENT}`);
                }}
              >
                AI Agent Home
              </Button>
            </div>
          </div>
        </div>
        <div className="grow w-full flex flex-col items-start justify-center gap-4">
          <Lottie animationData={SuccessAnimation} loop={false} />
        </div>
      </div>
    </div>
  );
};

export default AgentSuccess;
