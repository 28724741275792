import { get } from "@/infra/rest";
import parsePromise from "@/shared/utils/parsePromise";
import { ChatHistoryApiResponse, MessageListApiResponse, SessionObject } from "../types";

export const getSessionList = () => {
  return parsePromise(get<{data: SessionObject[]}>({
    url: "/v0/agents/sessions/list"
  }));
};

export const getHistoryList = () => {
  return parsePromise(get<ChatHistoryApiResponse>({
    url: "/v0/ask-ai/threads?page_number=1&page_size=1000"
  }));
}

export const getChatHistoryMessages = (threadId: string) => {
  return parsePromise(get<MessageListApiResponse>({
    url: `/v0/ask-ai/thread/${threadId}/messages`
  }));
}
