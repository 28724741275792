/* eslint-disable @typescript-eslint/no-unsafe-call */
import { ZTableColumnProps } from "@/shared/components/table";

import { ZButton } from "@/shared/components/button";
import { ZTableDeprecrated } from "@/shared/components/table-deprecrated/TableDeprecrated";
import capitalizeFirstLetter from "@/shared/utils/capitalize-first-letter";
import { cn } from "@/shared/utils/classname-merger";
import { cloneDeep } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { RenderCellTypeEnum, tableConfigs } from "../../../constants/helpers";
import {
  AGENT_TYPES,
  AgentSpecificSubTypes,
  AgentSpecificTableRowData,
  ConfidenceTypes,
  MultiVendorAssessmentTypes,
  PDFReviewSource,
  ReviewSourceTypes,
  ExcelReviewSource
} from "../../../types/index.ts";
import { renderTableComponents } from "../../table-components";
import { useVendorRiskAssessment } from "../useVendorRiskAssessment/useVendorRiskAssessment";
import { useVendorRiskAssessmentStore } from "../useVendorRiskAssessment/useVendorRiskAssessmentStore";
import AddSubServicesPopup from "./AddSubServicesPopup";

import { useAgentSelector, useAgentStepData } from "@/modules/agent/states";
import { VENDOR_SUPPLY_CHAIN_SUBSERVICES, VendorRiskResponseItemForVendorSupplyChain, VendorSupplyChainInfrastructureItem, VendorSupplyChainSoftwareItem } from "@/modules/agent/types/vendorSupplyChain.ts";
import { AgentSessionStatus, AgentSessionStepType } from "@/modules/sessions/types";
import { addNotification } from "@/shared/states/notification";
import { useParams } from "react-router-dom";
import { IdParams } from "../Overview/Overview";
import InlineSources from "@/modules/agent/components/review-responses/InlineSources";
import PDFViewer from "@/shared/components/custom/pdf-preview";
import ImageViewer from "@/shared/components/custom/image-viewer";
import JsonPreview from "@/modules/agent/components/json-preview";
import ExcelPreview from "@/modules/agent/components/excel-preview";
import { checkAndRefreshUrl } from "@/modules/agent/utils/refreshUrl";

type AddServicesPopupMode =
  | { mode: "CLOSED" }
  | { mode: "ADD" }
  | {
    mode: "EDIT",
    subserviceType: typeof VENDOR_SUPPLY_CHAIN_SUBSERVICES[keyof typeof VENDOR_SUPPLY_CHAIN_SUBSERVICES],
    indexInSubserviceArray: number,
  }

const SupplyChain = () => {
  const { id } = useParams<keyof IdParams>() as IdParams;

  const [addServicesPopupMode, setAddServicesPopupMode] = useState<AddServicesPopupMode>({ mode: "CLOSED" });
  // Add state for selected source handling
  const [selectedSource, setSelectedSource] = useState<ReviewSourceTypes>();
  const [listOfSourcesForSelectedSources, setListOfSourcesForSelectedSources] = useState<ReviewSourceTypes[]>();

  const { vendorSupplyChainData } = useVendorRiskAssessment();
  const { setVendorSupplyChain } = useVendorRiskAssessmentStore();
  const stepData = useAgentStepData(id);
  const { setSelectedId } = useAgentSelector.useActions();

  const isReportReady = useMemo(() => {
    return stepData?.find((step) => step.type === AgentSessionStepType.PREPARE_REPORT)?.status === AgentSessionStatus.COMPLETE;
  }, [stepData]);

  const handleDelete = useCallback((item: VendorRiskResponseItemForVendorSupplyChain, indexInValueArray: number) => {
    if (isReportReady) {
      return;
    }

    const temp = cloneDeep(vendorSupplyChainData);
    const findkeyIndex = temp.findIndex((d) => d.key === item.key);
    if (findkeyIndex > -1) {
      temp[findkeyIndex].value.value.splice(indexInValueArray, 1);
      setSelectedId(id, temp[findkeyIndex].key);
    }
    setVendorSupplyChain(temp);
    addNotification({
      type: "success",
      message: "Subservice deleted successfully",
    });
  }, [isReportReady, setVendorSupplyChain, vendorSupplyChainData, id, setSelectedId]);

  const handleEdit = useCallback((item: VendorRiskResponseItemForVendorSupplyChain, indexInValueArray: number) => {
    if (isReportReady) {
      return;
    }

    setAddServicesPopupMode({
      mode: "EDIT",
      subserviceType: item.key,
      indexInSubserviceArray: indexInValueArray,
    });
  }, [isReportReady]);

  const rows = useMemo(() => {
    const newRowList: ISupplyChain[] = [];

    // biome-ignore lint/complexity/noForEach: <explanation>
    vendorSupplyChainData.forEach((categoryWiseSupplyChainItemList) => {
      categoryWiseSupplyChainItemList.value.value.forEach((supplyChainItem, idx) => {
        let subService: string;
        if (categoryWiseSupplyChainItemList.key === 'infrastructure') {
          subService = (supplyChainItem as VendorSupplyChainInfrastructureItem).infrastructure_provider;
        } else {
          subService = (supplyChainItem as VendorSupplyChainSoftwareItem).software;
        }

        // Prepare sources with required properties based on the debug data shown
        const preparedSources = (supplyChainItem.sources || []).map((src, index) => {
          // Create a base source with common properties
          const baseSource = {
            ...src,
            source_number: src.source_number || index + 1,
            file_type: src.file_type || '',
            file_url: src.file_url || '',
            source_text: src.source_text || '',
            source_file_name: src.source_file_name || src.file_name || '',
            file_name: src.file_name || src.source_file_name || '',
            is_inline_source: src.is_inline_source !== undefined ? src.is_inline_source : false
          };

          // For PDF files
          if (src.file_type === 'pdf' || src.file_type === 'application/pdf') {
            return {
              ...baseSource,
              page_number: (src as PDFReviewSource).page_number || 1,
              highlight_area: (src as PDFReviewSource).highlight_area || [],
              source_page_number: (src as PDFReviewSource).source_page_number || (src as PDFReviewSource).page_number || 1,
              source_highlight_area: (src as PDFReviewSource).source_highlight_area || (src as PDFReviewSource).highlight_area || []
            } as PDFReviewSource;
          }

          // For Excel files
          if (src.file_type === 'xlsx' || src.file_type === 'csv' || 
              src.file_type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return {
              ...baseSource,
              row_number: (src as ExcelReviewSource).row_number || null,
              sheet_name: (src as ExcelReviewSource).sheet_name || ''
            } as ExcelReviewSource;
          }

          // For JSON files
          if (src.file_type === 'json' || src.file_type === 'application/json') {
            return {
              ...baseSource,
              file_type: 'application/json' // Ensure consistent file type
            } as ReviewSourceTypes;
          }

          return baseSource as ReviewSourceTypes;
        });

        const rowItem = {
          id: `${categoryWiseSupplyChainItemList.key};${idx}`,
          confidence: supplyChainItem.confidence,
          purpose: supplyChainItem.purpose as string,
          sources: preparedSources,
          subServices: subService,
          type: capitalizeFirstLetter(categoryWiseSupplyChainItemList.key),
          handleDelete: () => handleDelete(categoryWiseSupplyChainItemList, idx),
          handleEdit: () => handleEdit(categoryWiseSupplyChainItemList, idx),
          isReportReady,
        } as ISupplyChain;

        newRowList.push(rowItem);
      });
    });

    return newRowList;
  }, [vendorSupplyChainData, handleDelete, handleEdit, isReportReady]);

  const tableColumns: ZTableColumnProps<
    AgentSpecificTableRowData<
      AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
      MultiVendorAssessmentTypes.VENDOR_SUPPLY_CHAIN_TABLE
    >
  >[] = useMemo(() => {
    return tableConfigs[MultiVendorAssessmentTypes.VENDOR_SUPPLY_CHAIN_TABLE].map((column) => ({
      ...column,
      renderCell: (data) => {
        // Add special handling for the source column
        if (column.fieldName === "source") {
          return (
            <div style={{ position: "relative" }}>
              <InlineSources
                sources={data.sources}
                onSourceClick={async (source) => {
                  console.log("Source clicked:", source);
                  // If source has a file_url, refresh it before setting
                  if (source.file_url) {
                    try {
                      // Get a fresh URL if needed
                      const refreshedUrl = await checkAndRefreshUrl(source.file_url, source.file_name);
                      // Create a new source object with the refreshed URL
                      // Keep all properties from the original source but update the URL
                      const refreshedSource = {
                        ...source,
                        file_url: refreshedUrl
                      };
                      
                      console.log("Using refreshed source:", refreshedSource);
                      setSelectedSource(refreshedSource);
                    } catch (error) {
                      console.error("Error refreshing URL:", error);
                      setSelectedSource(source);
                    }
                  } else {
                    setSelectedSource(source);
                  }
                  setListOfSourcesForSelectedSources(data.sources);
                }}
                setListOfSourcesForSelectedSources={(sources) => setListOfSourcesForSelectedSources(sources)}
                tooltipPlacement="top"
                position="relative"
              />
            </div>
          );
        }
        
        // For other columns use the default rendering
        return column.renderType === RenderCellTypeEnum.CUSTOM
          ? column.renderCell?.(data)
          : renderTableComponents({
              renderType: column.renderType,
              data,
              metaData: {
                approved: [],
                edited: [],
                agentId: "",
              },
              onClickAvatar: () => { },
              fieldName: column.fieldName,
            });
      },
    }));
  }, []);
  console.log(selectedSource);

  return (
    <div className="h-full w-full overflow-hidden flex flex-col pb-3 bg-white">
      {/* header */}
      <div className="flex flex-row justify-between p-6 pb-4">
        <div className="flex flex-row gap-2 items-center justify-center">
          <span className="text-sm font-medium">Subservices</span>
          <div className="p-1 bg-[#F4F4F5] px-2 rounded-lg">
            <p className="text-[10px] text-[#52525B] leading-4 font-medium">
              {rows.length}
            </p>
          </div>
        </div>
        {!isReportReady && (
          <div>
            <ZButton
              size="sm"
              color="primary"
              variant="bordered"
              onClick={() => setAddServicesPopupMode({ mode: "ADD" })}
              className="text-tiny p-0 px-2 h-7 rounded-md"
            >
              Add New
            </ZButton>
          </div>
        )}
      </div>

      {/* /table */}

      <div className="grow overflow-hidden flex">
        <div className="flex-1 flex flex-col bg-white overflow-auto px-6">
          <ZTableDeprecrated<ISupplyChain>
            className="border-1 border-[#E4E4E7] rounded-lg overflow-auto scroll-auto text-[#333333]"
            loading={false}
            rows={[...rows] as ISupplyChain[]}
            columns={tableColumns}
            isHeaderSticky={true}
            isCompact={true}
            removeWrapper={true}
            tableHeaderProps={{
              className: "shadow-none rounded-none",
            }}
            tableRowProps={(_, rowIndex) => {
              return {
                className: cn(
                  rowIndex === 0 ? "border-none" : "border-y",
                  "hover:bg-[#F2F2F2]",
                  "border-b-1 border-b-[#E4E4E7]",
                ),
                id: `table-row-${rowIndex}`,
              };
            }}
            classNames={{
              wrapper: "shadow-none rounded-none ",
            }}
            aria-label="Third-Party Supply Chain"
          />
        </div>
      </div>
      {addServicesPopupMode.mode === "ADD" && (
        <AddSubServicesPopup
          handleClose={() => setAddServicesPopupMode({ mode: "CLOSED" })}
          type="ADD"
        />
      )}
      {addServicesPopupMode.mode === "EDIT" && (
        <AddSubServicesPopup
          handleClose={() => setAddServicesPopupMode({ mode: "CLOSED" })}
          type="EDIT"
          editKey={addServicesPopupMode.subserviceType}
          editIdx={addServicesPopupMode.indexInSubserviceArray}
        />
      )}

      {/* PDF Viewer */}
      {selectedSource && selectedSource.file_type === 'application/pdf' && (
        <PDFViewer
          fileUrl={selectedSource.file_url || ''}
          isOpen={!!selectedSource}
          onClose={() => {setSelectedSource(undefined);setListOfSourcesForSelectedSources(undefined); }}
          headerTitle={selectedSource.original_file_name || selectedSource.source_file_name || ''}
          selectedSource={selectedSource}
          setSelectedSource={setSelectedSource}
          listOfSourcesForSelectedSources={listOfSourcesForSelectedSources}
          pageNumber={
            (selectedSource as PDFReviewSource).source_page_number ||
            (selectedSource as PDFReviewSource).page_number || 1
          }
          highlight={
            (selectedSource as PDFReviewSource).source_highlight_area ||
            (selectedSource as PDFReviewSource).highlight_area || []
          }
        />
      )}

      {/* Image Viewer */}
      {selectedSource && 
        (
          ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/svg+xml', 'image/gif'].includes(selectedSource.file_type) ||
          ['jpeg', 'jpg', 'png', 'webp', 'svg', 'gif'].includes(selectedSource.file_type)
        ) && (
        <ImageViewer
          fileUrl={selectedSource.file_url || ''}
          isOpen={!!selectedSource}
          onClose={() => setSelectedSource(undefined)}
          headerTitle={selectedSource.original_file_name || selectedSource.source_file_name || selectedSource.file_name || ''}
          selectedSource={selectedSource}
          setSelectedSource={setSelectedSource}
          listOfSourcesForSelectedSources={listOfSourcesForSelectedSources}
        />
      )}

      {/* Excel Viewer */}
      {selectedSource && 
        (
          ['text/csv', 'application/csv', 'application/vnd.msexcel', 'application/vnd.ms-excel', 'application/vnd.ms-excel.sheet.macroEnabled.12', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(selectedSource.file_type) ||
          ['xlsx', 'csv', 'xls'].includes(selectedSource.file_type)
        ) && (
        <ExcelPreview
          fileUrl={selectedSource.file_url || ''}
          open={!!selectedSource}
          onClose={() => setSelectedSource(undefined)}
          sheetName={(selectedSource as ExcelReviewSource).sheet_name || ''}
          fileName={selectedSource.original_file_name || selectedSource.source_file_name || selectedSource.file_name || ''}
          selectedSource={selectedSource}
          setSelectedSource={setSelectedSource}
          listOfSourcesForSelectedSources={listOfSourcesForSelectedSources}
          highlightRow={(selectedSource as ExcelReviewSource).row_number}
        />
      )}

      {/* JSON Viewer */}
      {selectedSource && (selectedSource.file_type === 'application/json' || selectedSource.file_type === 'json') && (
        <JsonPreview
          fileUrl={selectedSource.file_url || ''}
          open={!!selectedSource}
          onClose={() => setSelectedSource(undefined)}
          fileName={selectedSource.original_file_name || selectedSource.source_file_name || selectedSource.file_name || ''}
          textToHighlight={selectedSource.source_text || ''}
          selectedSource={selectedSource}
          setSelectedSource={setSelectedSource}
          listOfSourcesForSelectedSources={listOfSourcesForSelectedSources}
        />
      )}
    </div>
  );
};

export default SupplyChain;

export interface ISupplyChain {
  id: string;
  type: string;
  subServices: string;
  purpose: string;
  confidence: ConfidenceTypes;
  sources: ReviewSourceTypes[];

  // handler for the rows
  handleDelete: () => void;
  handleEdit: () => void;
  isReportReady: boolean;
}
