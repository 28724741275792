import { del, patch, post } from "@/infra/rest";
import parsePromise from "@/shared/utils/parsePromise";
import { InviteApiResponse, InviteTeamApiPayload, RevokeMemberApiResponse, UpdateTeamApiPayload } from "../types";

export const deleteTeamMember = (memberId: string) => {
  return parsePromise(del<RevokeMemberApiResponse>({
    url: `/v0/auth/stytch-member/${memberId}`,
  }));
};

export const updateTeamMember = (data: UpdateTeamApiPayload) => {
  return parsePromise(patch<any>({
    url: `/v0/auth/stytch-member`,
    config: {
      data: data
    }
  }));
};

export const inviteTeamMember = (data: InviteTeamApiPayload) => {
  return parsePromise(post<any, InviteApiResponse>({
    url: `/v0/auth/invite`,
    data: data
  }));
};
