import {
  AgentSessionStepType,
  AgentSessionType,
} from "@/modules/sessions/types";
import { Tab, Tabs } from "@nextui-org/react";
import clsx from "clsx";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { getAgentData } from "../../states";
import {
  getCustomizeControlsActions,
  TSCENUM,
  useCustomizeControlsStore,
} from "../../states/cusomize-controls";
import { AGENT_TYPES, MultiVendorAssessmentTypes } from "../../types";
import { convertSnakeToTitleCase } from "../../utils/snake-to-capital";

export const CustomizeControlsTabs = () => {
  const { id = "" } = useParams<{ id: string }>();

  const selectedTab = useCustomizeControlsStore().selectedTab;
  const agentType = useCustomizeControlsStore().agentType;

  const handleChange = useCallback((key: TSCENUM) => {
    const tableEl = document.getElementById("customize-control-table");
    if (tableEl) {
      tableEl.scrollTo({ top: -tableEl.scrollTop, behavior: "smooth" });
    }
    getCustomizeControlsActions().setSelectedTab(key);
  }, []);

  const setSelectedControls = useCustomizeControlsStore().actions
    .setSelectedControls;

  const options: { value: string; label: string }[] = useMemo(() => {
    switch (agentType) {
      case AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT: {
        const agentData = getAgentData<
          AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
          MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
        >(id);

        let vendorContainsAI = true;
        if (
          agentData?.stepData.find(
            (step) => step.type === AgentSessionStepType.MULTI_VENDOR_PROFILE
          )?.data.vendor_intake_form?.vendor_ai_ml_usage === "No"
        ) {
          vendorContainsAI = false;
        }

        const listOfOptions = [
          {
            value: "zania_security_assessment",
            label: "General Controls",
          },
        ];

        if (vendorContainsAI) {
          listOfOptions.push({
            label: "AI Controls",
            value: "ai_security_assessment",
          });
        }

        return listOfOptions;
      }
      case AgentSessionType.SOC2_GAP:
      case AgentSessionType.SOC2_GAP_TYPE1:
      case AgentSessionType.SOC2_GAP_TYPE2:
        return Object.values(TSCENUM).map((tsc) => {
          return {
            value: tsc,
            label:
              tsc === TSCENUM.SECURITY
                ? "Security / Common Criteria"
                : convertSnakeToTitleCase(tsc),
          };
        });
      default:
        return [];
    }
  }, [agentType, id]);

  return (
    <div className="bg-white w-full">
      <Tabs
        variant="underlined"
        selectedKey={selectedTab}
        classNames={{
          tab: "rounded-none px-6 ",
          base: "rounded-none ",
          cursor: "rounded-none ",
          panel: "rounded-none ",
          tabContent: "rounded-none ",
          tabList: "rounded-none  p-0",
          wrapper: "rounded-none ",
        }}
        className="bg-white w-full text-[12px] leading-5"
        onSelectionChange={(key) => {
          handleChange(key as TSCENUM);
          setSelectedControls(new Set());
        }}
      >
        {options.map((option) => (
          <Tab
            className={clsx("rounded-none text-[12px] leading-5", {
              'after:content-[""] after:absolute after:bottom-0 after:left-0 after:w-full after:h-full after:bg-gradient-to-t  after:from-[#E7F2FF] after:to-[#FFFFFF] after:border-b-2 after:border-b-[#006FEE]':
                selectedTab === option.value,
            })}
            key={option.value}
            title={option.label}
          />
        ))}
      </Tabs>
    </div>
  );
};

export default CustomizeControlsTabs;
