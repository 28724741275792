import ErrorBoundary from "@/shared/components/error-boundary";
import { AnimatePresence } from "framer-motion";
import { Outlet } from "react-router-dom";
import { useChatStore } from "../states";
import ChatInput from "./chat-input";
import Conversation from "./conversation";
import FeaturesCards from "./features-cards";
import { useCallback, useEffect, useMemo } from "react";
import { getSessionList } from "../requests";
import UseAskAiSocketEvents from "../hooks/useAskAiEvents";
import { getZaniaTeamMemberRoles } from "@/modules/team/states";
import { useLoggedInMember } from "@/modules/auth/states";
import { useNavigate } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import StaticHeader from "./static-header";
import FloatingAskAi from "./floating-chat";
import ChatHeader from "./chat-header";
import ChatHistory from "./ChatHistory";

export default function AskAi() {
  const { isOpen, setSessionList, threadId, isFloatingAskAiOpen, isShowHistory } = useChatStore();
  const loggedInMember = useLoggedInMember();
  const navigate = useNavigate();
  const MemoizedConversation = useMemo(() => <Conversation />, [isOpen]);
  const { enablePylonChat } = useFlags();

  const { createNewThread } = UseAskAiSocketEvents();

  const sessionList = useCallback(async () => {
    const [error, resp] = await getSessionList();
    if (resp?.data) {
      setSessionList(resp.data);
      return resp.data;
    }
    return resp;
  }, []);

  const createNewChat = () => {
    createNewThread();
  }

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        await sessionList();
      } catch (error) {
        console.error('Error fetching sessions:', error);
        return;
      }
    };

    void fetchSessions();
  }, [sessionList]);

  // Create new chat if threadId is not available
  useEffect(() => {
    if (!threadId)
      createNewChat();
  }, [threadId])

  useEffect(() => {
    const { canAccessZaniaAskAi } = loggedInMember ? getZaniaTeamMemberRoles("", loggedInMember) : { canAccessZaniaAskAi: false };
    if (!canAccessZaniaAskAi ||
      (enablePylonChat && loggedInMember && !enablePylonChat[loggedInMember.organization_id])
    ) {
      navigate('/');
    }
  }, [])

  return (
    <ErrorBoundary fallback={null}>
      {
        isFloatingAskAiOpen ? (
          <FloatingAskAi>
            {MemoizedConversation}
          </FloatingAskAi>
        )
          :
          <div className="w-full h-full flex flex-col">
            <div className="pt-4 px-6 flex h-full w-full max-w-full flex-col gap-5 bg-white rounded-xl pb-2">
              <ChatHeader />
              {isShowHistory ? <ChatHistory /> :
                <>
                  <AnimatePresence>
                    {/* load conversations after chat is initialized */}
                    {isOpen ? <>
                      {MemoizedConversation}
                    </> :
                      <>
                        {/* show feature cards by default*/}
                        <div className="flex h-full flex-col justify-end gap-10">
                          <StaticHeader />
                          <FeaturesCards className={`grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3`} />
                        </div>
                      </>
                    }
                  </AnimatePresence>
                  <ChatInput />
                </>
              }


            </div>
            <div className="grow overflow-hidden rounded-xl">
              <Outlet />
            </div>
          </div>
      }

    </ErrorBoundary>
  );
}
