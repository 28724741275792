import { AgentSubTypes, RiskAssessmentTypes } from "../types";

export const getTagAndRiskString = (data: string) => {
  const regex = /^([A-Z]{2}\.[A-Z]{2}-\d{2}): (.+)$/;
  const match = data ? data.match(regex) : false;
  if (match) {
    return {
      tag: match[1],
      riskString: match[2],
    };
  }
  return {
    tag: null,
    riskString: null,
  };
};

export const getTagForNistAIRMF = (data:string) => {
  switch(data.toLowerCase()){
    case "govern":
      return "GV"
    case "map":
      return "MP"
    case "measure":
      return "MS"
    case "manage":
      return "MG"
    default: 
      return data
  }
}

export const getTagColor = (tag: string, agentSubType?: AgentSubTypes) => {
  const type = tag.slice(0, 2);
  switch (type) {
    case "GV":
      if (agentSubType === RiskAssessmentTypes.NIST_AI_RMF){
        return  "bg-[#85C0E4]"
      }
      
      return "bg-[#F9F49D]";
    case "ID":
      return "bg-[#4BB2E0]";
    case "PR":
      return "bg-[#9292EA]";
    case "DE":
      return "bg-[#FAB746]";
    case "DT": // Default to DE (Detect)
      return "bg-[#FAB746]";
    case "RS":
      return "bg-[#F97367]";
    case "RP": // Default to RS (Respond)
      return "bg-[#F97367]";
    case "RC":
      return "bg-[#7DF49F]";
    case "MP":
      return "bg-[#96D4BF]";
    case "MS":
      return "bg-[#ECC37B]";
    case "MG":
      return "bg-[#839EBD]";
  }
};
