import { setAuth } from "@/modules/auth/states";
import requestConfig from "@/shared/config/request";
import { deleteCookie } from "@/shared/utils/cookie-helper";
import parsePromise from "@/shared/utils/parsePromise";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

enum Methods {
  POST = "POST",
  PUT = "PUT",
  GET = "GET",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

const axiosClient = axios.create({
  baseURL: requestConfig.baseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

interface CommonProps<D = any> {
  url: string;
  config?: AxiosRequestConfig<D>;
  useCookies?: boolean;
  isAuthRequired?: boolean;
}

export const makeRequest = async <T, D = any>({
  url,
  method,
  config,
  useCookies = true,
  isAuthRequired = true,
  retryCount = 0,
}: CommonProps<D> & {
  method: Methods;
  retryCount?: number;
}): Promise<T> => {
  if (retryCount >= 3) {
    throw new Error("Max retry attempts reached");
  }

  let token: string | undefined;

  const headers = isAuthRequired
    ? { ...config?.headers, Authorization: token, token }
    : config?.headers;

  let response: AxiosResponse<T> = {} as AxiosResponse<T>;

  try {
    response = await axiosClient.request<T>({
      url,
      method,
      // don't send cookies for signed_url api as it won't work
      ...(useCookies && { withCredentials: url.includes("signed_url") ? false : true }),
      ...config,
      headers,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(error?.message);
    }
    if (
      axios.isAxiosError(error) &&
      error.response?.status === 401
    ) {
      if (retryCount === 0) {
          // refresh token
          await parsePromise(makeRequest({
            url: "/v0/auth/refresh-token",
            method: Methods.POST,
            config: {},
            useCookies: true,
            isAuthRequired: true,
            retryCount: retryCount + 1,
          }));
        }        
      // delete the cookie if the token is expired as it can be a problem with the cookie
      if (retryCount >= 2) {
        deleteCookie("intermediate_session_token");
        setAuth({
          isAuthenticated: false,
          isAuthenticationInProgress: false,
          loggedInMember: null,
          loggedInOrganization: null
        })
      }
      if (retryCount > 0 && retryCount < 3) {
          return makeRequest({
            url,
            method,
            config,
            useCookies,
            isAuthRequired,
            retryCount: retryCount + 1,
          });
      }

    }
    throw error as Error;
  }

  return response.data;
};

export const get = async <T>({
  url,
  config,
  useCookies = true,
  isAuthRequired = true,
}: CommonProps): Promise<T> =>
  makeRequest<T>({
    url,
    method: Methods.GET,
    config,
    useCookies,
    isAuthRequired,
  });

export const post = async <D, T>({
  url,
  data,
  config,
  useCookies = true,
  isAuthRequired = true,
}: CommonProps<D> & {
  data: D;
}): Promise<T> =>
  makeRequest<T>({
    url,
    method: Methods.POST,
    config: { data, ...config },
    useCookies,
    isAuthRequired,
  });

export const put = async <D, T>({
  url,
  data,
  config,
  useCookies = true,
  isAuthRequired = true,
}: CommonProps<D> & {
  data: D;
}): Promise<T> =>
  makeRequest<T>({
    url,
    method: Methods.PUT,
    config: { data, ...config },
    useCookies,
    isAuthRequired,
  });

export const del = async <T>({
  url,
  config,
  useCookies = true,
  isAuthRequired = true,
}: CommonProps): Promise<T> =>
  makeRequest<T>({
    url,
    method: Methods.DELETE,
    config: { ...config },
    useCookies,
    isAuthRequired,
  });

export const patch = async <T>({
  url,
  config,
  useCookies = true,
  isAuthRequired = true,
}: CommonProps): Promise<T> =>
  makeRequest<T>({
    url,
    method: Methods.PATCH,
    config,
    useCookies,
    isAuthRequired,
  });

export const request = {
  get,
  post,
  put,
  del,
  patch,
};
