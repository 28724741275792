import {
  Card,
  CardBody,
  CardHeader,
  Chip,
  InputSlots,
  Select,
  SelectItem,
  SelectSlots,
  SlotsToClasses,
  Textarea,
} from "@nextui-org/react";
import { SquarePen } from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import ComplianceAdherenceEdit from "./ComplianceAdherenceEdit";
import ComplianceAdherenceView from "./ComplianceAdherenceView";
import {
  OverviewCardPropsForCompliance,
  OverviewCardPropsForDropDown,
  OverviewCardPropsForDropDownAndTextArea,
  OverviewCardPropsForIncidents,
  OverviewCardPropsForRiskAndJustification,
  OverviewCardPropsForTextArea,
} from "./OverviewCards.types";
import RiskLevelChip from "./RiskLevelChip";
import { withControlTracking } from "./withControlTracking";
import debounce from "@/shared/utils/debounce";

export const RiskAndJustitificationCard = withControlTracking(({
  ...props
}: OverviewCardPropsForRiskAndJustification & { enhancedHeader: React.ReactNode }) => {
  return (
    <Card className="w-full p-4 bg-[#FAFAFA] relative group shadow-none hover:shadow-small focus-within:shadow-small rounded-lg">
      <CardHeader className="text-sm font-medium p-0 pb-2 flex justify-between">
        {props.enhancedHeader}
      </CardHeader>
      <CardBody className="p-0">
        <Select
          aria-label={props.title}
          placeholder="Select Option"
          selectedKeys={new Set([props.riskLevel])}
          onChange={(e) =>
            props.updateRiskLevel(e.target.value as "High" | "Medium" | "Low")
          }
          selectionMode={"single"}
          className="w-36 p-0 pb-4 bg-transparent bg-[#FAFAFA]"
          classNames={riskSelectFieldClassNames}
          renderValue={() => <RiskLevelChip riskLevel={props.riskLevel} />}
          isDisabled={props.isDisabled}
        >
          <SelectItem 
            key={"High"} 
            value={"High"} 
            textValue="High Risk"
            isDisabled={props.riskLevel === "High"}
          >
            <RiskLevelChip riskLevel="High" />
          </SelectItem>
          <SelectItem 
            key={"Medium"} 
            value={"Medium"} 
            textValue="Medium Risk"
            isDisabled={props.riskLevel === "Medium"}
          >
            <RiskLevelChip riskLevel="Medium" />
          </SelectItem>
          <SelectItem 
            key={"Low"} 
            value={"Low"} 
            textValue="Low Risk"
            isDisabled={props.riskLevel === "Low"}
          >
            <RiskLevelChip riskLevel="Low" />
          </SelectItem>
        </Select>

        <Textarea
          aria-label={props.title}
          value={props.textAreaValue}
          variant="flat"
          className="h-full shadow-none border-none disabled:pointer-events-none disabled:cursor-default p-0 text-[#717179]"
          classNames={textAreaClassNames}
          style={{
            border: "0px",
            boxShadow: "unset",
            color: "#333333",
          }}
          onChange={(e) => props.handleTextAreaValueChange(e.target.value)}
          disabled={props.isDisabled}
          placeholder="Add Justification"
        />
      </CardBody>
    </Card>
  );
});

export const TextAreaCard = withControlTracking(({ enhancedHeader, ...props }: OverviewCardPropsForTextArea & { enhancedHeader: React.ReactNode }) => {
  return (
    <Card className="w-full p-4 bg-[#FAFAFA] relative group shadow-none hover:shadow-small focus-within:shadow-small rounded-lg">
      <CardHeader className="text-sm font-medium p-0 pb-2">
        {enhancedHeader}
      </CardHeader>
      <CardBody className="p-0">
        <Textarea
          aria-label={props.title}
          value={props.textAreaValue}
          variant="flat"
          className="h-full shadow-none border-none disabled:pointer-events-none disabled:cursor-default p-0 text-[#717179]"
          classNames={textAreaClassNames}
          style={{
            border: "0px",
            boxShadow: "unset",
            color: "#333333",
          }}
          onChange={(e) => props.handleTextAreaValueChange(e.target.value)}
          disabled={props.isDisabled}
          placeholder={`Add ${props.title}`}
        />
      </CardBody>
    </Card>
  );
});

export const DropDownAndTextAreaCard = withControlTracking(({
  ...props
}: OverviewCardPropsForDropDownAndTextArea & { enhancedHeader: React.ReactNode }) => {
  const [otherValue, setOtherValue] = useState(props.otherValues || []);

  useEffect(() => {
    setOtherValue(props.otherValues || []);
  }, [props.otherValues]);

  const debouncedHandleOtherValueChange = useMemo(
    () =>
      debounce((value: string) => {
        props.handleOtherValueChange?.([value]);
      }, 500),
    [props.handleOtherValueChange]
  );

  const handleOtherValueChange = (value: string) => {
    setOtherValue([value]);
    debouncedHandleOtherValueChange(value);
  };

  const handleDropDownChange = (values: string[]) => {
    if (!values.includes("Other")) {
      setOtherValue([]);
      props.handleOtherValueChange?.([]);
    }
    props.handleDropDownChanges(values);
  };

  return (
    <Card className="w-full p-4 bg-[#FAFAFA] relative group shadow-none hover:shadow-small focus-within:shadow-small rounded-lg">
      <CardHeader className="text-sm font-medium p-0 pb-2">
        {props.enhancedHeader}
      </CardHeader>
      <CardBody className="p-0">
        <Select
          className="p-0 pb-4 border-none shadow-none"
          aria-label={props.title}
          placeholder="Select all applicable options"
          selectedKeys={new Set(props.dropdownValues)}
          onChange={(e) => {
            handleDropDownChange(e.target.value.split(","));
          }}
          selectionMode={props.selectionMode}
          variant="underlined"
          isDisabled={props.isDisabled}
        >
          {props.dropDownOptions.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </Select>

        {props.dropdownValues.includes("Other") && (
          <Textarea
            aria-label="Other data types"
            value={otherValue[0]}
            variant="flat"
            placeholder="Specify other data types"
            minRows={2}
            classNames={{
              inputWrapper:
                "w-full shadow-none rounded-md border-1 border-gray-300 bg-white mb-4",
            }}
            onChange={(e) => handleOtherValueChange(e.target.value)}
            disabled={props.isDisabled}
          />
        )}

        <Textarea
          aria-label={props.title}
          value={props.textAreaValue}
          variant="flat"
          className="h-full shadow-none border-none disabled:pointer-events-none disabled:cursor-default p-0 text-[#717179]"
          classNames={textAreaClassNames}
          style={{
            border: "0px",
            boxShadow: "unset",
            color: "#333333",
          }}
          onChange={(e) => props.handleTextAreaValueChange(e.target.value)}
          disabled={props.isDisabled}
          placeholder="Add notes"
        />
      </CardBody>
    </Card>
  );
});

export const DropDownCard = withControlTracking(({ onFocus, ...props }: OverviewCardPropsForDropDown & { 
  enhancedHeader: React.ReactNode,
  onFocus: () => void 
}) => {
  return (
    <Card className="w-full p-4 bg-[#FAFAFA] relative group shadow-none hover:shadow-small focus-within:shadow-small rounded-lg">
      <CardHeader className="text-sm font-medium p-0 pb-2">
        {props.enhancedHeader}
      </CardHeader>
      <CardBody className="p-0">
        <Select
          className="p-0 border-none shadow-none"
          aria-label={props.title}
          placeholder="Select Option"
          selectedKeys={new Set(props.dropdownValues)}
          onChange={(e) => {
            onFocus();
            props.handleDropDownChanges(e.target.value.split(","));
          }}
          onFocus={onFocus}
          selectionMode={props.selectionMode}
          variant="underlined"
          disabled={props.isDisabled}
        >
          {props.dropDownOptions.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </Select>
      </CardBody>
    </Card>
  );
});

export const ComplianceAdherenceCard = withControlTracking(({
  ...props
}: OverviewCardPropsForCompliance & { enhancedHeader: React.ReactNode }) => {
  const [complianceAdherenceMode, setComplianceAdherenceMode] = useState<
    "VIEW" | "EDIT"
  >("VIEW");

  const parentRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  // useEffect(() => {
  //   if (complianceAdherenceMode === 'EDIT'){

  //     const mouseClickHandler = (mouseEvent:MouseEvent) =>{
  //       if(parentRef.current){
  //         const verticalRange = [parentRef.current.offsetTop, parentRef.current.offsetTop + parentRef.current.clientHeight]
  //         const horizontalRange = [parentRef.current.offsetLeft, parentRef.current.offsetLeft + parentRef.current.clientWidth]
  //         if(!(verticalRange[0]<mouseEvent.clientY && verticalRange[1]>mouseEvent.clientY && horizontalRange[0]<mouseEvent.clientX && horizontalRange[1] > mouseEvent.clientX)){
  //           setComplianceAdherenceMode('VIEW')
  //         }
  //       }
  //     }

  //     window.addEventListener('click', mouseClickHandler)

  //     return () => {
  //       window.removeEventListener('click', mouseClickHandler)
  //     }
  //   }
  // },[complianceAdherenceMode])

  return (
    <div ref={parentRef} id="compliance-adherence-card">
      <Card className="w-full p-4 bg-[#FAFAFA] relative group shadow-none hover:shadow-small focus-within:shadow-small rounded-lg">
        <CardHeader className="text-sm font-medium p-0 pb-2">
          {props.enhancedHeader}
          {complianceAdherenceMode === "VIEW" &&
            (props.isDisabled ? null : (
              <button
                ref={buttonRef}
                onClick={() => setComplianceAdherenceMode("EDIT")}
                className="invisible group-hover:visible transition-all duration-300 ease-in-out cursor-pointer group-hover:cursor-pointer z-10 pointer-events-auto"
              >
                <SquarePen color="#006FEE" className="h-5 w-5" />
              </button>
            ))}
        </CardHeader>
        <CardBody className="p-0">
          {complianceAdherenceMode === "EDIT" ? (
            <ComplianceAdherenceEdit {...props} />
          ) : (
            <ComplianceAdherenceView {...props} />
          )}
        </CardBody>
      </Card>
    </div>
  );
});

const textAreaClassNames: SlotsToClasses<InputSlots> = {
  base:
    "shadow-none border-none bg-[#FAFAFA] disabled:pointer-events-none disabled:cursor-default p-0 text-[#717179]",
  clearButton:
    "shadow-none border-none bg-[#FAFAFA] hover:bg-[#FAFAFA] disabled:pointer-events-none disabled:cursor-default p-0 text-[#717179] ",
  description:
    "shadow-none border-none bg-[#FAFAFA] hover:bg-[#FAFAFA] disabled:pointer-events-none disabled:cursor-default p-0 text-[#717179]",
  errorMessage:
    "shadow-none border-none bg-[#FAFAFA] hover:bg-[#FAFAFA] disabled:pointer-events-none disabled:cursor-default p-0 text-[#717179]",
  innerWrapper:
    "shadow-none border-none bg-[#FAFAFA] hover:bg-[#FAFAFA] disabled:pointer-events-none disabled:cursor-default p-0 text-[#717179]",
  input:
    "shadow-none border-none bg-[#FAFAFA] hover:bg-[#FAFAFA] disabled:pointer-events-none disabled:cursor-default p-0 text-[#717179]",
  helperWrapper:
    "shadow-none border-none bg-[#FAFAFA] hover:bg-[#FAFAFA] disabled:pointer-events-none disabled:cursor-default p-0 text-[#717179]",
  inputWrapper:
    "shadow-none border-none bg-[#FAFAFA] hover:bg-[#FAFAFA] disabled:pointer-events-none disabled:cursor-default p-0 text-[#717179]",
  label:
    "shadow-none border-none bg-[#FAFAFA] hover:bg-[#FAFAFA] disabled:pointer-events-none disabled:cursor-default p-0 text-[#717179]",
  mainWrapper:
    "shadow-none border-none bg-[#FAFAFA] hover:bg-[#FAFAFA] disabled:pointer-events-none disabled:cursor-default p-0 text-[#717179]",
};

const riskSelectFieldClassNames: SlotsToClasses<SelectSlots> = {
  base: "bg-[#FAFAFA]",
  mainWrapper: "bg-[#FAFAFA]",
  description: "bg-[#FAFAFA]",
  errorMessage: "bg-[#FAFAFA]",
  helperWrapper: "bg-[#FAFAFA]",
  innerWrapper: "bg-[#FAFAFA] h-6 min-h-6",
  label: "bg-[#FAFAFA]",
  listbox: "bg-[#FAFAFA]",
  listboxWrapper: "bg-[#FAFAFA]",
  popoverContent: "bg-[#FAFAFA]",
  value: "bg-[#FAFAFA]",
  selectorIcon: "bg-[#FAFAFA]",
  spinner: "bg-[#FAFAFA]",
  trigger: "bg-[#FAFAFA] shadow-none p-0 h-6 min-h-6",
};
