// create a state for the chat

import { create } from "zustand";
import { AllSource, ChatHistoryInterface, MessageInterface } from "../types";
import { UploadedFile } from "@/shared/types/file-upload";
import dayjs from "dayjs";

export type SelectedSessionInterface = {
  id: string;
  type: string;
  name: string;
} | Record<string, never>;

interface ChatStore {
  messages: MessageInterface[];
  isFloatingAskAiOpen: boolean;
  isOpen: boolean;
  isGenerating: boolean;
  message: string;
  selectedSession: SelectedSessionInterface;
  sessionList: SelectedSessionInterface[],
  threadId: string;
  uploadedFiles: UploadedFile[];
  fileUploadError: boolean;
  isShowHistory: boolean;
  historyList: ChatHistoryInterface[];
  
  setIsFloatingAskAiOpen: (isFloatingAskAiOpen: boolean) => void;
  setIsOpen: (isOpen: boolean) => void;
  setMessage: (message: string) => void;
  addMessage: (message: MessageInterface) => void;
  setSelectedSession: (selectedSession: SelectedSessionInterface) => void;
  setIsGenerating: (isGenerating: boolean) => void;
  setSessionList: (sessionList: SelectedSessionInterface[]) => void;
  setThreadId: (threadId: string) => void;
  setUploadedFiles: (uploadedFiles: UploadedFile[]) => void;
  appendToMessage: (message: string) => void;
  setFileUploadError: (fileUploadError: boolean) => void;
  addAllSourceToMessage: (allSources: AllSource[]) => void;
  setMessages: (messages: MessageInterface[]) => void;
  setIsShowHistory: (isShowHistory: boolean) => void;
  setHistoryList: (historyList: ChatHistoryInterface[]) => void;
}

export const useChatStore = create<ChatStore>((set) => ({
  messages: [
  ],
  isFloatingAskAiOpen: false,
  isOpen: false,
  message: "",
  isGenerating: false,
  sessionList: [],
  threadId: '',
  uploadedFiles: [],
  selectedSession: {
  },
  fileUploadError: false,
  isShowHistory: false,
  historyList: [],
  addMessage: (message: MessageInterface) => set((state) => ({ messages: [...state.messages, message] })),
  setIsOpen: (isOpen: boolean) => set({ isOpen }),
  setIsFloatingAskAiOpen: (isFloatingAskAiOpen: boolean) => set({ isFloatingAskAiOpen }),
  setMessage: (message: string) => set({ message }),
  setSelectedSession: (selectedSession: SelectedSessionInterface) => set({ selectedSession }),
  setIsGenerating: (isGenerating: boolean) => set({ isGenerating }),
  setSessionList: (sessionList: SelectedSessionInterface[]) => set({ sessionList }),
  setThreadId: (threadId: string) => set({ threadId }),
  setHistoryList: (historyList: ChatHistoryInterface[]) => set({ historyList }),
  setUploadedFiles: (uploadedFiles: UploadedFile[]) => set({ uploadedFiles }),
  appendToMessage: (text: string) =>
    set((state) => {
      // If there are no messages yet, create one with a default role (e.g., 'assistant')
      if (state.messages.length === 0) {
        return { messages: [{ role: 'assistant', message: text, created_at: dayjs().toString() }] };
      }
      // Otherwise, update the last message by appending the new text
      const updatedMessages = [...state.messages];
      const lastIndex = updatedMessages.length - 1;
      updatedMessages[lastIndex] = {
        ...updatedMessages[lastIndex],
        message: updatedMessages[lastIndex].message + text,
      };
      return { messages: updatedMessages };
    }),
  addAllSourceToMessage: (allSources: AllSource[]) =>
    set((state) => {
      const updatedMessages = [...state.messages];
      const lastIndex = updatedMessages.length - 1;
      updatedMessages[lastIndex] = {
        ...updatedMessages[lastIndex],
        all_sources: allSources,
      };
      return { messages: updatedMessages };
    }),
  setFileUploadError: (fileUploadError: boolean) => set({ fileUploadError }),
  setMessages: (messages: MessageInterface[]) => set({ messages }),
  setIsShowHistory: (isShowHistory: boolean) => set({ isShowHistory })
}));
