import { get } from "@/infra/rest";
import { updateAgentSessionStep } from "@/modules/sessions/requests";
import {
  AgentSession,
  AgentSessionStatus,
  AgentSessionStep,
  AgentSessionStepType,
  AgentSessionType,
  CommonRagCreateRequest,
  MultiFileVendorRagCreateRequestInput,
} from "@/modules/sessions/types";
import { getSignedUrl } from "@/shared/requests/get-signed-url";
import { uploadFileReq } from "@/shared/requests/upload-file";
import { addNotification } from "@/shared/states/notification";
import { userStateSelector } from "@/shared/states/user";
import { AiResponseType } from "@/shared/types/user";
import { NavigateFunction } from "react-router-dom";
import { emitRagCreate } from "../requests";
import { getAgentData, getAgentStateActions, getAgentStepData } from "../states";
import {
  Control,
  getCustomizeControls,
  getCustomizeControlsActions,
  getCustomizeControlsAgentType,
  Soc2Control,
  VendorAssessmentControl,
} from "../states/cusomize-controls";
import { AGENT_TYPES, GapAssessmentTypes, MultiVendorAssessmentTypes } from "../types";
import { getResponseTypes } from "../utils/get-response-type";
import { checkAndRefreshUrl } from "../utils/refreshUrl";
import { handleSocketResponse } from "../utils/socket-response";

export const handleContinueCustomizeControls = async (steps: AgentSessionStep[], sessionData: AgentSession) => {
  getCustomizeControlsActions().reset();
  const customizeControlStep = steps.find((step) => step.type === AgentSessionStepType.CONTROL_SELECTION);

  if (customizeControlStep && (customizeControlStep.status === AgentSessionStatus.INPUT_NEEDED || true)) {
    await processFileForCustomizeControls(customizeControlStep.data.url, sessionData);
  }
};

export const processFileForCustomizeControls = async (url: string, sessionData: AgentSession) => {
  const validSignedUrl = await checkAndRefreshUrl(url);

  let customizeControlJson = await get<Control[]>({
    url: validSignedUrl,
    isAuthRequired: false,
  });

  if (sessionData.type === AgentSessionType.SOC2_GAP_TYPE1 || sessionData.type === AgentSessionType.SOC2_GAP_TYPE2) {
    customizeControlJson = (customizeControlJson as Soc2Control[]).map((control) => ({
      ...control,
      changesInControl: control.changesInControl === undefined ? 0 : control.changesInControl,
      changesInTestingProcedure:
        control.changesInTestingProcedure === undefined ? -1 : control.changesInTestingProcedure,
      toe: sessionData.type === AgentSessionType.SOC2_GAP_TYPE1 ? control.tod : control.toe,
    }));
  }

  if (sessionData.type === AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT) {
    customizeControlJson = (customizeControlJson as VendorAssessmentControl[]).map((control) => ({
      ...control,
      category: (control as any).key,
      key: undefined,
      changesInQuestion: control.changesInQuestion === undefined ? 0 : control.changesInQuestion,
    }));
  }

  const { setDataState, setAgentType, setSelectedTab } = getCustomizeControlsActions();

  setDataState(customizeControlJson);
  if (sessionData.type === AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT) {
    setSelectedTab("zania_security_assessment");
  }
  setAgentType(sessionData.type);
};

export const handleAutoSaveCustomizeControls = async (agentId: string, markAsComplete = false) => {
  const agentType = getCustomizeControlsAgentType();
  const controls = JSON.stringify(
    getCustomizeControls().map((d) => {
      const temp = { ...d, selected: true };

      if (
        agentType === AgentSessionType.SOC2_GAP_TYPE1 ||
        agentType === AgentSessionType.SOC2_GAP ||
        agentType === AgentSessionType.SOC2_GAP_TYPE2
      ) {
        (temp as Soc2Control).new_control = undefined;
        (temp as Soc2Control).new_tod = undefined;
        (temp as Soc2Control).new_toe = undefined;
      }
      if (agentType === AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT) {
        (temp as VendorAssessmentControl).new_question = undefined;
        (temp as any).key = temp.category;
        (temp as any).category = undefined;
      }
      return temp;
    }),
  ); // temporary until backend is ready
  const blob = new Blob([controls], { type: "application/json" });

  const stepData = getAgentStepData(agentId);

  const { updateAgentStepData } = getAgentStateActions();

  const customizeControlStep = stepData?.find((step) => step.type === AgentSessionStepType.CONTROL_SELECTION);

  if (stepData && customizeControlStep) {
    const stepUrl = customizeControlStep.data.url;
    let currentStaleUrl = stepUrl;
    let markAsCompleteDone = false;

    if (!stepUrl.includes("signed_urls")) {
      const signedUrl = await getSignedUrl({
        file_names: [stepUrl],
        max_age: 86400,
      });
      const updatedStep = {
        ...customizeControlStep,
        data: {
          url: signedUrl[0],
        },
        status: markAsComplete ? AgentSessionStatus.COMPLETE : AgentSessionStatus.IN_PROGRESS,
      };
      const updatedSteps = stepData.map((step) => {
        if (step.id === updatedStep.id) {
          return updatedStep;
        }
        return step;
      });
      updateAgentStepData(agentId, updatedSteps);
      await updateAgentSessionStep(updatedStep);
      currentStaleUrl = signedUrl[0];
      markAsCompleteDone = true;
    }

    const expiryDate = new URL(currentStaleUrl).searchParams.get("se");
    if (expiryDate) {
      const expiry = new Date(expiryDate);
      const currentTime = new Date();
      const diff = expiry.getTime() - currentTime.getTime();
      if (diff < 0) {
        const signedUrl = await getSignedUrl({
          stale_urls: [currentStaleUrl],
          max_age: 86400,
        });
        currentStaleUrl = signedUrl[0];
      }
    }
    await uploadFileReq(currentStaleUrl, blob);

    if (markAsComplete && !markAsCompleteDone) {
      const updatedStep = {
        ...customizeControlStep,
        data: {
          url: currentStaleUrl,
        },
        status: AgentSessionStatus.COMPLETE,
      };
      await updateAgentSessionStep(updatedStep);
      const updatedStepData = stepData.map((step) => {
        if (step.id === updatedStep.id) {
          return updatedStep;
        }
        return step;
      });
      updateAgentStepData(agentId, updatedStepData);
    }
  }
};

export const handleGenerateResponse = async (agentId: string, navigate: NavigateFunction) => {
  const agentTypeFromCustomControlsStore = getCustomizeControlsAgentType();
  if (
    agentTypeFromCustomControlsStore === AgentSessionType.SOC2_GAP ||
    agentTypeFromCustomControlsStore === AgentSessionType.SOC2_GAP_TYPE1 ||
    agentTypeFromCustomControlsStore === AgentSessionType.SOC2_GAP_TYPE2
  ) {
    const agentData = getAgentData<AGENT_TYPES.GAP_ASSESSMENT, GapAssessmentTypes.SOC2>(agentId);

    if (!agentData) {
      throw new Error("An error occurred");
    }
    const { updateAgentStepData } = getAgentStateActions();

    const selectedResponse =
      userStateSelector.getState().aiResponseType === AiResponseType.NONE
        ? AiResponseType.LITE
        : userStateSelector.getState().aiResponseType;
    const task = agentData.sessionData.type === AgentSessionType.SOC2_GAP_TYPE1 ? "soc2_type1" : "soc2_type2";
    const statusInProgressSessionStep = [
      AgentSessionStepType.SOC2_TYPE2_AUDIT,
      AgentSessionStepType.SOC2_TYPE2,
      AgentSessionStepType.SOC2_TYPE1,
    ];

    const statusCompleteSessionSteps = [AgentSessionStepType.CONTROL_SELECTION];

    const steps = agentData.stepData.reduce((acc, step) => {
      if (statusCompleteSessionSteps.includes(step.type)) {
        step.status = AgentSessionStatus.COMPLETE;
      }

      if (statusInProgressSessionStep.includes(step.type)) {
        step.status = AgentSessionStatus.IN_PROGRESS;
      }

      acc.push(step);
      return acc;
    }, [] as AgentSessionStep[]);
    const customizeControlStep = steps.find((step) => step.type === AgentSessionStepType.CONTROL_SELECTION);
    const { response_mode, response_quality } = getResponseTypes(selectedResponse);
    if (customizeControlStep) {
      const source_urls =
        agentData.sessionData.type === AgentSessionType.SOC2_GAP
          ? agentData.sessionData.soc2_type2_audit.source_urls
          : agentData.sessionData.type === AgentSessionType.SOC2_GAP_TYPE1
            ? agentData.sessionData.soc2_type1.source_urls
            : agentData.sessionData.soc2_type2.source_urls;

      const gapAssessmentRequest: CommonRagCreateRequest = {
        doc_type: "json",
        response_quality,
        controls_url: customizeControlStep?.data?.url ?? "",
        source_urls,
      };

      const agent_session_step_id = steps.find((step) => statusInProgressSessionStep.includes(step.type))?.id ?? "";

      updateAgentStepData(agentId, steps);

      // await updateAgentSessionStep(customizeControlStep);

      await emitRagCreate(
        {
          agent_session_id: agentId,
          agent_session_step_id,
          rag_input: gapAssessmentRequest,
          response_mode,
          task: task,
        },
        (response: any) => {
          handleSocketResponse(response, navigate);
        },
      );
    }
  }
  if (agentTypeFromCustomControlsStore === AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT) {
    const selectedResponse =
      userStateSelector.getState().aiResponseType === AiResponseType.NONE
        ? AiResponseType.LITE
        : userStateSelector.getState().aiResponseType;
    const agentData = getAgentData<
      AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
      MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
    >(agentId);

    if (!agentData) {
      throw new Error("An error occurred");
    }

    let { stepData, sessionData } = agentData;
    const vendorProfileStep = stepData.find((step) => step.type === AgentSessionStepType.MULTI_VENDOR_PROFILE);
    if (!vendorProfileStep) {
      throw new Error("Vendor profile step not found");
    }
    const sourceUrls = vendorProfileStep.data?.source_urls || [];
    const { response_mode, response_quality } = getResponseTypes(selectedResponse);

    // Check each URL and only refresh the expired ones
    const refreshedUrls = await Promise.all(
      sourceUrls.map(async (url) => {
        return await checkAndRefreshUrl(url);
      }),
    );
    const customizeControlStep = stepData.find((step) => step.type === AgentSessionStepType.CONTROL_SELECTION);
    const vendorAssessment: MultiFileVendorRagCreateRequestInput = {
      doc_type: "json",
      response_quality,
      source_urls: refreshedUrls,
      vendor_intake_form: vendorProfileStep.data?.vendor_intake_form,
      controls_url: customizeControlStep?.data?.url ?? "",
    };
    stepData = stepData.map((step) => {
      if (
        [
          AgentSessionStepType.LOAD_TEMPLATE,
          AgentSessionStepType.EXTRACT_CONTROLS,
          AgentSessionStepType.MULTI_VENDOR_PROFILE,
          AgentSessionStepType.CONTROL_SELECTION,
        ].includes(step.type)
      ) {
        step.status = AgentSessionStatus.COMPLETE;
      }

      const inprogresStep = AgentSessionStepType.MULTI_FILE_VENDOR_ASSESSMENT;

      if ((step.type as AgentSessionStepType) === inprogresStep) {
        step.status = AgentSessionStatus.IN_PROGRESS;
      }
      return step;
    });

    const { setAgentData } = getAgentStateActions();
    setAgentData(agentId, {
      ...agentData,
      stepData,
    });

    const multiFileVendorAssessmentStep = stepData.find(
      (step): step is AgentSessionStep =>
        (step.type as AgentSessionStepType) === AgentSessionStepType.MULTI_FILE_VENDOR_ASSESSMENT,
    );

    const agent_session_step_id = multiFileVendorAssessmentStep?.id ?? "";

    const vendorProfileStep1 = stepData.find(
      (
        step,
      ): step is AgentSessionStep & {
        type: AgentSessionStepType.MULTI_VENDOR_PROFILE;
      } => step.type === AgentSessionStepType.MULTI_VENDOR_PROFILE,
    );

    const updatedStep = {
      ...vendorProfileStep1,
      status: AgentSessionStatus.COMPLETE,
    };

    try {
      if (customizeControlStep) {
        // await updateAgentSessionStep(customizeControlStep);
      }
    } catch (error) {
      console.error("Error updating agent session step:", error);
      addNotification({
        message: "Failed to save vendor profile. Please try again.",
        type: "error",
      });
    }

    emitRagCreate(
      {
        agent_session_id: agentId,
        agent_session_step_id,
        rag_input: vendorAssessment,
        response_mode,
        task: "multi_file_vendor_assessment",
        vendor_intake_form: vendorProfileStep.data?.vendor_intake_form,
      },
      (response: any) => {
        handleSocketResponse(response, navigate);
      },
    );
  }
};
