import { AiResponseType } from "@/shared/types/user";
import { getResponseTypes } from "../utils/get-response-type";
import { AgentSessionStatus, AgentSessionStepType, AgentSessionType, RiskAgentSession } from "@/modules/sessions/types";
import type { AgentSessionStep, FairAgentSession, CommonRagCreateRequest, AgentSessionControl } from "@/modules/sessions/types";
import { AGENT_TYPES, RiskAssessmentTypes } from "../types";
import type { AgentData, AgentSourceFile, AgentSpecificReviewResponseType, ReviewResponseData, ReviewResponseTableRowForFair } from "../types";
import { userStateSelector } from "@/shared/states/user";
import type { NavigateFunction } from "react-router-dom";
import { createAgentSession, getAgentSession, getAgentSessionControls, updateAgentSessionStep } from "@/modules/sessions/requests";
import { getAgentData, getAgentStateActions } from "../states";
import { emitRagCreate, getRAGExcelFromJSON } from "../requests";
import { handleSocketResponse } from "../utils/socket-response";
import { getRenderType } from "../utils/get-render-type";
import { getFieldTitle } from "../utils/getFieldTitle";
import { ResponseSchemaVersion } from "../types/risk-and-gap";
import { convertSnakeToCapitalized, convertSnakeToTitleCase } from "../utils/snake-to-capital";
import { FairReviewResponse } from "../types/risk-and-gap";
import { AutoSaveFunctionArgs } from "../utils/autosave";
import { useAgentSocket } from "../utils/agent-socket.ts";
import { getSignedUrl } from "@/shared/requests/get-signed-url";
import { addNotification } from "@/shared/states/notification";
import { getFileNameForDownloadReport } from "../utils/downloadReport";
import { ROUTES, AGENT_ROUTES } from "@/shared/constants/routes";
import { uploadFileReq } from "@/shared/requests/upload-file";

interface ProcessFileForFairAssessmentArgs {
    name: string;
    risk_statement: string;
    sourceFiles: AgentSourceFile[];
    navigate: NavigateFunction;
    agentDataCurrent: AgentData<AGENT_TYPES.RISK_ASSESSMENT, RiskAssessmentTypes.FAIR> | undefined;
  }
export const processFileForFairAssessment = async ({
    name,
    risk_statement,
    sourceFiles,
    navigate,
    agentDataCurrent,
  }: ProcessFileForFairAssessmentArgs) => {
    const selectedResponse =
       userStateSelector.getState().aiResponseType === AiResponseType.NONE
         ? AiResponseType.LITE
         : userStateSelector.getState().aiResponseType;
     const source_urls = sourceFiles.map((file) => file.url);
   
     const { response_mode, response_quality } = getResponseTypes(selectedResponse);
   
     const fairAssessment: CommonRagCreateRequest = {
       doc_type: "json",
       response_quality,
       source_urls,
       risk_statement
     };

     fairAssessment.assessment_type = "assessment";
     const { data: { session, steps } } = await createAgentSession({
           name: name ?? "Fair Assessment",
           type: AgentSessionType.FAIR_ASSESSMENT,
           [AgentSessionType.FAIR_ASSESSMENT]: fairAssessment,
         });


      if (!session || !session.id) {
          throw new Error("An error occurred");
        }

   

      const stepData = steps?.reduce((acc: AgentSessionStep[], step:AgentSessionStep) => {
        if ([AgentSessionStepType.LOAD_TEMPLATE, AgentSessionStepType.EXTRACT_CONTROLS].includes(step.type)) {
              step.status = AgentSessionStatus.COMPLETE;
      }
            
        if (step.type === AgentSessionStepType.GENERATE_FAIR_ASSESSMENT_RESPONSE) {
                step.status = AgentSessionStatus.IN_PROGRESS;
      }
         
          acc.push(step);
            return acc;
          }, [] as AgentSessionStep[]);

          const agentData: AgentData<AGENT_TYPES.RISK_ASSESSMENT, RiskAssessmentTypes.FAIR> = {
              agentType: AGENT_TYPES.RISK_ASSESSMENT,
              subType: RiskAssessmentTypes.FAIR,
              sessionData: session as FairAgentSession,
              stepData: stepData || [],
              responseQuality: selectedResponse,
              mainData: {
                sourceFilesUrls: new Map(sourceFiles.map((file) => [file.fileName, file])),
                approvedIds: [],
                editedIds: [],
              },
            };

          const agent_session_step_id =
            steps?.find((step) => step.type === AgentSessionStepType.GENERATE_FAIR_ASSESSMENT_RESPONSE)?.id ??
            steps?.find((step) => step.type === AgentSessionStepType.EDIT_RESPONSE)?.id ??
            "";

          const { setAgentData } = getAgentStateActions();
          setAgentData(session.id, agentData);
        
          await emitRagCreate(
            {
              agent_session_id: session.id,
              agent_session_step_id,
              rag_input: fairAssessment,
              response_mode,
              task: "fair_ra",
            },
            (response: any) => {
              handleSocketResponse(response, navigate);
            },
          );
          navigate({
            pathname: `/agent/${session.id}/`,
          });
        };



export const processFileForFairReview = async (url:string, sessionId:string) => {
  // Get the session data
  const session = await getAgentSession(sessionId ?? "");
  
  // In a real implementation, we would fetch the controls from the API
  const sessionControls = await getAgentSessionControls(sessionId);
  const fairJson = sessionControls.data?.map((data: AgentSessionControl) => data.fair_ra) || [];
  // Initialize arrays and maps for storing the processed data
  const ids: string[] = [];
  const tableRows: ReviewResponseTableRowForFair[] = [];
  const approvedIds: string[] = [];
  const editedIds: string[] = [];
  
  // Keys that should not be rendered in the UI
  const keysToOmit: string[] = [
    "id",
    "field_level",
    "edited",
    "approved",
    "reassessed",
    "version",
    "field_name",
    "confidence",
    "index",
    "reassesed",
    "rating",
  ];
  
  const getFieldType = (level: number): 'number' | 'letter' | 'roman' | 'none' => {
    switch (level) {
      case 1:
        return 'number';
      case 2:
        return 'letter';
      case 3:
        return 'roman';
      case 4:
        return 'number';
      default:
        return 'none';
    }
  };
  
  // Process the FAIR assessment data
  const reviewResponse = new Map<
    string,
    ReviewResponseData<
      keyof AgentSpecificReviewResponseType<AGENT_TYPES.RISK_ASSESSMENT, RiskAssessmentTypes.FAIR>
    >[]
  >(
    fairJson.map((item: any) => {
      const id = item.id;
      ids.push(id);
      // Create a table row for this item
      const tableRow: ReviewResponseTableRowForFair = {
        id:id,
        breakdown: convertSnakeToTitleCase(item.control_id) || null,
        control: null,
        field_level: item.field_level || 0,
        field_type: getFieldType(item.field_level),
        field_index: item.field_index || 0,
        rating: item.rating || "",
      };
      
      tableRows.push(tableRow);
      
      // Track approved and edited items
      if (item.approved) {
        approvedIds.push(id);
      }
      if (item.edited) {
        editedIds.push(id);
      }
      
      // Create the response data for this item
      const responseData: ReviewResponseData<
        keyof AgentSpecificReviewResponseType<AGENT_TYPES.RISK_ASSESSMENT, RiskAssessmentTypes.FAIR>
      >[] = [];
      
      // Process each field in the item
      for (const [key, value] of Object.entries(item)) {
        // Skip null values - don't try to process them
        if (value === null) {
          continue;
        }
        // Determine the render type for this field
        const renderType = getRenderType(key , item.version, value as string, "fair");
        
        // Create the response data for this field
        const data: ReviewResponseData<
          keyof AgentSpecificReviewResponseType<AGENT_TYPES.RISK_ASSESSMENT, RiskAssessmentTypes.FAIR>
        > = {
          type: renderType,
          value: value,
          key: key as keyof AgentSpecificReviewResponseType<AGENT_TYPES.RISK_ASSESSMENT, RiskAssessmentTypes.FAIR>,
          title: getFieldTitle(key, item.version),
          shouldRender: keysToOmit.includes(key) ? false : true
        };
        
        responseData.push(data);
      }
      
      return [id, responseData];
    })
  );
  
  // Get the current agent data
  const currentData = getAgentData<AGENT_TYPES.RISK_ASSESSMENT, RiskAssessmentTypes.FAIR>(sessionId);
  
  if (!currentData) {
    throw new Error("An error occurred while processing the file: Failed to get agent data.");
  }
  
  
  // Update the step data
  const stepData = currentData.stepData.map((step) => {
    if (step.type === AgentSessionStepType.GENERATE_FAIR_ASSESSMENT_RESPONSE) {
      step.status = AgentSessionStatus.COMPLETE;
    }
    if (step.type === AgentSessionStepType.EDIT_RESPONSE) {
      step.status = AgentSessionStatus.INPUT_NEEDED;
      step.data = {
        url,
      };
    }
    return step;
  });
  
  // Create the updated agent data
  const agentData: Partial<AgentData<AGENT_TYPES.RISK_ASSESSMENT, RiskAssessmentTypes.FAIR>> = {
    agentType: AGENT_TYPES.RISK_ASSESSMENT,
    subType: RiskAssessmentTypes.FAIR,
    sessionData: session.data.session as FairAgentSession,
    stepData,
    mainData: {
      ...currentData.mainData,
      reviewResponseData: reviewResponse,
      reviewResponseIds: ids,
      tableRows: tableRows as any, // Type assertion to fix the error
      searchFor: ["field"],
      searchTerm: "",
      approvedIds,
      editedIds,
    },
  };
  
  // Update the agent data in the state
  const { updateAgentData } = getAgentStateActions();
  updateAgentData<AGENT_TYPES.RISK_ASSESSMENT, RiskAssessmentTypes.FAIR>(sessionId, agentData);
}

export const generateFinalFairJSON = (agentId: string) => {
  const agentData = getAgentData<AGENT_TYPES.RISK_ASSESSMENT, RiskAssessmentTypes.FAIR>(agentId);

  if (!agentData) {
    throw new Error("An error occurred while getting agent data");
  }

  const { mainData } = agentData;
  const { reviewResponseData, approvedIds, editedIds } = mainData;

  if (!reviewResponseData) {
    throw new Error("An error occurred while getting review response data");
  }

  // Reconstruct the original fairJson structure from reviewResponseData
  const finalFairJson = [];
  for (const [id, responseDataArray] of reviewResponseData.entries()) {
    // Start with a base object containing the ID and approval status
    const fairItem = {
      id,
      approved: approvedIds.includes(id),
      edited: editedIds.includes(id),
    };
    // Add all other fields from the responseDataArray
    for (const responseData of responseDataArray) {
      if (responseData.key && responseData.value !== undefined) {
        (fairItem as any)[responseData.key] = responseData.value;
      }
    }

    finalFairJson.push(fairItem);
  }

  return finalFairJson;
};

export const handleFairAutoSave = async ({ agentId, markAsComplete }: AutoSaveFunctionArgs) => {
  const fairData = generateFinalFairJSON(agentId);

  const agentData = getAgentData<AGENT_TYPES.RISK_ASSESSMENT, RiskAssessmentTypes.FAIR>(agentId);
  if (!agentData) {
    throw new Error("An error occurred while getting agent data");
  }
  const { stepData } = agentData;
  const { staleUrl, selectedId } = agentData.mainData;

  const { setStaleUrl, updateAgentStepData } = getAgentStateActions();

  const editStepData = stepData?.find((step) => step.type === AgentSessionStepType.EDIT_RESPONSE);

  if (!editStepData) {
    throw new Error("An error occurred while saving: Edit step data not found");
  }

  const stepUrl = editStepData?.data?.url;

  let currentStaleUrl = staleUrl || stepUrl;
  let markAsCompleteDone = false;

  if (!currentStaleUrl) {
    const signedUrl = await getSignedUrl({
      file_names: ["updated_fair.json"],
      max_age: 86400,
    });
    const updatedStep = {
      ...editStepData,
      data: {
        url: signedUrl[0],
      },
      status: markAsComplete ? AgentSessionStatus.COMPLETE : AgentSessionStatus.INPUT_NEEDED,
    };
    const updatedSteps = stepData.map((step) => {
      if (step.id === updatedStep.id) {
        return updatedStep;
      }
      return step;
    });
    updateAgentStepData(agentId, updatedSteps);
    await updateAgentSessionStep(updatedStep);
    currentStaleUrl = signedUrl[0];
    markAsCompleteDone = true;
    setStaleUrl(agentId, currentStaleUrl);
  }

  const expiryDate = new URL(currentStaleUrl).searchParams.get("se");
  if (expiryDate) {
    const expiry = new Date(expiryDate);
    const currentTime = new Date();
    const diff = expiry.getTime() - currentTime.getTime();
    if (diff < 0) {
      const signedUrl = await getSignedUrl({
        stale_urls: [currentStaleUrl],
        max_age: 86400,
      });
      currentStaleUrl = signedUrl[0];
      setStaleUrl(agentId, currentStaleUrl);
    }
  }
  const control = fairData.find((item) => item.id === selectedId);
  if (!control) return;

  const { emitControlEdit } = useAgentSocket();
  try {
    // Get lastEditedField from the state
    const editedField = agentData.mainData.lastEditedField ?? "";

    // Make sure control.id is a string
    const controlId = control.id ? control.id.toString() : selectedId;

    await emitControlEdit(currentStaleUrl, control, controlId, agentId, editStepData.id, editedField);

    // Update lastUpdate in the state after successful emit
    const { updateAgentData } = getAgentStateActions();
    updateAgentData(agentId, {
      ...agentData,
      mainData: {
        ...agentData.mainData,
        lastUpdate: {
          timestamp: Date.now(),
          isRemoteUpdate: false
        }
      }
    });
  } catch (error) {
    console.error("Error in emitControlEdit:", error);
    throw error;
  }
};

export const getFairJsonFromApi = async (sessionId: string) => {
  try {
    const agentData = getAgentData<AGENT_TYPES.RISK_ASSESSMENT, RiskAssessmentTypes.FAIR>(sessionId);
    if (!agentData) {
      throw new Error("An error occurred");
    }
    const { responseQuality, sessionData, stepData } = agentData;
    addNotification({
      type: "success",
      title: "Report Download in Progress...",
      message: "If the download does not begin within 15 seconds, please click the ‘Download’ button",
    });

    const data = await getRAGExcelFromJSON({
      task: "fair_ra",
      output_format: "pdf",
      response_quality: responseQuality ?? AiResponseType.LITE,
      agent_session_id: sessionId,
      use_for_ai_training: false,
    });

    const { setFinalFileUrl, updateAgentStepData } = getAgentStateActions();

    setFinalFileUrl(sessionId, data.data.file_url);

      // Direct download using the file URL
    const link = document.createElement("a");
    link.href = data.data.file_url;
    link.download = getFileNameForDownloadReport(sessionData);
    link.click();

    const downloadStepData = stepData?.find((step) => step.type === AgentSessionStepType.PREPARE_REPORT);
    const editResponseStep = stepData?.find((step) => step.type === AgentSessionStepType.EDIT_RESPONSE);
    const updatedStepData = stepData.map((step) => {
      if (step.id === editResponseStep?.id) {
        return { ...step, status: AgentSessionStatus.COMPLETE };
      }
      if (step.id === downloadStepData?.id) {
        return {
          ...step,
          data: {
            url: data.data.file_url,
          },
        };
      }
      return step;
    });

   if (downloadStepData?.id) {
    await updateAgentSessionStep({
      ...downloadStepData,
      data: {
        url: data.data.file_url,
      },
    });
   }
    updateAgentStepData(sessionId, updatedStepData as AgentSessionStep[]);

  } catch (error) {
    console.error(error);
    addNotification({
      type: "error",
      title: "Download failed",
      message: (error as Error)?.message ?? "Report download failed. Please try again later.",
    });
  }
};

export const handleEndFairSession = async (sessionId: string, navigate: NavigateFunction) => {
  const { setFinalFileUrl, updateAgentStepData } = getAgentStateActions();
  try {
    const agentData = getAgentData<AGENT_TYPES.RISK_ASSESSMENT, RiskAssessmentTypes.FAIR>(sessionId);

    if (!agentData) {
      throw new Error("An error occurred");
    }

    const { stepData } = agentData;
    const editResponseStep = stepData?.find((step) => step.type === AgentSessionStepType.EDIT_RESPONSE);

    //if user directly clicks on end session without download report
    if (editResponseStep?.status !== AgentSessionStatus.COMPLETE) {
      const fairData = generateFinalFairJSON(sessionId);
      const blob = new Blob([JSON.stringify(fairData)], { type: "application/json" });

      const signedUrl = await getSignedUrl({
        file_names: ["updated_fair.json"],
        max_age: 86400,
      });

      //await uploadFileReq(signedUrl[0], blob);

      // Process the JSON file first
      await processFileForFairReview(signedUrl[0], sessionId);

      const data = await getRAGExcelFromJSON({
        task: "fair_ra",
        output_format: "pdf",
        response_quality: agentData.responseQuality ?? AiResponseType.LITE,
        agent_session_id: sessionId,
         use_for_ai_training: false,
      });

      setFinalFileUrl(sessionId, data.data.file_url);

      const downloadStepData = stepData?.find((step) => step.type === AgentSessionStepType.PREPARE_REPORT);

      const updatedStepData = stepData.map((step) => {
        if (step.id === editResponseStep?.id) {
          return {
            ...step,
            status: AgentSessionStatus.COMPLETE,
            data: {
              url: signedUrl[0], //  JSON URL for future processing
            },
          };
        }
        if (step.id === downloadStepData?.id) {
          return {
            ...step,
            data: {
              url: data.data.file_url,
            },
            status: AgentSessionStatus.COMPLETE,
          };
        }
        return step;
      });

      if (downloadStepData?.id) {
        await updateAgentSessionStep({
          ...downloadStepData,
          data: {
            url: data.data.file_url,
          },
          status: AgentSessionStatus.COMPLETE,
        });
      }

      updateAgentStepData(sessionId, updatedStepData as AgentSessionStep[]);
      const successPath = `/${ROUTES.AGENT}/${AGENT_ROUTES.SUCCESS}/${sessionId}`;
      navigate(successPath);
    } else {
      const prepareReportStep = stepData?.find((step) => step.type === AgentSessionStepType.PREPARE_REPORT);
      const updatedStepData = stepData.map((step) => {
        if (step.id === prepareReportStep?.id) {
          return { ...step, status: AgentSessionStatus.COMPLETE };
        }
        return step;
      });

      if (prepareReportStep?.id) {
        await updateAgentSessionStep({
          ...prepareReportStep,
          status: AgentSessionStatus.COMPLETE,
        });
      }

      await new Promise<void>((resolve) => {
        updateAgentStepData(sessionId, updatedStepData as AgentSessionStep[]);
        setTimeout(resolve, 0);
      });

      const successPath = `/${ROUTES.AGENT}/${AGENT_ROUTES.SUCCESS}/${sessionId}`;
      navigate(successPath);
    }
  } catch (error) {
    console.error(error);
    addNotification({
      type: "error",
      message: "Error in ending the session",
      title: "Error",
    });
  }
};