import { ZButton } from "@/shared/components/button";
import ModalCloseIcon from "@/shared/icons/modal-close";
import { Modal, ModalBody, ModalContent, ModalHeader, Spinner } from "@nextui-org/react";
import { useState } from "react";
import ShadowExcalamationIcon from "@/shared/icons/shadow-exclamation";
import { ZModalFooter } from "@/shared/components/modal";
import { ChatHistoryInterface } from "../types";


type Props = {
  isOpen: boolean;
  toggleModal: () => void;
  selectedChat: ChatHistoryInterface
}

export default function DeleteConfirmModal({ isOpen, toggleModal, selectedChat }: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    setIsLoading(true);
    setTimeout(() => {
      toggleModal();
      setIsLoading(false);
    }, 1500)
  }

  return (
    <Modal isOpen={isOpen} size="md" hideCloseButton={true} onClose={toggleModal}>
      <ModalContent>
        <ModalHeader className="self-center">
          <button role="button" aria-label="Close" onClick={toggleModal}
            className="absolute appearance-none select-none top-1 right-1 rtl:left-1 rtl:right-[unset] p-2 text-foreground-500 
        rounded-full hover:bg-default-100 active:bg-default-200 tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 
        data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 text-[16px] wei"
            type="button">

            <ModalCloseIcon />
          </button>
          <div className="flex flex-col items-center justify-center gap-2">
            <p className="text-[20px] font-medium">
              Delete Chat?
            </p>
          </div>
        </ModalHeader>
        <ModalBody className="self-center items-center justify-center w-full mx-4">
          <p className="text-center text-sm text-gray-500">Are you sure you want to delete
            <span className="text-medium text-black"> {selectedChat.title}</span> from history?</p>

          <ShadowExcalamationIcon />

          <p className="text-sm text-gray-500">Once deleted, the chat cannot be restored</p>

        </ModalBody >
        <ZModalFooter className="w-full justify-stretch">
          <ZButton
            variant="bordered"
            onClick={() => toggleModal()}
            className="flex-auto"
          >
            Cancel
          </ZButton>
          <ZButton

            isLoading={isLoading}
            onClick={() => void handleDelete()}
            disabled={isLoading}
            className="flex-auto"
            color="danger"
          >
            Yes, Delete
          </ZButton>
        </ZModalFooter>

      </ModalContent >
    </Modal >
  );
}