import { type AgentSession, AgentSessionStatus, AgentSessionStepType, AgentSessionType } from "@/modules/sessions/types";
import { addNotification } from "@/shared/states/notification";
import { getAgentData, getAgentStepData, getAgentTypeFromId } from "../states";
import { AGENT_TYPES, GapAssessmentTypes, RiskAssessmentTypes } from "../types";
import { getDoraJsonFromApi } from "../use-cases/dora.use-case";
import { getQAJsonFromAPI } from "../use-cases/quesitionnaire.use-case";
import { getRiskJsonFromApi } from "../use-cases/risk.use-case";
import { getSoc2JsonFromApi } from "../use-cases/soc2.use-case";
import { getVendorJsonFromApi } from "../use-cases/vendor.use-case";
import { getFairJsonFromApi } from "../use-cases/fair.use-case";

export const generateAndDownloadSessionReport = async (sessionId: string) => {

  const stepData = getAgentStepData(sessionId);

  const prepareReportStep = stepData?.find(
    step => step.type === AgentSessionStepType.PREPARE_REPORT
  );
  // If prepare report step is complete, directly download report
  if (prepareReportStep?.status === AgentSessionStatus.COMPLETE) {
    return downloadFinalSessionReport(sessionId);
  }

  const { agentType, agentSubType } = getAgentTypeFromId(sessionId);

  try {
    switch (agentType) {
      case AGENT_TYPES.RISK_ASSESSMENT:
        if (agentSubType === RiskAssessmentTypes.FAIR) {
          await getFairJsonFromApi(sessionId);
        } else {
          await getRiskJsonFromApi(sessionId);
        }
        break;
      case AGENT_TYPES.QUESTIONNAIRE:
        await getQAJsonFromAPI(sessionId);
        break;
      case AGENT_TYPES.GAP_ASSESSMENT:
        switch (agentSubType) {
          case GapAssessmentTypes.SOC2:
          case GapAssessmentTypes.SOC2_TYPE1:
          case GapAssessmentTypes.SOC2_TYPE2:
            await getSoc2JsonFromApi(sessionId);
            break;
          case GapAssessmentTypes.DORA:
            await getDoraJsonFromApi(sessionId);
            break;
          default:
            throw new Error('Invalid gap assessment type');
        }
        break;
      case AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT:
        await getVendorJsonFromApi(sessionId);
        break;
      default:
        throw new Error('Invalid assessment type');
    }
  } catch (error) {
    console.error(error);
    addNotification({
      type: 'error',
      title: 'Download failed',
      message:
        (error as Error)?.message ??
        'Report download failed. Please try again later.',
    });
  }
}

export const downloadFinalSessionReport = async (sessionId: string) => {
  try {
    const { agentType, agentSubType } = getAgentTypeFromId(sessionId);
    const agentData = getAgentData<typeof agentType, typeof agentSubType>(sessionId);

    if (!agentData?.sessionData || !agentData.stepData) {
      throw new Error('Session data not found');
    }

    const prepareReportStep = agentData.stepData.find(
      (step) => step.type === AgentSessionStepType.PREPARE_REPORT
    );

    if (!prepareReportStep?.data?.url) {
      throw new Error('Report file not found');
    }

    const response = await fetch(prepareReportStep.data.url);
    if (!response.ok) {
      throw new Error(`Failed to fetch report: ${response.statusText}`);
    }

    const fileBlob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(fileBlob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = getFileNameForDownloadReport(agentData.sessionData);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);

    addNotification({
      type: "success",
      title: "Downloading your Report...",
      message: "If the download does'nt start in 15 seconds, please use the download button",
    });
  } catch (error) {
    addNotification({
      type: 'error',
      title: 'Download failed',
      message: (error as Error)?.message ?? 'Report download failed. Please try again later.',
    });
  }
}

export const getFileNameForDownloadReport = (session: AgentSession) => {
  // TODO :: smelly code - these conditions need to go
  // the BE needs to send the file extension as part of the response
  let extension = "xlsx";
  if (session.type === AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT || session.type === AgentSessionType.FAIR_ASSESSMENT) {
    extension = "pdf";
  }

  let suffix = "";
  let prefix = "";

  let fileName = session.name;
 
  switch (session.type) {
    case AgentSessionType.QA_FILLER:
      suffix = " Questionnaire";
      break;
    case AgentSessionType.SOC2_GAP:
      prefix = "SOC 2 Type 2 - ";
      break;
    case AgentSessionType.SOC2_GAP_TYPE1:
      prefix = "SOC 2 Type 1 - ";
      break;
    case AgentSessionType.SOC2_GAP_TYPE2:
      prefix = "SOC 2 Type 2 - ";
      break;
    case AgentSessionType.DORA_GAP:
      suffix = " DORA Gap Assessment";
      break;
    case AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT:
      suffix = ` Third-Party Risk Assessment ${session.name}`;
      fileName = session.multi_file_vendor_assessment.vendor_name;
      break;
    case AgentSessionType.FAIR_ASSESSMENT:
      suffix = " FAIR Risk Assessment";
      break;
    case AgentSessionType.RISK_ASSESSMENT:
      if (session.type === AgentSessionType.RISK_ASSESSMENT) {
        suffix = " NIST Risk Assessment";
      } 
      break;
    case AgentSessionType.NIST_AI_RMF:
      suffix = " NIST AI RMF Risk Assessment";
      break;
  }

  return `${prefix}${fileName}${suffix}.${extension}`;
};