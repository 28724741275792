import FeatureCard from "./feature-card";
import { BookOpenCheck, Drama, MessageCircleQuestion, ShieldAlert, WandSparkles } from "lucide-react";


const featuresCategories = [
  {
    key: "examples",
    title: "Follow-Ups",
    // make icon stroke less wide
    icon: <MessageCircleQuestion width={40} height={40} className="stroke-1" />,
    descriptions: [
      "What follow-ups refine this assessment further?",
      "Which root causes explain these gaps, exceptions?",
      "Update policies for Splunk adoption.",
    ],
  },
  {
    key: "capabilities",
    title: "Knowledge",
    icon: <BookOpenCheck width={40} height={40} className=" stroke-1" />,
    descriptions: [
      "How does NIST AI RMF relate to CSF?",
      "What are each category’s and overall maturity tiers?",
      "What is a SIEM?",
    ],
  },
  {
    key: "limitations",
    title: "Benchmarks",
    icon: <ShieldAlert width={40} height={40} className=" stroke-1" />,
    descriptions: [
      "Which peers share a similar risk profile?",
      "What attacks are most prevalent in Kubernettes",
      "Have there been phishing attacks on companies like ours?",
    ],
  },
];

interface FeaturesCardsProps {
  className?: string;
  cardClassName?: string;
}

export default function FeaturesCards({ className, cardClassName }: FeaturesCardsProps) {
  return (
    <div className={className}>
      {featuresCategories.map((category) => (
        <FeatureCard
          className={cardClassName}
          key={category.key}
          descriptions={category.descriptions}
          icon={category.icon}
          title={category.title}
        />
      ))}
    </div>
  );
}
