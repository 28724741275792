import { Textarea } from "@nextui-org/react";
import clsx from "clsx";
import {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { ReviewSourceTypes } from "../../types/index.ts";
import InlineSources from "./InlineSources";

export type FieldValueWithInlineSources = {
  value: string;
  sources: ReviewSourceTypes[];
  isEdited?: boolean;
};

export type AgentEditFieldRef = {
  focus: () => void;
};

type Props = {
  fieldValueObj: FieldValueWithInlineSources;
  title?: string;
  onChange: (value: FieldValueWithInlineSources) => void;
  onSourceClick: (selectedSource: ReviewSourceTypes) => void;
  setListOfSourcesForSelectedSources: (
    selectedSource: ReviewSourceTypes[]
  ) => void;

  noBorder?: boolean;
  noPadding?: boolean;
  expandedView?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  minRows?: number;
  maxRows?: number;
  isDisabled?: boolean;
  focusEnd?: boolean
};

const AgentEditFieldWithInlineSources = forwardRef<AgentEditFieldRef, Props>(
  (props, ref) => {
    const {
      fieldValueObj,
      onChange,
      onSourceClick,
      title,
      noBorder,
      noPadding,
      expandedView,
      autoFocus,
      placeholder,
      minRows,
      maxRows,
      isDisabled,
      setListOfSourcesForSelectedSources,
      focusEnd
    } = props;

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (textareaRef.current) {
          textareaRef.current.focus({
            preventScroll: true
          });
          // Set cursor to end of text
          if(focusEnd){
            const length = textareaRef.current.value.length;
            textareaRef.current.setSelectionRange(length, length);
          }
        }
      },
    }));

    const [value, setValue] = useState(fieldValueObj);

    useEffect(() => {
      if (fieldValueObj?.value !== value?.value) {
        setValue(fieldValueObj);
      }
    }, [fieldValueObj?.value]);

    const handleOnChange = useCallback(
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!onChange) return;
        const newValue = e.target.value;
        const newFieldValueObj = {
          ...fieldValueObj,
          value: newValue,
          isEdited: true,
        };
        setValue(newFieldValueObj);
        onChange(newFieldValueObj);
      },
      [onChange]
    );

    const handleSourceRemoveAtIndex = (index: number) => {
      const newSources = [...(fieldValueObj?.sources ?? [])];
      newSources.splice(index, 1);

      const newFieldValueObj = {
        ...fieldValueObj,
        sources: newSources,
        isEdited: true,
      };

      setValue(newFieldValueObj);
      onChange(newFieldValueObj);
    };

    return (
      <div className="relative w-full">
        {title && (
          <div
            className="bg=[#F9F7F7] text-[#717171] font-medium py-2 px-4 text-[10px] leading-4 "
            style={{
              border: "1px solid #E4E4E7",
              borderLeft: "0px",
              borderRight: "0px",
            }}
          >
            {title}
          </div>
        )}
        <Textarea
          ref={textareaRef}
          className="text-[#121212] font-light bg-white focus-within:text-[#000000] h-fit"
          classNames={{
            base: `shadow-none !border-none rounded-none truncate`,
            inputWrapper: clsx(
              "px-4 py-2 rounded-md border-[1px]",
              noBorder && "border-none",
              noPadding && "p-0",
              fieldValueObj?.sources?.length && "pb-8"
            ),
          }}
          minRows={minRows}
          maxRows={maxRows}
          variant="bordered"
          labelPlacement="outside"
          value={value.value}
          placeholder={placeholder ? placeholder : "Type here..."}
          onChange={handleOnChange}
          autoFocus={autoFocus}
          disabled={isDisabled}
          onMouseDown={(e) => {
            const textarea = e.target as HTMLTextAreaElement;
            const clickPosition = textarea.selectionStart;
            textarea.focus({
              preventScroll: true
            });
            textarea.setSelectionRange(clickPosition, clickPosition);
          }}
        />
        {fieldValueObj?.sources && (
          <div className={clsx(noPadding ? "p-0" : "px-4")}>
            <InlineSources
              sources={fieldValueObj?.sources}
              onSourceClick={onSourceClick}
              handleSourceRemoveAtIndex={handleSourceRemoveAtIndex}
              setListOfSourcesForSelectedSources={
                setListOfSourcesForSelectedSources
              }
              tooltipPlacement={expandedView ? "bottom" : "bottom"}
            />
          </div>
        )}
      </div>
    );
  }
);

export default AgentEditFieldWithInlineSources;
