import { AgentSessionStatus, AgentSessionStepType } from "@/modules/sessions/types";
import ErrorBoundary from "@/shared/components/error-boundary";
import { ZTable, ZTableColumnProps } from "@/shared/components/table";
import usePrevious from "@/shared/hooks/use-previous";
import { Chip, Input, ModalHeader, ModalBody, ModalContent, Modal, cn } from "@nextui-org/react";
import clsx from "clsx";
import Fuse from "fuse.js";
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  helper,
  tableConfigs
} from "../../constants/helpers";
import { useAgentNotifications } from "../../hooks/useAgentNotifications.ts";
import { useControlEventsSubscription } from "../../hooks/useControlEventsSubscription.ts";
import { useControlStatus } from "../../hooks/useControlStatus.ts";
import { useSessionSubscription } from "../../hooks/useSessionSubscription.ts";
import {
  getAgentData,
  getAgentTypeFromId,
  useAgentData,
  useAgentSelector,
  useAgentStepData,
  useApprovedIds,
  useEditedIds,
  useReviewResponseFilters,
  useReviewResponseIds,
  useReviewResponseSearch,
  useSelectedId,
  useTableRows,
} from "../../states";
import {
  AGENT_TYPES,
  AgentSpecificTableRowData,
  FilterOperators,
  FilterOption,
  ReviewResponseTableRow,
  RiskAssessmentTypes
} from "../../types/index.ts";
import { handleAgentAutoSave } from "../../utils/autosave";
import { renderTableComponents } from "../table-components";
import AgentActions from "./actions";
import AgentExpandedView from "./expanded-view";
import AgentFilters from "./filters";
import AgentResponse from "./response";

interface ReviewResponseProps {
  hideAgentTypeFromHeader?: boolean;
  disableRoundedBorder?: boolean;
}

interface Params {
  id: string;
}

const ReviewResponse = (props: ReviewResponseProps) => {
  const { id } = useParams<keyof Params>() as Params;
  const stepData = useAgentStepData(id);
  const isReportReady =
    stepData?.find((step) => step.type === AgentSessionStepType.PREPARE_REPORT)
      ?.status === AgentSessionStatus.COMPLETE;

  const ids = useReviewResponseIds(id);
  const selectedId = useSelectedId(id);
  const approved = useApprovedIds(id);
  const edited = useEditedIds(id);
  const tableRows = useTableRows(id);
  const filters = useReviewResponseFilters(id);
  const prevApproved = usePrevious(approved);
  const prevEdited = usePrevious(edited);
  const { updateTableRows } = useAgentSelector.useActions();

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [visibleRows, setVisibleRows] = useState(new Set<string>());
  const [selectedSourceIndex, setSelectedSourceIndex] = useState<
    number | undefined
  >(undefined);
  const [isExpandedView, setIsExpandedView] = useState(false);

  const [isRiskModalOpen, setIsRiskModalOpen] = useState(false);
  const [isTextClamped, setIsTextClamped] = useState(false);
  const textRef = useRef<HTMLParagraphElement>(null);
  
  const { searchTerm, searchFor } = useReviewResponseSearch(id);

  const agentData = useAgentData(id);

  const { agentType, agentSubType } = getAgentTypeFromId(id);

  const helperData = helper[agentSubType];
  const { updateAgentMainData, setSelectedId } = useAgentSelector.useActions();

  // Subscribe to session events
  useControlStatus(id);
  useSessionSubscription(id);
  useControlEventsSubscription(id);
  
  //accepted notification
  useAgentNotifications(id);


  useEffect(() => {
    if (ids.length > 0 && approved.length === ids.length) {
      void handleAgentAutoSave(id, agentType, agentSubType, true);
    }
  }, [agentType, agentSubType, approved.length, id, ids.length]);

  // effect to watch for maturity changes in review responses
  useEffect(() => {
    const agentData = getAgentData(id);
    if (!agentData?.mainData?.reviewResponseData) return;

    // Check each response for maturity changes
    for (const [responseId, response] of agentData.mainData.reviewResponseData) {
      if (!response) continue;

      // to find maturity level in response data
      const maturityItem = response.find(item => 
        item.key === 'current_maturity_level'
      );

      if (maturityItem?.value) {
        updateTableRows(id, responseId, 'maturity' as keyof ReviewResponseTableRow, maturityItem.value);
      }
    }
  }, [
    id, 
    updateTableRows,
    agentData?.mainData?.reviewResponseData,
  ]);

  const tableRowsSelectedCount = useMemo(
    () =>
      ids.reduce(
        (count, id) =>
          selectedRows.includes(id) && !approved.includes(id)
            ? count + 1
            : count,
        0
      ),
    [ids, selectedRows, approved]
  );

  const onClickAvatar = useCallback(
    (rowId: string, index: number) => {
      if (selectedRows.length > 0) {
        // disable the split view i.e dont show the edit review section if the a row is selected
        return;
      }
      setSelectedSourceIndex(index);
      setSelectedId(id, rowId);
    },
    [id, selectedRows, setSelectedId]
  );

  const tableColumns: ZTableColumnProps<
    AgentSpecificTableRowData<typeof agentType, typeof agentSubType>
  >[] = useMemo(() => {
    return tableConfigs[agentSubType].map((column) => ({
      ...column,
      renderCell: (
        data: AgentSpecificTableRowData<typeof agentType, typeof agentSubType>
      ) =>
        renderTableComponents({
          renderType: column.renderType,
          data,
          metaData: {
            approved,
            edited,
            agentId: id,
            agentSubType,
          },
          onClickAvatar,
        }),
    })) as ZTableColumnProps<
      AgentSpecificTableRowData<typeof agentType, typeof agentSubType>
    >[];
  }, [agentSubType, approved, edited, id, onClickAvatar]);

  const tableRowsSelectableCount = useMemo(() => ids.length - approved.length, [
    ids,
    approved,
  ]);

  const closeSplitView = useCallback(() => {
    setSelectedId(id, "");
    setSelectedSourceIndex(undefined);
  }, [setSelectedId, id]);

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      closeSplitView();
      const newSearchTerm = e.target.value.trimStart().toLowerCase();
      updateAgentMainData(id, { searchTerm: newSearchTerm });
    },
    [id, updateAgentMainData, closeSplitView]
  );

  const filteredRows = useMemo(() => {
    if (!filters || filters.length === 0) return tableRows || [];

    const statusLookup = {
      approved: new Set(approved),
      edited: new Set(edited),
    };

    const groupedFilters: Record<string, FilterOption[]> = {};
    filters.forEach((filter) => {
      if (filter.key) {
        if (!groupedFilters[filter.key]) {
          groupedFilters[filter.key] = [];
        }
        groupedFilters[filter.key].push(filter);
      }
    });

    const filterFunctions: Record<
      string,
      (
        row: AgentSpecificTableRowData<typeof agentType, typeof agentSubType>,
        filterGroup: FilterOption[]
      ) => boolean
    > = {
      status: (row, filterGroup) => {
        if (!row || !row.id) return false;
        const isApproved = statusLookup.approved.has(row.id);
        const isEdited = statusLookup.edited.has(row.id);
        const status = isApproved
          ? "accepted"
          : isEdited
            ? "edited"
            : "pending";
        return filterGroup.some((filter) => status === filter.value);
      },
      default: (row, filterGroup) => {
        if (!row) return false;
        return filterGroup.some((filter) => {
          if (!filter.key || !(filter.key in row)) return false;
          const fieldValue = String(
            row[filter.key as keyof typeof row] || ""
          ).toLowerCase();
          const filterValue = String(filter.value).toLowerCase();

          const lowerCaseTrimReplaceDash = (str: string) =>
            str.toLowerCase().replace(/-/g, " ");

          switch (filter.operator) {
            case FilterOperators.BETWEEN: {
              const numValue = (row[
                filter.key as keyof typeof row
              ] as unknown) as number;

              if (
                isNaN(filter.value?.min) ||
                isNaN(filter.value?.max) ||
                isNaN(numValue)
              ) {
                return false;
              }

              return (
                numValue >= filter.value.min && numValue <= filter.value.max
              );
            }
            case FilterOperators.EQUALS:
              return (
                lowerCaseTrimReplaceDash(fieldValue) ===
                lowerCaseTrimReplaceDash(filterValue)
              );
            case FilterOperators.NOT_EQUALS:
              return fieldValue !== filterValue;
            case FilterOperators.CONTAINS:
              return fieldValue.includes(filterValue);
            case FilterOperators.NOT_CONTAINS:
              return !fieldValue.includes(filterValue);
            default:
              return true;
          }
        });
      },
    };

    return (tableRows || []).filter(Boolean).filter((row) => {
      if (!row) return false;
      return Object.entries(groupedFilters).every(([key, filterGroup]) => {
        const filterFunction = filterFunctions[key] || filterFunctions.default;
        return filterFunction(row, filterGroup);
      });
    });
  }, [filters, tableRows, approved, edited]);

  const fuse = useMemo(() => {
    let searchItems: AgentSpecificTableRowData<
      typeof agentType,
      typeof agentSubType
    >[];

    if (!filteredRows) {
      searchItems = [];
    } else {
      searchItems = [...filteredRows];
    }

    return new Fuse(searchItems, {
      keys: (searchFor as string[]) || [],
      threshold: 0.3,
      findAllMatches: true,
      ignoreLocation: true,
    });
  }, [filteredRows, searchFor]);

  const filteredAndSearchedRows = useMemo(() => {
    const temp = [...filteredRows];
    // set isEdited and isApproved flags
    temp.map((row) => {
      if (row.id) {
        row.status = approved.includes(row.id)
          ? "approved"
          : edited.includes(row.id)
          ? "edited"
          : "none";
      }
      return row;
    });
    if (!searchTerm || searchTerm.trim() === "") return temp || [];

    return fuse
      .search(searchTerm)
      .map((result) => result.item)
      .filter(Boolean);
  }, [filteredRows, fuse, searchTerm, approved]);

  const setupIntersectionObserver = useCallback(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const id = entry.target.id.replace("table-row-", "");
          setVisibleRows((prev) => {
            const newSet = new Set(prev);
            if (entry.isIntersecting) {
              newSet.add(id);
            } else {
              newSet.delete(id);
            }
            return newSet;
          });
        });
      },
      { threshold: 0.5 }
    );

    ids.forEach((id) => {
      const element = document.getElementById(`table-row-${id}`);
      if (element) observer.observe(element);
    });

    return () => observer.disconnect();
  }, [ids, isExpandedView]);

  useEffect(() => {
    const cleanup = setupIntersectionObserver();
    return cleanup;
  }, [setupIntersectionObserver]);

  useEffect(() => {
    if (selectedRows.length > 1 && selectedId) {
      setSelectedId(id, "");
    }
  }, [id, selectedId, selectedRows.length, setSelectedId]);

  useEffect(() => {
    const isSelectedRowInFilteredAndSearchedRows = filteredAndSearchedRows.some(
      (item) => item.id === selectedId
    );
    if (!isSelectedRowInFilteredAndSearchedRows) {
      closeSplitView();
    }
  }, [filteredAndSearchedRows, selectedId, closeSplitView]);

  // Auto-select first row only for FAIR assessments
  useEffect(() => {
    const agentData = getAgentData(id);
    const isFairAssessment = agentData?.agentType === AGENT_TYPES.RISK_ASSESSMENT && 
                            agentData?.subType === "fair_ra";

    // Only auto-select for FAIR assessments
    if (isFairAssessment && filteredAndSearchedRows.length > 0 && !selectedId) {
      setSelectedId(id, filteredAndSearchedRows[0].id);
    }
  }, [filteredAndSearchedRows, selectedId, id, setSelectedId]);

  // Use type assertion to bypass the type check
  const riskStatement = (agentData?.sessionData as any)?.fair_ra?.risk_statement || '';
  
  // Get the overall risk rating from the first row (which is the overall risk)
  const overallRiskRating = (tableRows as any)?.find((row: any) => row.breakdown === 'Overall Risk')?.rating || '';

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      setIsTextClamped(element.scrollHeight > element.clientHeight);
    }
  }, []);


  if (isExpandedView) {
    return (
      <AgentExpandedView
        agentId={id}
        agentType={agentType}
        agentSubType={agentSubType}
        onClose={() => setIsExpandedView(false)}
      />
    );
  }

  return (
    <ErrorBoundary fallback={null}>
      <div
        className={clsx(
          "h-full w-full overflow-hidden flex flex-col",
          props.disableRoundedBorder && "rounded-none"
        )}
      >
        <div className="px-6 py-4 flex flex-col bg-white gap-2 border-b border-border">
          {!props.hideAgentTypeFromHeader && (
            <div className="flex items-center gap-2">
              <h3 className="text-base font-medium leading-5 text-[#171717]">
                {agentType === AGENT_TYPES.RISK_ASSESSMENT && agentSubType !== RiskAssessmentTypes.FAIR || agentType === AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT || agentType === AGENT_TYPES.GAP_ASSESSMENT ? helperData.title : "Overall Risk"}
              </h3>
              {agentType === AGENT_TYPES.RISK_ASSESSMENT && agentSubType === RiskAssessmentTypes.FAIR && overallRiskRating && (
                <Chip 
                  className="px-3 py-0 h-6" 
                  classNames={{ 
                    base: cn(
                      'h-5',
                      overallRiskRating === 'High' ? 'bg-red-100' : 
                      overallRiskRating === 'Moderate' ? 'bg-[#FDEDD3]' : 
                      overallRiskRating === 'Low' ? 'bg-green-100' :
                      overallRiskRating === 'Very High' ? 'bg-red-200' :
                      overallRiskRating === 'Very Low' ? 'bg-green-200' :
                      'bg-gray-100'
                    ),
                    content: cn(
                      'flex items-center p-0',
                      overallRiskRating === 'High' ? 'text-red-500' : 
                      overallRiskRating === 'Moderate' ? 'text-[#F5A524]' : 
                      overallRiskRating === 'Low' ? 'text-green-500' :
                      overallRiskRating === 'Very High' ? 'text-red-700' :
                      overallRiskRating === 'Very Low' ? 'text-green-700' :
                      'text-gray-800'
                    )
                  }}
                >
                  <span className="text-[12px] leading-3 ">{overallRiskRating}</span>
                </Chip>
              )}
            </div>
          )}
          <div className="flex justify-between items-center py-1 ">
            {agentType === AGENT_TYPES.RISK_ASSESSMENT && agentSubType !== RiskAssessmentTypes.FAIR ||
            agentType === AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT || agentType === AGENT_TYPES.GAP_ASSESSMENT? (
              <div className="flex grow gap-2">
                {tableRowsSelectedCount === 0 ? (
                  <>
                  <AgentFilters
                    agentId={id}
                    agentType={agentType}
                    agentSubType={agentSubType}
                  />
                  <Input
                    classNames={{
                      inputWrapper: "rounded-md h-9 min-h-9",
                      mainWrapper: "max-h-9",
                    }}
                    className="grow max-w-96 h-9"
                    placeholder="Enter keywords to search"
                    value={agentData?.mainData.searchTerm ?? ""}
                    onChange={handleSearch}
                  />
                </>
              ) : (
                <div className="flex gap-3 items-center py-2">
                  <p className="text-sm  text-[#44444B]">
                    {tableRowsSelectedCount === tableRowsSelectableCount
                      ? "All"
                      : tableRowsSelectedCount}{" "}
                    {tableRowsSelectedCount === 1 ? "control" : "controls"}{" "}
                    selected
                  </p>
                </div>
              )}
            </div>
            ) : 
            <div className="flex grow gap-2">
            <div className="w-full mb-1">
                <div className="w-full">
                    <p ref={textRef} className="text-sm text-[#44444B] whitespace-pre-wrap line-clamp-3">
                        {riskStatement}
                    </p>
                    {isTextClamped && riskStatement && riskStatement.length > 0 && (
                        <button 
                            onClick={() => setIsRiskModalOpen(true)}
                            className="text-sm text-[#006FEE] font-semibold"
                            type="button"
                        >
                            see more
                        </button>
                    )}
                </div>
            </div>
        </div>
            }
            {selectedRows.length > 0 ? (
              <AgentActions
                agentId={id}
                selectedId={selectedId ?? ""}
                isApproved={approved.includes(selectedId ?? "")}
                selectedRows={selectedRows}
                resetSelectedRows={() => {
                  setSelectedRows([]);
                }}
              />
            ) : null}
          </div>
        </div>
        <div className="grow overflow-hidden flex">
          <div 
            className={cn(
              "flex-1 flex flex-col bg-white overflow-auto",
              agentType === AGENT_TYPES.RISK_ASSESSMENT && agentSubType === "fair_ra" ? "w-1/2" : "flex-1"
            )}
          >
            <ZTable<
              AgentSpecificTableRowData<typeof agentType, typeof agentSubType>
            >
              rows={filteredAndSearchedRows}
              columns={tableColumns}
              isReportReady={isReportReady}
              visibleColumns={
                selectedId
                  ? tableColumns.filter(
                    ({ fieldName }) =>
                      ["id", "control", "question", "requirement", "Breakdown"].indexOf(
                        fieldName
                      ) > -1
                  )
                  : tableColumns
              }
              isRowDisabled={({ row: { id } }) => {
                return approved?.includes?.(id) || false;
              }}
              isRowSelectable={({ row: { id } }) => {
                return !(approved?.includes?.(id) || false);
              }}
              isRowActive={({ row: { id } }) => {
                return selectedId === id;
              }}
              onRowSelectionModelChange={(newSelection) => {
                const filteredSelection = newSelection.filter(
                  (id) => !(approved?.includes?.(id.toString()) || false)
                );
                if (selectedId) closeSplitView();
                setSelectedRows(filteredSelection as string[]);
              }}
              rowSelectionModel={selectedRows}
              tableRowSelectionCheckboxProps={({ row }) => {
                const isApproved = approved?.includes?.(row.id) || false;
                return {
                  className: cn(
                    "group-hover:opacity-100 opacity-0",
                    (isApproved ||
                      selectedRows.length > 0 ||
                      selectedId === row.id) &&
                    "opacity-100"
                  ),
                };
              }}
              isHeaderSticky={true}
              isCompact={true}
              removeWrapper={true}
              classNames={{
                td: [
                  "group-data-[first=true]:first:before:rounded-none",
                  "group-data-[first=true]:last:before:rounded-none",
                  "group-data-[middle=true]:before:rounded-none",
                  "group-data-[last=true]:first:before:rounded-none",
                  "group-data-[last=true]:last:before:rounded-none",
                  "py-2 px-2",
                ],

                tr: "hover:bg-[#ECECEC] group bg-[#FEFEFE]",
                thead:
                  "rounded-none [&>tr>th]:bg-red [&>tr]:first:rounded-none [&>tr]:first:shadow-none [&>tr:first-of-type>th:first-of-type]:rounded-none [&>tr:first-of-type>th:last-of-type]:rounded-none",
                th: cn(isReportReady && agentSubType !== RiskAssessmentTypes.FAIR ? "pl-7 pb-5 pt-5" : "px-2"),
              }}
              tableHeaderProps={{
                className: cn(
                  "shadow-none rounded-none",
                  isReportReady && agentSubType !== RiskAssessmentTypes.FAIR && "pl-6"
                ),
              }}
              tableColumnProps={({ fieldName }) => {
                return {
                  align:
                    ["riskLevel", "confidence"].indexOf(fieldName) > -1
                      ? "center"
                      : "start",
                };
              }}
              tableBodyProps={{
                emptyContent: "No controls found",
              }}
              tableRowProps={({ id: rowId }) => {
                return {
                  className: cn(
                    "p-5",
                    `border-y ${filteredAndSearchedRows.length > 0 &&
                      rowId === filteredAndSearchedRows[0].id
                      ? "border-t-0"
                      : ""
                    }`,
                    rowId === selectedId &&
                    "!bg-[#D6E5F8] !bg-opacity-50 border-l-[#006FEE] border-l-[2px] rounded-tl-md rounded-bl-md",
                    "cursor-pointer",
                    "&:hover:bg-[#F2F2F2]",
                    selectedRows.includes(rowId) &&
                    !approved?.includes?.(rowId) &&
                    "!bg-[#D6E5F8] !bg-opacity-100"
                  ),
                  id: `table-row-${rowId}`,
                  onClick: () => {
                    setSelectedId(id, rowId);
                    setSelectedSourceIndex(undefined);
                  },
                };
              }}
              tableCellProps={{
                className: cn(
                  "py-4",
                  isReportReady && agentSubType !== RiskAssessmentTypes.FAIR ? "pl-6 pb-6 pt-6" : "px-2"
                ),
              }}
              agentSubType={agentSubType}
              aria-label="Review Responses List"
            />
          </div>
          {(selectedId || (agentType === AGENT_TYPES.RISK_ASSESSMENT && agentSubType === "fair_ra")) && (
            <div className="w-1/2 overflow-y-auto p-4 border-l bg-white bg-transparent bg-opacity-50">
              <AgentResponse
                onClickExpand={() => setIsExpandedView(true)}
                closeSplitView={closeSplitView}
                selectedSourceIndex={selectedSourceIndex}
                agentId={id}
                agentType={agentType}
                agentSubType={agentSubType}
                isSelectedRowVisible={visibleRows.has(selectedId || '')}
              />
            </div>
          )}
        </div>
      </div>
       {/* Risk Statement Modal */}
       <Modal 
        isOpen={isRiskModalOpen} 
        onClose={() => setIsRiskModalOpen(false)}
        size="md"
      >
        <ModalContent>
          <ModalHeader className="font-bold justify-center">Risk Statement</ModalHeader>
          <ModalBody className="pb-6">
            <p className="text-sm text-[#44444B] whitespace-pre-wrap">
              {riskStatement}
            </p>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ErrorBoundary>
  );
};

export default ReviewResponse;
