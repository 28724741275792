import {
  AGENT_TYPES,
  AgentSubTypes,
  GapAssessmentTypes,
  MultiVendorAssessmentTypes,
  QuestionnaireTypes,
  RiskAssessmentTypes,
} from '../types/index.ts';
import { handleAutoSaveDora } from "../use-cases/dora.use-case";
import { handleFairAutoSave } from "../use-cases/fair.use-case";
import { handleQuestionnaireAutoSave } from "../use-cases/quesitionnaire.use-case";
import { handleRiskAssessmentAutoSave } from "../use-cases/risk.use-case";
import { handleAutoSaveSoc2 } from "../use-cases/soc2.use-case";
import { handleAutoSaveOfVendorAssessmentResponse } from "../use-cases/vendor.use-case";

/** This is a common interface for all the auto-save handlers.
 * It is used to pass the necessary arguments to the auto-save handler.
 * @param {string} agentId - The ID of the agent.
 * @param {boolean} markAsComplete - Whether to mark the agent as complete.
 * @param {any} data - Any additional data to be passed to the auto-save handler.
 **/
export interface AutoSaveFunctionArgs {
  agentId: string;
  markAsComplete?: boolean;
  data?: any;
}

const autoSaveHandlers = {
  [QuestionnaireTypes.QA]: handleQuestionnaireAutoSave,
  [RiskAssessmentTypes.NIST_CSF_2]: handleRiskAssessmentAutoSave,
  [RiskAssessmentTypes.NIST_AI_RMF]: handleRiskAssessmentAutoSave,
  [RiskAssessmentTypes.FAIR]: handleFairAutoSave,
  [GapAssessmentTypes.SOC2]: handleAutoSaveSoc2,
  [GapAssessmentTypes.SOC2_TYPE1]: handleAutoSaveSoc2,
  [GapAssessmentTypes.SOC2_TYPE2]: handleAutoSaveSoc2,
  [GapAssessmentTypes.DORA]: handleAutoSaveDora,
  [MultiVendorAssessmentTypes.VENDOR_ASSESSMENT]:
    handleAutoSaveOfVendorAssessmentResponse,
  // The following are not really needed, just here to keep tsc happy,
  // without having to make MultiVendorAssessmentTypes a Partial or Omit these keys from it
  [MultiVendorAssessmentTypes.VENDOR_SUPPLY_CHAIN_TABLE]: () => {},
  [MultiVendorAssessmentTypes.VENDOR_INTAKE_FORM]: () => {},
};

/** This function is used to handle the auto-save functionality for an agent.
 * It takes in the agent ID, the agent type, the agent sub-type, whether to mark the agent as complete, and any additional data.
 * It then calls the appropriate auto-save handler based on the agent sub-type.
 * If the agent sub-type is not recognized, it throws an error.
 * @param {string} id - The ID of the agent.
 * @param {AGENT_TYPES} agentType - The type of the agent.
 * @param {AgentSubTypes} agentSubType - The sub-type of the agent.
 * @param {boolean} markAsComplete - Whether to mark the agent as complete.
 * @param {any} data - Any additional data to be passed to the auto-save handler.
 **/
export const handleAgentAutoSave = async (
  id: string,
  agentType: AGENT_TYPES,
  agentSubType: AgentSubTypes,
  markAsComplete?: boolean,
  data?: any,
) => {
  const handler = autoSaveHandlers[agentSubType];

  if (!handler) {
    throw new Error("Invalid agent type");
  }

  const autoSaveArgs: AutoSaveFunctionArgs = {
    agentId: id,
    markAsComplete: markAsComplete,
    data: data,
  };

  await (handler as typeof handleRiskAssessmentAutoSave)(autoSaveArgs);
};
