import { useChatStore } from "../states";
import MessageCard from "./message-card";
import React from "react";
import { ScrollShadow } from "@nextui-org/react";

const Conversation = React.memo(() => {
  const messages = useChatStore((state) => state.messages);
  const isGenerating = useChatStore((state) => state.isGenerating);

  return (
    <ScrollShadow className="flex h-full flex-col-reverse thin-scrollbar mt-0.5 scroll-mr-1" id="conversation">
      <div className="mb-2">
        <div className={`flex flex-col gap-4 px-1  ${isGenerating ? 'mb-2' : 'mb-4'}`}>
          {messages.map(({ role, message, created_at, type, all_sources }, index) => (
            message?.length ? (
              <MessageCard
                key={index}
                createdAt={created_at}
                role={role as 'user' | 'assistant'}
                type={type}
                attempts={index === 1 ? 2 : 1}
                allSources={all_sources}
                currentAttempt={index === 1 ? 2 : 1}
                message={message}
                messageClassName={
                  role === 'user'
                    ? 'bg-content3 text-content3-foreground'
                    : ''
                }
                showFeedback={role === 'assistant'}
              />
            ) : null
          ))}
          {isGenerating && (
            <div className="flex text-sm ml-12 text-purple-500 mb-2">
              Generating <div className="pl-1 animate-bounce">..</div>
            </div>
          )}
        </div>
      </div>
    </ScrollShadow>
  );
});

Conversation.displayName = 'Conversation';

export default Conversation;
