import { LoadingSpinner } from "@/shared/components/custom/spin";
import { addNotification } from "@/shared/states/notification";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Tooltip,
} from "@nextui-org/react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ReviewSourceTypes } from "../../types";
import { SelectedSheetDataExcel } from "../../types/excel-mapper";
import {
  getJSONFromSheet,
  getSheetDataFromUrl,
} from "../../utils/excel-parser";
import { getFileNameFromUrl } from "../../utils/get-filename-from-url";
import ExcelMapper from "../excel-mapper";

interface ExcelPreviewProps {
  fileUrl: string;
  open: boolean;
  onClose: () => void;
  sheetName: string;
  setSelectedSource: React.Dispatch<
    React.SetStateAction<ReviewSourceTypes | undefined>
  >;
  selectedSource?: ReviewSourceTypes;
  listOfSourcesForSelectedSources?: ReviewSourceTypes[];
  highlightRow?: number;
  fileName?: string;
}

const ExcelPreview = ({
  fileUrl,
  open,
  onClose,
  sheetName,
  fileName,
  highlightRow,
  selectedSource,
  setSelectedSource,
  listOfSourcesForSelectedSources,
}: ExcelPreviewProps) => {
  const [loading, setLoading] = useState(true);
  const [sheetData, setSheetData] = useState<SelectedSheetDataExcel>();
  const [openCount, setOpenCount] = useState(0);

  const loadExcelData = useCallback(async () => {
    try {
      setLoading(true);
      const { workbook } = await getSheetDataFromUrl(fileUrl);
      if (!workbook.Sheets[sheetName]) {
        sheetName = workbook.SheetNames[0];
      }
      const { data, merges } = await getJSONFromSheet(workbook, sheetName);
      setSheetData({ data, merges, selectedSheet: sheetName });
    } catch (e) {
      addNotification({
        message: "Error loading excel file",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [fileUrl, sheetName]);

  useEffect(() => {
    if (open) {
      void loadExcelData();
      setOpenCount((prev) => prev + 1);
    }
  }, [open, loadExcelData]);

  const findindex = useMemo(() => {
    if (listOfSourcesForSelectedSources) {
      return (
        listOfSourcesForSelectedSources.findIndex(
          (d) => d.source_number === selectedSource?.source_number
        ) + 1
      );
    }
    return 0;
  }, [listOfSourcesForSelectedSources, selectedSource]);

  const isNextAvailable = useMemo(() => {
    if (listOfSourcesForSelectedSources) {
      const findindex = listOfSourcesForSelectedSources.findIndex(
        (d) => d.source_number === selectedSource?.source_number
      );

      return findindex < listOfSourcesForSelectedSources.length - 1;
    }
    return false;
  }, [listOfSourcesForSelectedSources, selectedSource]);
  const handleNext = useCallback(() => {
    if (listOfSourcesForSelectedSources && isNextAvailable) {
      const findindex = listOfSourcesForSelectedSources.findIndex(
        (d) => d.source_number === selectedSource?.source_number
      );
      setSelectedSource(listOfSourcesForSelectedSources[findindex + 1]);
    }
  }, [
    listOfSourcesForSelectedSources,
    setSelectedSource,
    isNextAvailable,
    selectedSource,
  ]);

  const isPreviousAvailable = useMemo(() => {
    if (listOfSourcesForSelectedSources) {
      const findindex = listOfSourcesForSelectedSources.findIndex(
        (d) => d.source_number === selectedSource?.source_number
      );

      return findindex > 0;
    }
    return false;
  }, [listOfSourcesForSelectedSources, selectedSource]);
  const handlePrevious = useCallback(() => {
    if (listOfSourcesForSelectedSources && isPreviousAvailable) {
      const findindex = listOfSourcesForSelectedSources.findIndex(
        (d) => d.source_number === selectedSource?.source_number
      );
      setSelectedSource(listOfSourcesForSelectedSources[findindex - 1]);
    }
  }, [
    listOfSourcesForSelectedSources,
    setSelectedSource,
    isPreviousAvailable,
    selectedSource,
  ]);

  return (
    <Modal
      className="max-h-[calc(100vh-32px)] max-w-[calc(100vw-200px)] !rounded-[8px] overflow-y-hidden !my-auto"
      style={{
        borderRadius: "8px !important",
      }}
      size="full"
      isOpen={open}
      onClose={onClose}
    >
      <ModalContent className="flex flex-col my-0">
        {(_) => (
          <>
            <ModalHeader className="border-b border-solid border-[#E4E4E7]">
              <div className="flex gap-3 items-center ">
                <div className="flex gap-3 items-center">
                  <div className="flex gap-1 items-center pr-3 border-r border-[#E4E4E7] border-solid">
                    <Tooltip
                      content="Previous Reference"
                      classNames={{
                        content:
                          "bg-black bg-opacity-80 backdrop-blur-md text-white text-tiny",
                        arrow: "bg-black bg-opacity-80 backdrop-blur-md",
                      }}
                    >
                      <div
                        className="select-none bg-white cursor-pointer border-[1px] h-5 w-5 flex items-center justify-center rounded-[50%] border-solid border-[#E4E4E7]"
                        onClick={handlePrevious}
                      >
                        <ChevronLeft
                          color={isPreviousAvailable ? "#A1A1AA" : "#E4E4E7"}
                          size={16}
                        />
                      </div>
                    </Tooltip>

                    <div className="text-[12px] leading-5 font-medium text-[#71717A] w-11 text-center">
                      {findindex} of {listOfSourcesForSelectedSources?.length}
                    </div>

                    <Tooltip
                      content="Next Reference"
                      classNames={{
                        content:
                          "bg-black bg-opacity-80 backdrop-blur-md text-white text-tiny",
                        arrow: "bg-black bg-opacity-80 backdrop-blur-md",
                      }}
                    >
                      <div
                        className="select-none bg-white cursor-pointer border-[1px] h-5 w-5 flex items-center justify-center rounded-[50%] border-solid border-[#E4E4E7]"
                        onClick={handleNext}
                      >
                        <ChevronRight
                          color={isNextAvailable ? "#A1A1AA" : "#E4E4E7"}
                          size={16}
                        />
                      </div>
                    </Tooltip>
                  </div>

                  <p className="text-sm font-medium">
                    {fileName || getFileNameFromUrl(fileUrl)}
                  </p>
                </div>
              </div>
            </ModalHeader>
            <ModalBody className="grow overflow-auto p-0">
              {loading || !sheetData ? (
                <div className="flex ">
                  <LoadingSpinner className="mx-auto" />
                </div>
              ) : (
                <ExcelMapper
                  selectedSheetData={sheetData}
                  showHeader={false}
                  setSelectedSheet={() => {}}
                  allowSelection={false}
                  scrollToRowSelection={openCount}
                  highlightedRow={
                    highlightRow
                      ? new Map([
                          [
                            Number(highlightRow),
                            {
                              backgroundColor: "#EFFBEF",
                            },
                          ],
                        ])
                      : new Map()
                  }
                />
              )}
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ExcelPreview;
