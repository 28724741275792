import { Chip } from "@nextui-org/react";
import type React from "react";
import { VendorRiskLevel } from "./OverviewCards.types";

type Props = {
  riskLevel: VendorRiskLevel;
};

const RiskLevelChip = ({ riskLevel }: Props) => {
  return riskLevel === 'High' ? (
    <Chip className="px-3 py-0 h-6" classNames={{ base: 'h-5 bg-[#FDD0DF]', content: 'text-[#F31260] flex items-center p-0' }}><span className="text-[12px] leading-3">High Risk</span></Chip>
  ) : riskLevel === 'Medium' ? (
    <Chip className="px-3 py-0 h-6" classNames={{ base: 'h-5 bg-[#FDEDD3]', content: 'text-[#F5A524] flex items-center p-0' }}><span className="text-[12px] leading-3">Medium Risk</span></Chip>
  ) : (
    <Chip
      className="px-3 py-0 h-6"
      classNames={{
        base: "h-5 bg-[#D1F4E0]",
        content: "text-[#45D483] flex items-center p-0",
      }}
    >
      <span className="text-[12px] leading-3">Low Risk</span>
    </Chip>
  );
};

export default RiskLevelChip;
