import { ZButton } from "@/shared/components/button";
import { ZProgress } from "@/shared/components/progress";
import { ZTooltip } from "@/shared/components/ZTooltip/ZTooltip";
import usePrevious from "@/shared/hooks/use-previous";
import { getSignedUrl } from "@/shared/requests/get-signed-url";
import { deleteFileReq, uploadFileReq } from "@/shared/requests/upload-file";
import { addNotification } from "@/shared/states/notification";
import { useIsDemoActive } from "@/shared/states/user";
import { UploadedFile, UploadProgress } from "@/shared/types/file-upload";
import { cn } from "@/shared/utils/classname-merger";
import { Spinner } from "@nextui-org/react";
import { AxiosProgressEvent } from "axios";
import { FileIcon, RotateCw, XIcon } from "lucide-react";
import React, {
  ChangeEvent,
  DragEvent,
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { v4 as uuidv4 } from "uuid";
const typeMap: { [key: string]: string } = {
  "application/json": "JSON",
  "application/msword": "Word",
  "application/pdf": "PDF",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "Word",
};

export const simplifyFileTypes = (types: string[]): string[] => {

  if (types.length === 0) {
    return [...new Set(Object.keys(typeMap))];
  }

  return [...new Set(types.map((type) => typeMap[type] || type))];
};

interface FileUploadProps {
  isMultiple: boolean;
  maxFiles: number;
  allowedFileTypes: string[];
  setFiles?: (uploadFiles: UploadedFile[], type: string) => void;
  type?: string;
  orientation?: "vertical" | "horizontal";
  isShowNewFilesSection?: boolean;
  displayOnlyButton?: boolean;
  displayContainer?: any;
  displayChildren?: boolean;
  children?: any;
  reset?: boolean;
}

const AiFileUpload = forwardRef<HTMLDivElement, FileUploadProps>(
  (
    {
      isMultiple,
      maxFiles,
      allowedFileTypes,
      setFiles,
      type,
      displayContainer,
      children,
      displayChildren,
      reset
    }, ref
  ) => {
    const [uploadFiles, setUploadFiles] = useState<UploadedFile[]>([]);
    const prevUploadFiles = usePrevious(uploadFiles) || [];
    const [uploadProgress, setUploadProgress] = useState<UploadProgress>({});
    const inputRef: React.RefObject<HTMLInputElement> = useRef(null);
    const isDemo = useIsDemoActive();

    const isFileTooLarge = (file: File, megaBytes = 20) => {
      const sizeLimit = megaBytes * 1024 * 1024;
      return file.size > sizeLimit;
    };

    useEffect(() => {
      if (reset) {
        setUploadFiles([]);
        setUploadProgress({});
      }
    }, [reset]);
    useEffect(() => {
      if (setFiles && prevUploadFiles !== uploadFiles) {
        setFiles(uploadFiles, type ?? "");
      }
    }, [uploadFiles, setFiles, prevUploadFiles, type]);

    const handleFiles = async (uploadedFiles: FileList) => {
      let counter = uploadFiles.length;
      let shouldShowFileTooLargeNotification = false;
      const largeFileNames: string[] = [];

      const files: UploadedFile[] = [];

      let validFilesTypes = Array.from(uploadedFiles).filter(file => Object.keys(typeMap).includes(file.type) || Object.values(typeMap).includes(file.type))
      for (const file of Array.from(validFilesTypes)) {
        let alreadyAdded = false;
        if (counter >= maxFiles) {
          files.push({
            id: uuidv4(),
            file,
            preview: URL.createObjectURL(file),
            status: "too_many_files",
            progressBar: false,
            type,
          });
          alreadyAdded = true;
        }
        counter = counter + 1;

        const fileTooLarge = isFileTooLarge(file);
        shouldShowFileTooLargeNotification =
          shouldShowFileTooLargeNotification || fileTooLarge;

        if (fileTooLarge) {
          largeFileNames.push(file.name);
        }

        if (!alreadyAdded) {
          files.push({
            id: uuidv4(),
            file,
            preview: URL.createObjectURL(file),
            status: fileTooLarge ? "too_large" : "gettingurl",
            progressBar: false,
            type,
          });
        }
      }

      const fileName = new Set<string>(
        uploadFiles.map((oldFile) => oldFile.file.name)
      );
      for (const file of files) {
        if (fileName.has(file.file.name)) {
          if (file.status !== "too_many_files") {
            file.status = "duplicate_files";
          }
        } else {
          fileName.add(file.file.name);
        }
      }

      // uploadFiles.push(...files);
      const newUploadFiles = [...files];

      if (maxFiles && counter > maxFiles) {
        addNotification({
          type: "warn",
          title: "Too many files!",
          message: `You can only upload up to ${maxFiles} for this section. Please remove ${counter - maxFiles
            } files to proceed.`,
        });
      }

      if (shouldShowFileTooLargeNotification) {
        addNotification({
          type: "error",
          title: "File size limit exceeded!",
          message:
            largeFileNames.length > 1
              ? `${largeFileNames.length} files exceed the maximum file size of 20mb. Please remove them to proceed.`
              : `The file ${largeFileNames[0]} exceeds the maximum file size of 20mb. Please remove it to proceed.`,
        });
      }

      const validFilesArray = newUploadFiles.filter(
        (fileObj: UploadedFile) => fileObj.status === "gettingurl"
      );
      const invalidFilesArray = newUploadFiles.filter(
        (fileObj: UploadedFile) => fileObj.status !== "gettingurl"
      );

      await Promise.all(
        validFilesArray.map(async (fileObj) => {
          try {
            const url = await getSignedUrl({
              file_names: [fileObj.file.name],
              ...(isDemo
                ? {
                  max_age: 120,
                }
                : {}),
            });
            fileObj.signedUrl = url[0];
            fileObj.status = "inprogress";
            setUploadFiles((prevFiles) => [
              ...prevFiles,
              {
                ...fileObj,
                status: prevFiles.find(
                  (fil) => fil.file.name === fileObj.file.name
                )
                  ? "duplicate_files"
                  : fileObj.status,
              },
            ]);
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [fileObj.file.name]: 0,
            }));

            await uploadFile(fileObj);
          } catch (e) {
            console.error(e);
            setUploadFiles((prevFiles) => [
              ...prevFiles,
              { ...fileObj, status: "failed" },
            ]);
            setUploadProgress((prevProgress) => {
              const newProgress = { ...prevProgress };
              delete newProgress[fileObj.file.name];
              return newProgress;
            });
          }
        })
      );
      setUploadFiles((prev) => [...prev, ...invalidFilesArray]);
    };

    const handleRetry = async (fileObj: UploadedFile) => {
      try {
        fileObj.status = "retrying";
        setUploadFiles((prevFiles) => [
          ...prevFiles.map((f) => (f.id === fileObj.id ? { ...fileObj } : f)),
        ]);
        const url = await getSignedUrl({
          file_names: [fileObj.file.name],
        });
        fileObj.signedUrl = url[0];
        fileObj.status = "inprogress";
        setUploadFiles((prevFiles) => [
          ...prevFiles.map((f) => (f.id === fileObj.id ? { ...fileObj } : f)),
        ]);
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [fileObj.file.name]: 0,
        }));

        await uploadFile(fileObj);
      } catch (e) {
        console.error(e);
        setUploadFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.id === fileObj.id ? { ...fileObj, status: "failed" } : f
          )
        );
        addNotification({
          message: "Error in uploading the file",
          type: "error",
        });
        setUploadProgress((prevProgress) => {
          const newProgress = { ...prevProgress };
          delete newProgress[fileObj.file.name];
          return newProgress;
        });
      }
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      document.getElementById("drop-area")?.classList.remove("bg-[#F9FAFB]");

      const { files } = e.dataTransfer;
      void handleFiles(files);
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (files) {
        void handleFiles(files);
        e.target.value = "";
      }
    };

    const uploadFile = async (fileObj: UploadedFile) => {
      const name = fileObj.file.name;
      if (!fileObj.signedUrl) {
        return;
      }
      try {
        await uploadFileReq(
          fileObj.signedUrl,
          fileObj.file,
          (progressEvent: AxiosProgressEvent) => {
            if (progressEvent.total) {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress((prevProgress) => ({
                ...prevProgress,
                [name]: progress,
              }));
            }
          }
        );
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [name]: -1,
        }));
        setUploadFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.file.name === name
              ? {
                ...f,
                status:
                  f.status === "duplicate_files"
                    ? "duplicate_files"
                    : "success",
              }
              : f
          )
        );
      } catch (error) {
        console.error(error);
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [name]: 100,
        }));

        setTimeout(() => {
          setUploadFiles((prevFiles) =>
            prevFiles.map((f) =>
              f.file.name === name ? { ...f, status: "failed" } : f
            )
          );
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [name]: -1,
          }));
        }, 1000);
      }
    };

    const removeFile = (fileId: string) => {
      const fileToRemove = uploadFiles.find((f) => f.id === fileId);
      if (!fileToRemove) return;

      let removeDuplicateFileFlag = true;
      setUploadFiles((prevFiles) => {
        const filterFiles = prevFiles.filter(({ id }) => id !== fileId);

        const newFiles: UploadedFile[] = [];

        let idx = 0;
        const fileName = new Set<string>();
        for (const file of filterFiles) {
          let status = file.status;

          if (
            file.status === "duplicate_files" &&
            fileToRemove.file.name === file.file.name &&
            removeDuplicateFileFlag
          ) {
            status = "success";
            removeDuplicateFileFlag = false;
          }

          if (file.status === "too_many_files" && idx < maxFiles) {
            status = "success";
          }

          idx++;

          newFiles.push({
            ...file,
            status,
          });
        }

        for (const file of newFiles) {
          if (fileName.has(file.file.name)) {
            if (
              file.status !== "too_many_files" &&
              file.status !== "too_large"
            ) {
              file.status = "duplicate_files";
            }
          } else {
            fileName.add(file.file.name);
          }
        }

        return newFiles;
      });

      if (fileToRemove.signedUrl) {
        void deleteFileReq(fileToRemove.signedUrl).catch(e => {
          if (e.response.statusText !== '"The specified blob does not exist."' && uploadProgress[fileToRemove.file.name] === 100) {
            throw e
          } else {
            console.error(e)
          }
        });

      }

      setUploadProgress((prevProgress) => {
        const newProgress = { ...prevProgress };
        delete newProgress[fileToRemove.file.name];
        return newProgress;
      });
    };

    const handleDropFile = () => {
      if (inputRef.current) {
        inputRef?.current.click();
      }
    };

    const doesFileHasError = useCallback((fileObj: UploadedFile) => {
      return (
        fileObj.status === "failed" ||
        fileObj.status === "too_large" ||
        fileObj.status === "too_many_files" ||
        fileObj.status === "duplicate_files"
      );
    }, []);

    const getFileNameSuffix = useCallback((fileObj: UploadedFile) => {
      let fileNameSuffix = "";

      switch (fileObj.status) {
        case "too_large":
          fileNameSuffix = " (too large)";
          break;
        case "too_many_files":
          fileNameSuffix = " (excess)";
          break;
        case "duplicate_files":
          fileNameSuffix = " (duplicate file)";
          break;
        default:
          break;
      }

      return fileNameSuffix;
    }, []);

    const displayFiles = (fileObj: UploadedFile, index: number) => {
      return (
        <div key={index} className="relative flex items-center justify-between border bg-white border-gray-200 p-1 mr-1 rounded-md">
          <FileIcon className={`${doesFileHasError(fileObj) ? 'text-red-500' : 'text-green-500'} w-4 h-4 mr-1`} />
          <div className="flex-1 flex flex-col justify-center ">
            <p
              className={cn(
                "text-xs leading-4 whitespace-nowrap",
                fileObj.status === "success"
                  ? "text-green-600"
                  : doesFileHasError(fileObj)
                    ? "text-red-600"
                    : "text-black"
              )}
            >
              {/* display max 15 character and then ... and then .file extension */}

              {fileObj.file.name.length > 20
                ?
                fileObj.file.name.slice(0, 20) + "..."

                : fileObj.file.name}

              {getFileNameSuffix(fileObj)}
            </p>
            {fileObj.status === "gettingurl" ? <Spinner size="sm" /> : null}
            <div>
              {fileObj.status !== "success" &&
                !isNaN(uploadProgress[fileObj.file.name]) &&
                uploadProgress[fileObj.file.name] != -1 ? (
                <ZProgress
                  aria-label="file-upload-progress"
                  value={uploadProgress[fileObj.file.name] || 0}
                  maxValue={100}
                  className={cn("h-[3px] mt-1 [&>*]:" + "bg-blue-600")}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
          {fileObj.status === "failed" || fileObj.status === "retrying" ? (
            <ZButton
              isIconOnly
              className="bg-transparent"
              onClick={() => void handleRetry(fileObj)}
              isLoading={fileObj.status === "retrying"}
            >
              <RotateCw size={16} color="gray" />
            </ZButton>
          ) : null}
          <button
            className="absolute -top-2 -right-2 bg-gray-200 text-gray-600 hover:bg-gray-400 hover:text-white w-4 h-4 flex items-center justify-center rounded-full text-xs"
            onClick={(ev) => {
              ev.preventDefault();
              removeFile(fileObj.id)
            }}
          >
            <XIcon className="w-3 h-3" />
          </button>
        </div>
      )
    }

    const files = uploadFiles.map((fileObj: UploadedFile, index: number) => {
      return (
        <Fragment key={index}>
          {
            displayContainer && fileObj.file.name.length > 20 ?
              <ZTooltip content={fileObj.file.name} showArrow={true} delay={0} >
                {displayFiles(fileObj, index)}
              </ZTooltip >

              : <>{displayFiles(fileObj, index)}</>
          }
        </Fragment>
      )
    });

    return (
      <>
        {children(handleDropFile)}

        <input
          type="file"
          ref={inputRef}
          multiple={isMultiple}
          onChange={handleFileChange}
          className="hidden"
          id="file-input"
          accept={allowedFileTypes.join(",")}
        />

        {displayChildren ? <>
          {displayContainer && displayContainer.current ?
            createPortal(files, displayContainer.current)
            : (
              null
            )}</>
          : <div className="max-h-[200px] overflow-y-auto">{files}</div>}

      </>
    );
  }
);

AiFileUpload.displayName = "AiFileUpload";

export default AiFileUpload;
