import InlineSources from "@/modules/agent/components/review-responses/InlineSources";
import { useLoggedInMember } from "@/modules/auth/states";
import ZAvatarGradient from "@/shared/components/avatarGradient";
import ZaniaInverseIcon from "@/shared/icons/zania-inverse";
import { cn } from "@nextui-org/react";
import { useClipboard } from "@nextui-org/use-clipboard";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import ReactMarkdown from 'react-markdown';
import { AllSource } from "../types";
import FileViewer from "./FileViewer";
import { useChatStore } from "../states";

export type MessageCardProps = React.HTMLAttributes<HTMLDivElement> & {
  allSources?: AllSource[];
  showFeedback?: boolean;
  message: string;
  currentAttempt?: number;
  status?: "success" | "failed";
  attempts?: number;
  createdAt: string,
  messageClassName?: string;
  role: "user" | "assistant";
  type: undefined | 'warning' | 'error'
  onAttemptChange?: (attempt: number) => void;
  onMessageCopy?: (content: string | string[]) => void;
};

const MessageCard = React.forwardRef<HTMLDivElement, MessageCardProps>(
  ({
    message, showFeedback, attempts = 1, currentAttempt = 1,
    status, role, onMessageCopy, onAttemptChange, className, messageClassName,
    allSources, type, createdAt, ...props }, ref) => {



    const loggedInMember = useLoggedInMember();
    const messageRef = useRef<HTMLDivElement>(null);

    const [source, setSource] = useState<AllSource | undefined>(undefined);
    const { copy, copied } = useClipboard();
    const {isFloatingAskAiOpen} = useChatStore();

    const failedMessageClassName =
      type === "error" ? "bg-danger-100/50 border border-danger-100 text-foreground" : "";

    const hasFailed = type === "error";

    const handleCopy = () => {
      if (typeof message === "string") {
        copy(message);
      }
    };
    const preprocessString = (input: string) => {
      const punctuationList = ".,!?"; // you can change or extend this list as needed
      const escapedPunctuation = punctuationList.replace(
        /[-/\\^$*+?.()|[\]{}]/g,
        '\\$&'
      );
      const regex = new RegExp(`(\\[[^\\]]*\\])(\\s*)([${escapedPunctuation}]+)`, "g");
      return input.replace(regex, "$3$2$1");
    };


    const renderStringWithLists = (input: string) => {
      const fixedInput = preprocessString(preprocessString(input)); // Fix punctuation placement

      return (
        fixedInput.split(/(\[[^\]]*\])/).map((part, index) => {
          try {
            if (part.startsWith("[{") && part.endsWith("}]")) {
              const parsedArray = JSON.parse(part) as AllSource[];

              if (Array.isArray(parsedArray)) {
                return (
                  <InlineSources
                    tooltipPlacement={isFloatingAskAiOpen ? 'left' : 'right'}
                    key={index}
                    position="static pt-1 pb-2"
                    sources={parsedArray.map((source) => ({
                      source_number: source.source_number,
                      file_name: source.file_name,
                      mime_type: source.mime_type,
                      file_url: source.file_url,
                      source_file_name: source.file_name,
                      file_type: source.mime_type,
                    }))}
                    onSourceClick={(selectedSource) => setSource(selectedSource as AllSource)}
                    setListOfSourcesForSelectedSources={() => { }}
                  />
                );
              }
            }
          } catch (error) {
            console.error("Invalid JSON", part);
            return;
          }
          return <ReactMarkdown key={index}>{part}</ReactMarkdown>;
        })
      );
    };

    return (
      <div {...props} ref={ref} className={cn("relative flex gap-3", className)}>
        <div className="relative flex-none">
          {role === "user" ? (
            <ZAvatarGradient
              name={loggedInMember?.name ?? ""}
              size={32}
            />
          ) : (
            <ZaniaInverseIcon
              fill="white"
            />
          )
          }
        </div>
        <div className="flex w-full flex-col gap-4 group">
          <div

            className={cn(
              `relative w-full rounded-medium bg-content2 px-4 py-3 text-default-600 ${allSources?.length ? 'pb-8' : ''}`,
              failedMessageClassName,
              messageClassName,
            )}
          >
            <div ref={messageRef} className={`${isFloatingAskAiOpen ? 'pr-10' : 'pr-20'} text-small`} style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
              {hasFailed ? message : <>{renderStringWithLists(message) || ''}</>}
            </div>
            {
              role === 'assistant' && allSources?.length ?
                <>
                  <InlineSources tooltipPlacement="left" sources={
                    allSources.map((source) => (
                      { source_number: source.source_number, file_name: source.file_name, mime_type: source.mime_type, file_url: source.file_url, source_file_name: source.file_name, file_type: source.mime_type }
                    ))
                  }
                    onSourceClick={(selectedSource) => setSource(selectedSource as AllSource)}
                    setListOfSourcesForSelectedSources={() => { }}
                  />
                </>
                : null
            }
            <p className="text-[#A1A1AA] text-xs h-2"><span className="hidden group-hover:flex justify-end">{dayjs(createdAt).format("hh:mm a, DD MMM YYYY")}</span></p>

            {/* {showFeedback && !hasFailed && (
              <div className="absolute right-2 top-2 flex rounded-full bg-content2 shadow-small">
                <Button isIconOnly radius="full" size="sm" variant="light" onPress={handleCopy}>
                  {copied ? (
                    <Check width={16} />
                  ) : (
                    <Copy width={16} />
                  )}
                </Button>
              </div>
            )} */}

          </div>

        </div>
        {
          source?.file_url &&
          <FileViewer source={source} setSelectedSource={() => setSource(undefined)} />

        }
      </div>
    );
  },
);

export default MessageCard;

MessageCard.displayName = "MessageCard";
