import AlertIcon from "@/shared/icons/alert";
import ClipboardListIcon from "@/shared/icons/clipboard-list";
import EyeClosedIcon from "@/shared/icons/eye-closed";
import GapAssessmentIcon from "@/shared/icons/gap-assessment";
import ShieldQuestionMarkIcon from "@/shared/icons/shield-question";
import { AGENT_TYPES } from "../types/index.ts";

export const assessments = [
  {
    key: AGENT_TYPES.RISK_ASSESSMENT,
    label: "Risk Assessment",
    desc: "Run robust risk assessments and gain actionable intelligence for better decisions.",
    icon: AlertIcon,
    isComponentReady: true,
  },
  {
    label: "Third-Party Risk Assessment",
    key: AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
    desc: "Evaluate vendors thoroughly, streamlining your third-party risk management.",
    icon: ShieldQuestionMarkIcon,
    isComponentReady: true,
  },
  {
    label: "Gap Assessment",
    key: AGENT_TYPES.GAP_ASSESSMENT,
    desc: "Identify compliance gaps by reviewing policies, evidence, and interview notes.",
    icon: GapAssessmentIcon,
    isComponentReady: true,
  },
  {
    key: AGENT_TYPES.QUESTIONNAIRE,
    label: "Security Questionnaire",
    desc: "Leverage your knowledge base to auto-fill and refine security questionnaires.",
    icon: ClipboardListIcon,
    isComponentReady: true,
  },
 
  {
    label: "Common Controls Framework",
    key: "design_review",
    desc: "Unify multiple regulatory frameworks or standards into a single control set.",
    icon: EyeClosedIcon,
    isComponentReady: false,
  },
 
];
