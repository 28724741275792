import { ReviewSourceTypes } from "@/modules/agent/types";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Tooltip,
} from "@nextui-org/react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useCallback, useMemo } from "react";

type ImageViewerProps = {
  fileUrl: string;
  isOpen: boolean;
  onClose: () => void;
  headerTitle: string;
  setSelectedSource: React.Dispatch<
    React.SetStateAction<ReviewSourceTypes | undefined>
  >;
  selectedSource?: ReviewSourceTypes;
  listOfSourcesForSelectedSources?: ReviewSourceTypes[];
};

const ImageViewer = ({
  fileUrl,
  isOpen,
  onClose,
  headerTitle,
  setSelectedSource,
  listOfSourcesForSelectedSources,
  selectedSource,
}: ImageViewerProps) => {
  const findindex = useMemo(() => {
    if (listOfSourcesForSelectedSources) {
      return (
        listOfSourcesForSelectedSources.findIndex(
          (d) => d.source_number === selectedSource?.source_number
        ) + 1
      );
    }
    return 0;
  }, [listOfSourcesForSelectedSources, selectedSource]);

  const isNextAvailable = useMemo(() => {
    if (listOfSourcesForSelectedSources) {
      const findindex = listOfSourcesForSelectedSources.findIndex(
        (d) => d.source_number === selectedSource?.source_number
      );

      return findindex < listOfSourcesForSelectedSources.length - 1;
    }
    return false;
  }, [listOfSourcesForSelectedSources, selectedSource]);
  const handleNext = useCallback(() => {
    if (listOfSourcesForSelectedSources && isNextAvailable) {
      const findindex = listOfSourcesForSelectedSources.findIndex(
        (d) => d.source_number === selectedSource?.source_number
      );
      setSelectedSource(listOfSourcesForSelectedSources[findindex + 1]);
    }
  }, [
    listOfSourcesForSelectedSources,
    setSelectedSource,
    isNextAvailable,
    selectedSource,
  ]);

  const isPreviousAvailable = useMemo(() => {
    if (listOfSourcesForSelectedSources) {
      const findindex = listOfSourcesForSelectedSources.findIndex(
        (d) => d.source_number === selectedSource?.source_number
      );

      return findindex > 0;
    }
    return false;
  }, [listOfSourcesForSelectedSources, selectedSource]);
  const handlePrevious = useCallback(() => {
    if (listOfSourcesForSelectedSources && isPreviousAvailable) {
      const findindex = listOfSourcesForSelectedSources.findIndex(
        (d) => d.source_number === selectedSource?.source_number
      );
      setSelectedSource(listOfSourcesForSelectedSources[findindex - 1]);
    }
  }, [
    listOfSourcesForSelectedSources,
    setSelectedSource,
    isPreviousAvailable,
    selectedSource,
  ]);

  return (
    <Modal
    className="max-h-[calc(100vh-32px)] max-w-[calc(100vw-200px)] !rounded-[8px] overflow-y-hidden !my-auto"
    style={{
      borderRadius: "8px !important",
    }}
    size="full"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent className="flex flex-col">
        {(onClose) => (
          <>
            <ModalHeader className="border-b border-solid border-[#E4E4E7]">
              <div className="flex gap-3 items-center">
                <div className="flex gap-1 items-center pr-3 border-r border-[#E4E4E7] border-solid">
                  <Tooltip
                    content="Previous Reference"
                    classNames={{
                      content:
                        "bg-black bg-opacity-80 backdrop-blur-md text-white text-tiny",
                      arrow: "bg-black bg-opacity-80 backdrop-blur-md",
                    }}
                  >
                    <div
                      className="select-none bg-white cursor-pointer border-[1px] h-5 w-5 flex items-center justify-center rounded-[50%] border-solid border-[#E4E4E7]"
                      onClick={handlePrevious}
                    >
                      <ChevronLeft
                        color={isPreviousAvailable ? "#A1A1AA" : "#E4E4E7"}
                        size={16}
                      />
                    </div>
                  </Tooltip>

                  <div className="text-[12px] leading-5 font-medium text-[#71717A] w-11 text-center">
                    {findindex} of {listOfSourcesForSelectedSources?.length}
                  </div>

                  <Tooltip
                    content="Next Reference"
                    classNames={{
                      content:
                        "bg-black bg-opacity-80 backdrop-blur-md text-white text-tiny",
                      arrow: "bg-black bg-opacity-80 backdrop-blur-md",
                    }}
                  >
                    <div
                      className="select-none bg-white cursor-pointer border-[1px] h-5 w-5 flex items-center justify-center rounded-[50%] border-solid border-[#E4E4E7]"
                      onClick={handleNext}
                    >
                      <ChevronRight
                        color={isNextAvailable ? "#A1A1AA" : "#E4E4E7"}
                        size={16}
                      />
                    </div>
                  </Tooltip>
                </div>

                <p className="text-sm font-medium">{headerTitle}</p>
              </div>

              {/* <div className="flex gap-3 items-center">
                <ImageIcon />
                <p className="text-lg">{headerTitle}</p>
              </div> */}
            </ModalHeader>
            <ModalBody className="grow overflow-auto px-0 py-0">
              <img
                src={fileUrl}
                alt={headerTitle}
                className="max-w-full max-h-full object-contain h-auto"
              />
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ImageViewer;
