import { useCustomizeControlsStore } from "@/modules/agent/states/cusomize-controls";
import { getValidControlNumber } from "./helper";

const CustomizeControlTopLeftSection = () => {
  const selectedControls = useCustomizeControlsStore().selectedControls;
  const selectedTab = useCustomizeControlsStore().selectedTab;
  const agentType = useCustomizeControlsStore().agentType;

  const controls = useCustomizeControlsStore().dataState;
  const validControlNumber = getValidControlNumber({
    agentType,
    controls,
    selectedTab,
  }).length;

  return (
    <div>
      {selectedControls.size > 0 ? (
        <>
          <div className="text-sm font-medium">
            {selectedControls.size} control
            {selectedControls.size > 1 ? "s" : ""} selected
          </div>
        </>
      ) : (
        <div className="flex gap-2">
          <div className="text-sm font-medium">Finalize Controls</div>
          <div className="p-1 bg-[#F4F4F5] px-2 rounded-lg">
            <p className="text-[10px] text-[#52525B] leading-4   font-medium">
              {`${validControlNumber}`}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomizeControlTopLeftSection;
