import {
  AgentSessionStatus,
  AgentSessionStepType,
  AgentSessionType,
} from "@/modules/sessions/types";
import ZBreadCrumbs from "@/shared/components/custom/breadcrumbs";
import { Spinner } from "@nextui-org/react";
import { Eye } from "lucide-react";
import { ReactNode, Suspense, useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { helper } from "../../constants/helpers";
import {
  getAgentTypeFromId,
  useAgentSessionData,
  useAgentStepData,
} from "../../states";
import { AGENT_TYPES } from "../../types/index.ts";

// const VendorRiskAssessment = lazy(() =>
//   import("../VendorRiskAssessment/VendorRiskAssessment")
// );

// const VendorAssessmentIntakeForm = lazy(() =>
//   import("../VendorAssessmentIntakeForm/VendorAssessmentIntakeForm")
// );

import AgentHeader from "./header";
// const Intermediate = lazy(() => import("../intermediate"));
import CustomizeControls from "../customize-controls/CustomizeControls.tsx";
import Intermediate from "../intermediate/index.tsx";
import QuestionnaireMapping from "../questionnaire-mapping/index.tsx";
import ReviewResponse from "../review-responses/index.tsx";
import VendorAssessmentIntakeForm from "../VendorAssessmentIntakeForm/VendorAssessmentIntakeForm.tsx";
import VendorRiskAssessment from "../VendorRiskAssessment/VendorRiskAssessment.tsx";
import AskAi from "@/modules/assistant/components/index.tsx";
import { useChatStore } from "@/modules/assistant/states/index.ts";
// const ReviewResponses = lazy(() => import("../review-responses"));
// const MapExcel = lazy(() => import("../questionnaire-mapping"));
// const Success = lazy(() => import("../success"));
// const CustomizeControls = lazy(() =>
//   import("../customize-controls/CustomizeControls")
// );

interface Params {
  id: string;
}

const ChatLayout = () => {
  const { id } = useParams<keyof Params>() as Params;
  const { pathname } = useLocation();

  const stepData = useAgentStepData(id);

  const {isFloatingAskAiOpen, setIsFloatingAskAiOpen} = useChatStore();

  const sessionData = useAgentSessionData(id);
  const workFlowName = useMemo(() => {
    if (!sessionData?.name) {
      return "Review Responses";
    }
    return sessionData?.name;
  }, [sessionData?.name]);

  const {
    title,
    breadcrumb,
    currentComponent,
    isIntermediate,
  } = useMemo(() => {
    const { agentType: task, agentSubType } = getAgentTypeFromId(id);
    if (!task || !stepData)
      return {
        title: "",
        breadcrumb: "Review Responses",
        currentComponent: null,
        isIntermediate: false,
      };

    const data = helper[agentSubType];
    if (!data)
      return {
        title: "",
        breadcrumb: "Review Responses",
        currentComponent: null,
        isIntermediate: false,
      };

    const inProgressStep = stepData.find(
      (step) =>
        step.status === AgentSessionStatus.IN_PROGRESS ||
        step.status === AgentSessionStatus.INPUT_NEEDED ||
        step.status === AgentSessionStatus.UNDER_REASSESSMENT
    );
    const prepareReportStep = stepData.find(
      (step) => step.type === AgentSessionStepType.PREPARE_REPORT
    );

    let component = null;
    let isIntermediate = false;

    if (
      inProgressStep?.type &&
      [
        AgentSessionStepType.GENERATE_RISK_ASSESSMENT_RESPONSE,
        AgentSessionStepType.GENERATE_FAIR_ASSESSMENT_RESPONSE,
        AgentSessionStepType.QA_EXCEL_STRUCTURE_MAPPING,
        AgentSessionStepType.QA_FILLER,
        AgentSessionStepType.SOC2_TYPE2_AUDIT,
        AgentSessionStepType.SOC2_TYPE2,
        AgentSessionStepType.SOC2_TYPE1,
        AgentSessionStepType.MULTI_FILE_VENDOR_ASSESSMENT,
        AgentSessionStepType.DORA_ASSESSMENT,
        AgentSessionStepType.NIST_AI_RMF,
      ].includes(inProgressStep.type)
    ) {
      component = <Intermediate />;
      isIntermediate = true;
    } else if (
      inProgressStep?.type &&
      [AgentSessionStepType.MULTI_VENDOR_PROFILE].includes(inProgressStep.type)
    ) {
      // Vendor intake form
      component = <VendorAssessmentIntakeForm />;
    } else if (
      task === AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT &&
      (inProgressStep?.type === AgentSessionStepType.EDIT_RESPONSE ||
        (!inProgressStep &&
          (prepareReportStep?.status === AgentSessionStatus.PENDING ||
            prepareReportStep?.status === AgentSessionStatus.COMPLETE)))
    ) {
      // Vendor assessment tabs
      component = <VendorRiskAssessment />;
    } else if (
      inProgressStep?.type === AgentSessionStepType.CONTROL_SELECTION
    ) {
      component = <CustomizeControls />;
    } else if (
      inProgressStep?.type === AgentSessionStepType.EDIT_RESPONSE ||
      (!inProgressStep &&
        prepareReportStep?.status === AgentSessionStatus.PENDING)
    ) {
      component = <ReviewResponse />;
      isIntermediate = false;
    } else if (
      prepareReportStep &&
      prepareReportStep.status === AgentSessionStatus.COMPLETE
    ) {
      component = <ReviewResponse />;
    } else if (
      inProgressStep?.type ===
      AgentSessionStepType.MANUAL_EXCEL_STRUCTURE_MAPPING
    ) {
      component = <QuestionnaireMapping />;
    }
    let breadcrumb: string | undefined;

    if (prepareReportStep?.status === AgentSessionStatus.COMPLETE) {
      breadcrumb = data.process[prepareReportStep?.type]?.breadcrumb ?? "";
    } else if (inProgressStep) {
      breadcrumb = data.process[inProgressStep?.type]?.breadcrumb ?? "";
    }
    // for vendor assessment, the breadcrumb is the vendor name
    if (sessionData?.type === AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT) {
      breadcrumb = sessionData.multi_file_vendor_assessment.vendor_name;
    }

    return {
      title: data.title,
      breadcrumb: breadcrumb,
      currentComponent: component,
      isIntermediate: isIntermediate,
    };
  }, [id, stepData, pathname]);

  const prepareReportStep = stepData?.find(
    (step) => step.type === AgentSessionStepType.PREPARE_REPORT
  );

  // close ask ai popup on component unmount
  useEffect(() => {
    return () => {
      setIsFloatingAskAiOpen(false);
    };
  }, []);

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex items-center pb-4 justify-between min-h-[44px] max-h-[44px]">
        <div className="flex gap-4 items-center">
          <ZBreadCrumbs
            items={[
              {
                title: title,
                href: ``,
                description: workFlowName,
              },
              {
                title: (
                  <div className="flex items-center gap-2">
                    <span>{breadcrumb || workFlowName}</span>
                    {prepareReportStep?.status ===
                      AgentSessionStatus.COMPLETE && (
                      <div className="flex items-center gap-1 bg-[#000000] rounded-xl px-2 py-[1px] opacity-80">
                        <Eye size={14} className="text-white opacity-80" />
                        <span className="text-[10px] text-white opacity-80">
                          View Only
                        </span>
                      </div>
                    )}
                  </div>
                ) as ReactNode,

                href: ``,
              },
            ]}
          />
        </div>
        {!isIntermediate ? <AgentHeader /> : null}
      </div>
      <div className="grow overflow-hidden rounded-xl">
        <Suspense
          fallback={
            <div className="w-full h-full bg-white flex items-center justify-center px-24">
              <Spinner />
            </div>
          }
        >
          {currentComponent}
          {isFloatingAskAiOpen ? <AskAi /> : null}
        </Suspense>
      </div>
    </div>
  );
};

export default ChatLayout;
