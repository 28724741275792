import { post } from "@/infra/rest";
import { AgentSessionStepType } from "@/modules/sessions/types";
import { AiResponseType } from "@/shared/types/user";
import { Soc2Control, VendorAssessmentControl } from "../states/cusomize-controls";

export const refineControlsDescription = async (
  payload: RefineControlDescriptionPayload | RefineControlDescriptionPayload,
) => {
  try {
    if (payload.task === AgentSessionStepType.MULTI_FILE_VENDOR_ASSESSMENT) {
      // RefineControlDescriptionResponseForVendor
      payload.controls = payload.controls.map((c) => ({
        key: c.category,
        question: c.question,
      })) as any[];
      const response = await post<RefineControlDescriptionPayload, RefineControlDescriptionResponseForVendor>({
        url: "/v0/rag/workflows/multi_file_va/controls/refine",
        data: payload,
      });
      return response;
    }
    if (payload.task === AgentSessionStepType.SOC2_TYPE1 || payload.task === AgentSessionStepType.SOC2_TYPE2) {
      const response = await post<RefineControlDescriptionPayload, RefineControlDescriptionResponseForSoc2>({
        url: "/v0/rag/workflows/soc2/controls/refine",
        data: payload,
      });
      return response;
    }
  } catch (err) {
    throw new Error("Failed to refine Control");
  }
};

export const refineControlsTestingProcedure = async (payload: RefineControlDescriptionPayload) => {
  try {
    const response = await post<RefineControlDescriptionPayload, RefineControlTestingProcedureResponse>({
      url: "/v0/rag/workflows/soc2/controls/tests",
      data: payload,
    });

    return response;
  } catch (err) {
    throw new Error("Failed to refine Control");
  }
};

type RefineControlDescriptionPayload =
  | {
      task: AgentSessionStepType.SOC2_TYPE1 | AgentSessionStepType.SOC2_TYPE2;
      controls: Soc2Control[];
      response_quality: AiResponseType;
    }
  | {
      task: AgentSessionStepType.MULTI_FILE_VENDOR_ASSESSMENT | AgentSessionStepType.MULTI_VENDOR_PROFILE;
      controls: VendorAssessmentControl[];
      response_quality: AiResponseType;
    };

export interface RefineControlDescriptionResponseForSoc2 {
  success: boolean;
  message: string;
  data: {
    controls: (Soc2Control & {
      old_control: string;
    })[];
  };
}
export interface RefineControlDescriptionResponseForVendor {
  success: boolean;
  message: string;
  data: {
    controls: (VendorAssessmentControl & {
      old_question: string;
    })[];
  };
}

export interface RefineControlTestingProcedureResponse {
  success: boolean;
  message: string;
  data: {
    controls: (Soc2Control & {
      old_toe: string;
      old_tod: string;
    })[];
  };
}
