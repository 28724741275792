import {
  getCustomizeControlsActions,
  getCustomizeControlsAiOpenStates,
  SecurityControlCategory,
  Soc2Control,
  TSCABBRIVATIONS,
  TSCENUM,
  useCustomizeControlsStore,
  VendorAssessmentControl,
} from "@/modules/agent/states/cusomize-controls";
import { AgentSessionType } from "@/modules/sessions/types";
import { cn } from "@/shared/utils/classname-merger";
import { Checkbox } from "@nextui-org/react";
import {
  CustomizeControlCategoryRenderer,
  CustomizeControlDescriptionRenderer,
  CustomizeControlIdRenderer,
  CustomizeControlTestingProcedureRenderer,
} from "../column-cell-renderers/soc2-renderers";
import {
  CustomizeControlVendorCategoryRenderer,
  CustomizeControlVendorControlDescriptionRenderer,
} from "../column-cell-renderers/vendor-assessment-renderers";

export const customizeControlsColumns = ({
  accordianOpenState,
  handleChangeDataState,
  searchQuery,
  selectedControls,
  setAccordianOpenState,
  setSelectedControls,
  filteredRows,
}: {
  accordianOpenState: {
    security: boolean;
    availability: boolean;
    confidentiality: boolean;
    "processing integrity": boolean;
    privacy: boolean;
  };
  handleChangeDataState: (
    key: keyof Soc2Control,
    uId: string,
    newValue: string,
    changesInTestingProcedure?: number
  ) => void;
  searchQuery: string;
  selectedControls: Set<string>;
  setAccordianOpenState: (key: TSCENUM, newValue: boolean) => void;
  setSelectedControls: (
    newVal: Set<string> | ((prev: Set<string>) => Set<string>)
  ) => void;
  filteredRows: Soc2Control[];
}) => {
  return [
    {
      fieldName: "id",
      headerName: "id",
      renderCell: (control: Soc2Control) => (
        <CustomizeControlIdRenderer
          accordianOpenState={accordianOpenState}
          control={control}
          handleChangeDataState={handleChangeDataState}
          searchQuery={searchQuery}
          selectedControls={selectedControls}
          setAccordianOpenState={setAccordianOpenState}
          setSelectedControls={setSelectedControls}
        />
      ),
      renderHeader: () => {
        const isCheckboxDisabled = getCustomizeControlsAiOpenStates() > 0;
        return (
          <div className="flex w-[216px]">
            <Checkbox
              isSelected={
                filteredRows.length === selectedControls.size &&
                selectedControls.size > 0
              }
              isIndeterminate={
                selectedControls.size > 0 &&
                filteredRows.length !== selectedControls.size
              }
              classNames={{
                base: cn("group"),
                wrapper: cn(
                  "group-data-[disabled=true]:border-default",
                  "after:group-data-[disabled=true]:bg-default-100"
                ),
                icon: "group-data-[disabled=true]:text-default-300",
              }}
              onChange={() => {
                if (filteredRows.length === selectedControls.size) {
                  setSelectedControls(new Set([]));
                } else {
                  setSelectedControls(new Set(filteredRows.map((d) => d.uid)));
                }
              }}
              data-component="z-table-row-select"
              color={"primary"}
              isDisabled={searchQuery.length > 0 || isCheckboxDisabled}
              disabled={searchQuery.length > 0 || isCheckboxDisabled}
            />
            <div className="text-[12px] leading-5 font-semibold">ID</div>
          </div>
        );
      },
    },
    {
      fieldName: "category",
      headerName: "Category",
      renderCell: (control: Soc2Control) => (
        <CustomizeControlCategoryRenderer
          control={control}
          handleChangeDataState={handleChangeDataState}
          searchQuery={searchQuery}
        />
      ),
    },
    {
      fieldName: "control",
      headerName: "Control Description",
      minWidth: 652,
      renderCell: (control: Soc2Control) => (
        <CustomizeControlDescriptionRenderer
          control={control}
          handleChangeDataState={handleChangeDataState}
          searchQuery={searchQuery}
        />
      ),
    },
    {
      fieldName: "testing_procedure",
      headerName: "Testing Procedure",
      minWidth: 372,
      renderCell: (control: Soc2Control) => (
        <CustomizeControlTestingProcedureRenderer
          control={control}
          handleChangeDataState={handleChangeDataState}
          searchQuery={searchQuery}
        />
      ),
    },
  ];
};

export const vendorAssessmentControlsColumns = ({
  handleChangeDataState,
  searchQuery,
  selectedControls,
  setSelectedControls,
  filteredRows,
  selectedTab,
}: {
  handleChangeDataState: (
    key: keyof VendorAssessmentControl,
    uId: string,
    newValue: string,
    changesInTestingProcedure?: number
  ) => void;
  searchQuery: string;
  selectedControls: Set<string>;
  setSelectedControls: (
    newVal: Set<string> | ((prev: Set<string>) => Set<string>)
  ) => void;
  filteredRows: VendorAssessmentControl[];
  selectedTab: string | null;
}) => {
  return [
    {
      fieldName: "category",
      headerName: "category",
      renderCell: (control: VendorAssessmentControl) => (
        <CustomizeControlVendorCategoryRenderer
          control={control}
          handleChangeDataState={handleChangeDataState}
          searchQuery={searchQuery}
          selectedControls={selectedControls}
          setSelectedControls={setSelectedControls}
          selectedTab={selectedTab}
        />
      ),
      renderHeader: () => {
        const isCheckboxDisabled = getCustomizeControlsAiOpenStates() > 0;
        return (
          <div className="flex w-[216px]">
            <Checkbox
              isSelected={
                filteredRows.length === selectedControls.size &&
                selectedControls.size > 0
              }
              isIndeterminate={
                selectedControls.size > 0 &&
                filteredRows.length !== selectedControls.size
              }
              classNames={{
                base: cn("group"),
                wrapper: cn(
                  "group-data-[disabled=true]:border-default",
                  "after:group-data-[disabled=true]:bg-default-100"
                ),
                icon: "group-data-[disabled=true]:text-default-300",
              }}
              onChange={() => {
                if (filteredRows.length === selectedControls.size) {
                  setSelectedControls(new Set([]));
                } else {
                  setSelectedControls(new Set(filteredRows.map((d) => d.uid)));
                }
              }}
              data-component="z-table-row-select"
              color={"primary"}
              disabled={searchQuery.length > 0 || isCheckboxDisabled}
              isDisabled={searchQuery.length > 0 || isCheckboxDisabled}
            />
            <div className="text-[12px] leading-5 font-semibold">Category</div>
          </div>
        );
      },
    },
    {
      fieldName: "control",
      headerName: "Control Description",
      minWidth: 652,
      renderCell: (control: VendorAssessmentControl) => (
        <CustomizeControlVendorControlDescriptionRenderer
          control={control}
          handleChangeDataState={handleChangeDataState}
          searchQuery={searchQuery}
        />
      ),
    },
  ];
};

export const searchAndFilteringList = (
  agentType: AgentSessionType | null
): string[] => {
  switch (agentType) {
    case AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT:
      return ["key", "question"];

    case AgentSessionType.SOC2_GAP_TYPE1:
    case AgentSessionType.SOC2_GAP_TYPE2:
    case AgentSessionType.SOC2_GAP:
      return [
        "category",
        "to2e",
        "control",
        "control_id",
        "tsc",
        "tsc_id",
        "zania_control_id",
      ];
    default:
      return [];
  }
};

type updateHandlerForSoc2 = {
  agentType:
    | AgentSessionType.SOC2_GAP_TYPE1
    | AgentSessionType.SOC2_GAP_TYPE2
    | AgentSessionType.SOC2_GAP;
  key: keyof Soc2Control;
  // sectionWiseData: Soc2Control[];
  uId: string;
  newValue: string;
  changesInTestingProcedure?: number;
};

type updateHandleForVendorAssesment = {
  agentType: AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT;
  key: keyof VendorAssessmentControl;
  // sectionWiseData: VendorAssessmentControl[];
  uId: string;
  newValue: string;
  changesInTestingProcedure?: number;
};

export type updateHandle = { agentType: AgentSessionType | null } & (
  | updateHandleForVendorAssesment
  | updateHandlerForSoc2
);

export const updateHandlerBasedOnAgentTypes = (params: updateHandle) => {
  const { agentType } = params;

  if (
    agentType === AgentSessionType.SOC2_GAP ||
    agentType === AgentSessionType.SOC2_GAP_TYPE1 ||
    agentType === AgentSessionType.SOC2_GAP_TYPE2
  ) {
    const { key, newValue, uId, changesInTestingProcedure } = params;
    const sectionWiseData = useCustomizeControlsStore.getState()
      .sectionWiseData as Soc2Control[];

    getCustomizeControlsActions().setDataState((prev) => {
      const newDataState = [...(prev as Soc2Control[])];
      const foundIndexOfElement = newDataState.findIndex((d) => d.uid === uId);
      if (foundIndexOfElement > -1) {
        if (key === "tsc_id") {
          let modifiedNewValue = newValue;

          if (
            modifiedNewValue.trim().length > 0 &&
            Object.values(TSCABBRIVATIONS).reduce(
              (acc, abbrivation) =>
                modifiedNewValue.startsWith(abbrivation) || acc,
              false
            ) === false
          ) {
            modifiedNewValue = `${
              TSCABBRIVATIONS[newDataState[foundIndexOfElement].tsc]
            }${modifiedNewValue}`;
          }

          newDataState[foundIndexOfElement].control_id = modifiedNewValue;
          newDataState[foundIndexOfElement].tsc_id = modifiedNewValue;
          if (newDataState[foundIndexOfElement].edited_state !== "default") {
            newDataState[foundIndexOfElement].tsc_id = modifiedNewValue;
            newDataState[foundIndexOfElement].control_id = modifiedNewValue;
          }
        }
        if (key === "control") {
          newDataState[foundIndexOfElement].control = newValue;
          newDataState[foundIndexOfElement].changesInControl =
            (newDataState[foundIndexOfElement].changesInControl ?? 0) + 1;
        }
        if (key === "category") {
          newDataState[
            foundIndexOfElement
          ].category = newValue as SecurityControlCategory;
        }
        if (key === "toe") {
          newDataState[foundIndexOfElement].toe = newValue;
          newDataState[foundIndexOfElement].tod = newValue;
          newDataState[foundIndexOfElement].changesInTestingProcedure =
            (newDataState[foundIndexOfElement].changesInTestingProcedure ?? 0) +
            (newDataState[foundIndexOfElement].changesInTestingProcedure === -1
              ? 2
              : 1);
          if (changesInTestingProcedure) {
            newDataState[
              foundIndexOfElement
            ].changesInTestingProcedure = changesInTestingProcedure;
          }
        }

        if (key === "new_control") {
          newDataState[foundIndexOfElement].new_control = newValue;
        }
        if (key === "new_tod" || key === "new_toe") {
          newDataState[foundIndexOfElement].new_tod = newValue;
          newDataState[foundIndexOfElement].new_toe = newValue;
        }

        if (
          newDataState[foundIndexOfElement].toe.trim().length === 0 &&
          newDataState[foundIndexOfElement].control.trim().length === 0 &&
          (newDataState[foundIndexOfElement].tsc_id.trim() ===
            TSCABBRIVATIONS[newDataState[foundIndexOfElement].tsc] ||
            newDataState[foundIndexOfElement].tsc_id.trim().length === 0) &&
          newDataState[foundIndexOfElement].category.trim().length === 0
        ) {
          const removedUid = newDataState[foundIndexOfElement].uid;
          getCustomizeControlsActions().setSelectedControls((prev) => {
            const newSelectedControls = new Set(prev);
            newSelectedControls.delete(removedUid);
            return newSelectedControls;
          });

          newDataState.splice(foundIndexOfElement, 1);
        }
      } else {
        const newObject = (sectionWiseData as Soc2Control[]).find(
          (d) => d.uid === uId
        );
        if (newObject) {
          if (key === "tsc_id") {
            let modifiedNewValue = newValue;

            if (
              Object.values(TSCABBRIVATIONS).reduce(
                (acc, abbrivation) =>
                  modifiedNewValue.startsWith(abbrivation) || acc,
                false
              ) === false
            ) {
              modifiedNewValue = `${
                TSCABBRIVATIONS[newObject.tsc]
              }${modifiedNewValue}`;
            }

            newObject.control_id = modifiedNewValue;
            if (newObject.edited_state !== "default") {
              newObject.tsc_id = modifiedNewValue;
            }
          }
          if (key === "control") {
            newObject.control = newValue;
            newObject.changesInControl = 1;
          }
          if (key === "category") {
            newObject.category = newValue as SecurityControlCategory;
          }
          if (key === "toe") {
            newObject.toe = newValue;
            newObject.changesInTestingProcedure = 1;
            newObject.changesInControl = 1;
          }

          newDataState.push({
            ...newObject,
            edited_state: "custom",
          });
          setTimeout(() => {
            const d = document.getElementById(uId + key);
            d?.focus();
          }, 0);
        }
      }

      getCustomizeControlsActions().setChangesMade((prev) => prev + 1);
      return newDataState;
    });
  }
  if (agentType === AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT) {
    const { key, newValue, uId } = params;
    const sectionWiseData = useCustomizeControlsStore.getState()
      .sectionWiseData as VendorAssessmentControl[];

    getCustomizeControlsActions().setDataState((prev) => {
      const newDataState = [...(prev as VendorAssessmentControl[])];
      const foundIndexOfElement = newDataState.findIndex((d) => d.uid === uId);
      if (foundIndexOfElement > -1) {
        if (key === "category") {
          newDataState[foundIndexOfElement].category = newValue;
        }
        if (key === "question") {
          newDataState[foundIndexOfElement].question = newValue;
          newDataState[foundIndexOfElement].changesInQuestion =
            (newDataState[foundIndexOfElement].changesInQuestion ?? 0) + 1;
        }
        if (key === "new_question") {
          newDataState[foundIndexOfElement].new_question = newValue;
        }

        if (
          (newDataState[foundIndexOfElement].category.trim().length === 0 ||
            (newDataState[foundIndexOfElement].section ===
              "ai_security_assessment" &&
              newDataState[foundIndexOfElement].category
                .replace("AI_", "")
                .trim().length === 0)) &&
          newDataState[foundIndexOfElement].question.trim().length === 0
        ) {
          const removedUid = newDataState[foundIndexOfElement].uid;
          getCustomizeControlsActions().setSelectedControls((prev) => {
            const newSelectedControls = new Set(prev);
            newSelectedControls.delete(removedUid);
            return newSelectedControls;
          });

          newDataState.splice(foundIndexOfElement, 1);
        }
      } else {
        const newObject = (sectionWiseData as VendorAssessmentControl[]).find(
          (d) => d.uid === uId
        );
        if (newObject) {
          if (key === "category") {
            newObject.category = newValue;
          }

          if (key === "question") {
            newObject.question = newValue;
            newObject.changesInQuestion = 1;
          }

          newDataState.push({
            ...newObject,
            edited_state: "custom",
          });
          setTimeout(() => {
            const d = document.getElementById(uId + key);
            d?.focus();
          }, 0);
        }
      }

      getCustomizeControlsActions().setChangesMade((prev) => prev + 1);
      return newDataState;
    });
  }
};
