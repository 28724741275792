import ErrorBoundary from "@/shared/components/error-boundary";
import { Spinner, Tab, Tabs } from "@nextui-org/react";
import Overview from "./Overview/Overview";
import SupplyChain from "./SupplyChain/SupplyChain";

import { useVendorRiskAssessment } from './useVendorRiskAssessment/useVendorRiskAssessment';
import useEffectAsync from '@/shared/hooks/useEffectAsync';
import parsePromise from '@/shared/utils/parsePromise';
import { useVendorRiskAssessmentStore } from './useVendorRiskAssessment/useVendorRiskAssessmentStore';
import ReviewResponses from '../review-responses';
import { useAgentSessionData } from '../../states';
import { useParams } from 'react-router-dom';
import { MultiVendorAgentSession } from '@/modules/sessions/types';
import clsx from 'clsx';
import RiskLevelChip from './Overview/RiskLevelChip';
import { VendorRiskSectionEnum } from '../../types/vendorAssessment';
import { VendorRiskResponseItemForVendorOverview } from '../../types/vendorOverviewSection';
import { Loader } from 'lucide-react';
import { useEffect } from 'react';

export type VendorPageType = "OVERVIEW" | "SUPPLY_CHAIN" | "ASSESSMENT";

interface Params {
  id: string;
}

const VendorRiskAssessment = () => {
  const { id } = useParams<keyof Params>() as Params;
  const { isSaving } = useVendorRiskAssessmentStore();
  const {
    combinedVendorRiskData,
    selectedTab,
    handleTabChange,
    loadVendorRiskData,
  } = useVendorRiskAssessment();

  const { reset } = useVendorRiskAssessmentStore();

  useEffectAsync(async () => {
    await parsePromise(loadVendorRiskData());
  }, [loadVendorRiskData]);

  const sessionData = useAgentSessionData(id);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return combinedVendorRiskData ? (
    <ErrorBoundary fallback={null}>
      <div className="h-full w-full rounded-lg flex flex-col">
        {/* header */}
        <div className="bg-white sticky top-0 z-50 w-full">
          <div className="px-6 pt-4 pb-3 flex flex-row gap-3 w-full items-center bg-white">
            <div className="text-lg font-[550]">
              {(sessionData as MultiVendorAgentSession)
                .multi_file_vendor_assessment?.vendor_name ?? "Vendor Name"}
            </div>
            <RiskLevelChip
              riskLevel={
                (
                  combinedVendorRiskData[
                    VendorRiskSectionEnum.vendor_overview
                  ].find(
                    (item: any) => item.key === "risk_rating",
                  ) as VendorRiskResponseItemForVendorOverview<"risk_rating">
                )?.value.value
              }
            />
            <div className="flex items-center h-7 gap-1">
              <Loader
                className={`w-4 h-4 animate-spin ${isSaving ? '' : 'invisible'}`}
              />
              {isSaving && (
                <p className="text-tiny text-[#A5A5A5] text-[10px]">Saving</p>
              )}
            </div>
          </div>

          <Tabs
            variant="underlined"
            selectedKey={selectedTab}
            classNames={{
              tab: "rounded-none px-6",
              base: "rounded-none",
              cursor: "rounded-none",
              panel: "rounded-none",
              tabContent: "rounded-none",
              tabList: "rounded-none p-0",
              wrapper: "rounded-none",
            }}
            className="border-b-1 bg-white w-full"
            onSelectionChange={(key) => handleTabChange(key as VendorPageType)}
          >
            <Tab
              className={clsx("rounded-none", {
                'after:content-[""] after:absolute after:bottom-0 after:left-0 after:w-full after:h-full after:bg-gradient-to-t  after:from-[#E7F2FF] after:to-[#FFFFFF] after:border-b-2 after:border-b-[#006FEE]':
                  selectedTab === "OVERVIEW",
              })}
              key="OVERVIEW"
              title="Overview"
            />
            <Tab
              className={clsx("rounded-none", {
                'after:content-[""] after:absolute after:bottom-0 after:left-0 after:w-full after:h-full after:bg-gradient-to-t after:from-[#E7F2FF] after:to-[#FFFFFF] after:border-b-2 after:border-b-[#006FEE]':
                  selectedTab === "SUPPLY_CHAIN",
              })}
              key="SUPPLY_CHAIN"
              title="Supply Chain"
            />
            <Tab
              className={clsx("rounded-none", {
                'after:content-[""] after:absolute after:bottom-0 after:left-0 after:w-full after:h-full after:bg-gradient-to-t after:from-[#E7F2FF] after:to-[#FFFFFF] after:border-b-2 after:border-b-[#006FEE]':
                  selectedTab === "ASSESSMENT",
              })}
              key="ASSESSMENT"
              title="Assessment"
            />
          </Tabs>
        </div>
        <div className="h-full bg-white overflow-x-auto">
          {selectedTab === "OVERVIEW" && <Overview />}
          {selectedTab === "SUPPLY_CHAIN" && <SupplyChain />}
          {selectedTab === "ASSESSMENT" && (
            <ReviewResponses
              hideAgentTypeFromHeader={true}
              disableRoundedBorder={true}
            />
          )}
        </div>
      </div>
    </ErrorBoundary>
  ) : (
    <div className="w-full h-full bg-white flex items-center justify-center px-24">
      <Spinner />
    </div>
  );
};

export default VendorRiskAssessment;
